import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import {
    Table,
    Stack,
    Avatar,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    Box,
    LinearProgress,
    InputAdornment,
    IconButton,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import ClientListHead from '../client/ClientListHead';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ServerListToolBar from '../@dashboard/user/ServerListToolBar';
import ServerMoreMenu from './ServerMoreMenu';
import ServersAlert from './ServersAlert';
import ServerDetails from './ServerDetails';
import CameraDetails from './CameraDetails';
import CheckIcon from '@mui/icons-material/Check';
import { changeServerStatus, deleteServer } from 'src/api/servers';


const TABLE_HEAD = [
    { id: 'name', label: 'S No', alignRight: false },
    { id: 'ServerName', label: 'Server Name', alignRight: false },
    { id: 'ServerType', label: 'Server Type', alignRight: false },
    { id: 'devices_count', label: 'Cameras', alignRight: false },
    { id: 'ServerId', label: 'Server ID', alignRight: false },
    { id: 'ServerStatus', label: 'Server Status', alignRight: false },
    { id: 'usedCPU', label: 'CPU Utilization(%)', alignRight: false },
    { id: 'usedStorageGB', label: 'Storage(GB)', alignRight: false },
    { id: 'Status', label: 'Status', alignRight: false },
    { id: '', alignRight: true },
];

// ----------------------------------------------------------------------


function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {props.displaytext ? props.displaytext : `${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => ['ServerName', 'ServerType', 'ServerId'].map((ky) => _user[ky] !== null ? _user[ky] : '').join(' ').toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function ServerList({ servers, titleKey, handleUpdateDetails, loadServersList, isServerLoading }) {

    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [copied, setCopied] = useState(null);
    const [moremenu, setOpenMenu] = useState({ isOpen: false, id: null });
    const [detailBoxInfo, setDetailBoxStatus] = useState({ isOpen: false, data: {} });
    const [activeServerInfo, setActiveServerInfo] = useState({ isOpen: false, data: {} });
    const [serverData, setServerData] = useState([])
    const [serverFilters, setFilter] = useState({
        offline: 1,
        online: 1,
        active: 1,
        inactive: 1,
        cpu: [0, 100]
    })    


    useEffect(() => {
        handleFilter(null, null)
    }, [servers])


    const [confirmAlert, setAlert] = useState({
        title: '',
        content: '',
        open: false,
        id: null,
        status: null,
    });

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - serverData.length) : 0;
    let filteredUsers = applySortFilter(serverData, getComparator(order, orderBy), filterName);
    const isUserNotFound = filteredUsers.length === 0;


    const handleCopy = (ServerId) => {
        navigator.clipboard.writeText(ServerId).then(() => {
            setCopied(ServerId);
            setTimeout(() => setCopied(null), 2000); // Reset copied state after 2 seconds
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    const handleAlertOpen = (id, status) => {
        if (status === 'status' && Number(id.Status) === 0) {
            let content = status === 'status' ? 'Are you sure want to Active this record?' : 'Are you sure want to delete this record permanently?';
            let btn = status === 'status' ? "Yes, Active record!" : "Yes, delete record!";
            let upStatus = status === 'delete' ? 2 : 1;
            setAlert({
                title: btn,
                content: content,
                open: true,
                id: id.ServerId,
                status: upStatus,
            });
        } else {
            let content = status === 'status' ? 'Are you sure want to inactive this record?' : 'Are you sure want to delete this record permanently?';
            let btn = status === 'status' ? "Yes, inactive record!" : "Yes, delete record!";
            let upStatus = status === 'delete' ? 2 : 0;
            setAlert({
                title: btn,
                content: content,
                open: true,
                id: id.ServerId,
                status: upStatus,
            });
        }
    };

    const handleAlertClose = () => setAlert({ ...confirmAlert, open: false });

    const handleMoreMenu = (id = null) => setOpenMenu({ ...moremenu, isOpen: true, id });

    const handleStatus = (id, status) => {
        if (status === 2) {
            deleteServer({ ServerId: id, Status: status }, (response) => {
                if (response.status === 200) {
                    loadServersList();
                    handleAlertClose();
                }
            });
        } else {
            changeServerStatus({ ServerId: id, Status: status }, (response) => {
                if (response.status === 200) {
                    loadServersList();
                    handleAlertClose();
                }
            });
        }
    };




    const handleFilter = (filterName, value) => {

        setFilter((prevServerFilters) => {
            if (filterName)
                prevServerFilters[filterName] = value

            const allFiltersMStatus = []
            if (prevServerFilters.online) {
                allFiltersMStatus.push(1)
            }
            if (prevServerFilters.offline) {
                allFiltersMStatus.push(0)
            }

            const allFiltersStatus = []
            if (prevServerFilters.active) {
                allFiltersStatus.push(1)
            }
            if (prevServerFilters.inactive) {
                allFiltersStatus.push(0)
            }


            setServerData((_) => servers.filter((data) =>
                allFiltersStatus.includes(data.Status) && allFiltersMStatus.includes(data.MonitoringStatus) && (prevServerFilters.cpu[0] <= data.CPUUtilisation && ((prevServerFilters.cpu[1] === 100 && data.CPUUtilisation >= 100) || data.CPUUtilisation <= prevServerFilters.cpu[1]))
            ))

            return { ...prevServerFilters }
        })
    }



    return (
        <Box>
            <Dialog
                open={activeServerInfo.isOpen}
                onClose={() => setActiveServerInfo({ ...activeServerInfo, isOpen: false })}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle id="alert-dialog-title">
                    {activeServerInfo.data.hasOwnProperty("ServerName") ? `${activeServerInfo.data.ServerName} Cameras Info` : ""}
                </DialogTitle>
                <DialogContent>
                    <CameraDetails serverInfo={activeServerInfo.data} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setActiveServerInfo({ ...activeServerInfo, isOpen: false })} autoFocus>Close</Button>
                </DialogActions>
            </Dialog>
            <ServerDetails open={detailBoxInfo.isOpen} serverData={detailBoxInfo.data} handleDetailBox={setDetailBoxStatus} />
            <ServersAlert
                alert={confirmAlert.open}
                handleAlertClose={handleAlertClose}
                title={confirmAlert.title}
                content={confirmAlert.content}
                id={confirmAlert.id}
                status={confirmAlert.status}
                handleStatus={handleStatus}
            />
            <ServerListToolBar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                handleFilter={handleFilter}
                filters={serverFilters}
                loadServersList={loadServersList}
                isServerLoading={isServerLoading}
            />

            <Scrollbar>
                <TableContainer sx={{ minWidth: 800, maxHeight: 550 }}>
                    <Table style={{ position: 'sticky', top: '0', flexDirection: 'column' }}>
                        <ClientListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={serverData.length}
                            numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, inc) => {
                                const {
                                    ServerName,
                                    ServerType,
                                    devices_count,
                                    ServerId,
                                    Status,
                                    CPUUtilisation,
                                    StorageUtilisation,
                                    MonitoringStatus,
                                    usedStorageGB,
                                    Storage,
                                    Id
                                } = row;

                                return (
                                    <TableRow
                                        hover
                                        key={`${titleKey}_${Id}`}
                                        tabIndex={-1}
                                    >
                                        <TableCell>{(inc + (page * rowsPerPage)) + 1}</TableCell>
                                        <TableCell>{ServerName}</TableCell>
                                        <TableCell>{ServerType}</TableCell>
                                        <TableCell><Button onClick={() => setActiveServerInfo({ isOpen: true, data: row })}>{devices_count}</Button></TableCell>
                                        <TableCell sx={{ maxWidth: "150px" }}>
                                            {/* {(copied && copied === ServerId) && <Typography variant='body2' color='success.main' sx={{ pl: 1 }}>Copied!</Typography>} */}
                                            <TextField
                                                value={ServerId || ''}
                                                variant="outlined"
                                                size="small"
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton disabled={copied === ServerId} onClick={() => handleCopy(ServerId)} edge="end" size="small">
                                                                {copied && copied === ServerId ? (<CheckIcon color="success" />) : (<ContentCopyIcon />)}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    sx: { pr: 0.5, width: 'auto' }
                                                }}
                                                sx={{ pr: 1 }}
                                            />
                                        </TableCell>
                                        <TableCell align="left">
                                            <Label variant="ghost" color={MonitoringStatus === 1 ? 'success' : 'error'}>
                                                {MonitoringStatus === 1 ? 'Online' : 'Offline'}
                                            </Label>
                                        </TableCell>
                                        <TableCell align="left">
                                            <LinearProgressWithLabel variant="determinate" value={CPUUtilisation ? CPUUtilisation : 0} color={CPUUtilisation >= 90 ? 'error' : CPUUtilisation < 90 && CPUUtilisation >= 80 ? 'warning' : 'success'} />
                                        </TableCell>
                                        <TableCell align="left">
                                            <LinearProgressWithLabel variant="determinate" displaytext={`${usedStorageGB}/${Storage}`} value={StorageUtilisation ? StorageUtilisation : 0} color={StorageUtilisation >= 90 ? 'error' : StorageUtilisation < 90 && StorageUtilisation >= 80 ? 'warning' : 'success'} />
                                        </TableCell>
                                        <TableCell align="left">
                                            <Label variant="ghost" color={Status === 1 ? 'success' : 'error'}>
                                                {Status === 1 ? 'Active' : 'In Active'}
                                            </Label>
                                        </TableCell>
                                        <TableCell align='right'>
                                            <ServerMoreMenu
                                                handleAlertOpen={handleAlertOpen}
                                                setDetailBoxStatus={setDetailBoxStatus}
                                                updateServerDetails={handleUpdateDetails}
                                                row={row}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={10} />
                                </TableRow>
                            )}
                        </TableBody>
                        {isUserNotFound && (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                                        <SearchNotFound searchQuery={filterName} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[100, 300, 500]}
                component="div"
                count={serverData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
}
