import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import {
  Toolbar,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
  Box,
  Slider
} from '@mui/material';
// component
import Iconify from '../../../components/Iconify';
import { LoadingButton } from '@mui/lab';


// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

ServerListToolBar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

function valuetext(value) {
  return `${value}°C`;
}

export default function ServerListToolBar({ 
  numSelected,
  filterName,
  onFilterName,
  filters,
  handleFilter,
  loadServersList,
  isServerLoading
 }) {
  

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder="Search"
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      )}

      <Stack direction="row" spacing={3}>
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={filters.online === 1} onChange={() => handleFilter('online', filters.online === 1? 0: 1)} color="success" />} label="Online" />
          <FormControlLabel control={<Checkbox checked={filters.offline === 1} onChange={() => handleFilter('offline', filters.offline === 1? 0: 1)} color="error" />} label="Offline" />
        </FormGroup>




        <Box
          sx={{ width: 300, pt: 2 }}
        >
          <Typography id="input-slider" gutterBottom>
            CPU Utilization(%)
          </Typography>
          <Slider
            value={filters.cpu}
            onChange={(_, value) => handleFilter('cpu', value)}
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
          />
        </Box>


        <FormGroup>
          <FormControlLabel control={<Checkbox checked={filters.active === 1} color="success" onChange={() => handleFilter('active', filters.active === 1? 0: 1)} />} label="Active" />
          <FormControlLabel control={<Checkbox checked={filters.inactive === 1} color="error" onChange={() => handleFilter('inactive', filters.inactive === 1? 0: 1)} />} label="Inactive" />
        </FormGroup>

        
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
>
          <LoadingButton loading={isServerLoading} onClick={loadServersList}>
            <Iconify sx={{fontSize: "1.5rem"}} icon="eva:refresh-outline" color="primary" />
          </LoadingButton>
        </Stack>        

      </Stack>


      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <Iconify icon="eva:trash-2-fill" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <Iconify icon="ic:round-filter-list" />
          </IconButton>
        </Tooltip>
      )} */}
    </RootStyle>
  );
}
