import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Table,
  TableCell,
  TableBody,
  TableRow,InputLabel,Select,MenuItem,
  TableHead,
  TableContainer,
  Card,
Autocomplete,
  TextField,
  FormControl,
  Grid,
  CardContent,Stack,DialogActions,
  Dialog,
  IconButton,
  InputAdornment,
  DialogContent,

} from "@mui/material";

import Scrollbar from "src/components/Scrollbar";


import Iconify from "src/components/Iconify";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { getClientList } from "src/api/analytics";
import { newSubLogins ,getSubLoginDetails ,updateLoginDetails} from "src/api/newSublogin";
const AddNewLogin = () => {
 
  //variable
  const [value3, setValue3] = useState(null);
  const [agentName, setAgentName] = useState("");
  const [userNewName, setNewUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  //error 
  const [error,setError]=useState(false)
  const [agentNameError,setagentNameError] = useState('');
  const [userNewNameError,setuserNewNameError] = useState('');
  const [passwordError,setpasswordTypeError] = useState('');
  const [confirmPasswordError,setconfirmPasswordError] = useState('');


  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [visiblePasswordIndex, setVisiblePasswordIndex] = useState(null);


  const [userDetails,setUserDetails] = useState([]);

  //Edit Dailog box
  const [openDialogEdit, setOpenDialogEdit] = useState(false);

  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('md');

  const [isEdited,setIsEdited]=useState(false)
  const [prvValue,setPrvValue]=useState('')

  const [rowData,setId]=useState([])

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);


  const [openMessage,setOpenMessage]=useState(false)
  const [isClientSelected,setIsClientSelected]=useState(false)
  const [clientList,setClientList]=useState([]) 
  const [subLogin,setSubLoginList]=useState([]) 


  const [clientType, setClientType] = useState('')
  const [clientTypeerror, setClientTypeError] = useState('')
  const [setTypeClient,setSelectedTypeClient] = useState([]);


  useEffect(() => {
    getClientList((response) => {
      if (response.status === 200) {
         setClientList(response.data)
      }
    })
    const data = {
      type : 'sub'
    }
    getSubLoginDetails(data,(response) =>{
      if(response.status === 200){
        console.log("getSubLoginDetails",response.data);
        setSubLoginList(response.data);
      }
    })

    },[])

    useEffect(() =>{
      if(clientType === "INDIA"){
        console.log("clientType",clientType)
       const indiaClients = clientList.filter((row) => row.ClientType === 'INDIA');
       setSelectedTypeClient(indiaClients)
       
      }
      else {
       const usClients = clientList.filter((row) => row.ClientType === 'US');
       setSelectedTypeClient(usClients)
  
  
      }
  
    },[clientType])

  const [dialogData, setDialogData] = useState({
    // userType: '',
    agentName: '',
    mobileNumber: '',
    userNewName: '',
    password: '',
    confirmPassword: '',
   
  });
  

  const [editedData, seteditedData] = useState({
    // userType: '',
    Id:'',
    UserID:'',
    agentName: '',
    userNewName: '',
    password: '',
    confirmPassword: '',
  });

 
  const handleAgentNameChange = (event,val) => {
    if(val==="add"){
      setDialogData({ ...dialogData, agentName: event.target.value });
      setAgentName(event.target.value)
    }
    else{
      seteditedData({...editedData,agentName:event.target.value})
    }
   
  };

  
  const handleUsername = (event,val) => {
    if(val==="add"){
      setDialogData({ ...dialogData, userNewName:event.target.value });
      setNewUsername(event.target.value)
    }
    else{
      setIsEdited(true)
      setPrvValue(editedData.userNewName)
      seteditedData({...editedData,userNewName:event.target.value})
    }
  };

  const handlePasswordChange = (event,val) => {
    if(val==="add"){
      setDialogData({ ...dialogData,password:event.target.value });
      setPassword(event.target.value)
    }
   else{
    seteditedData({...editedData,password:event.target.value})
   }
  };

  const handleConfirmPasswordChange = (event,val) => {
    if(val==="add"){
      setDialogData({ ...dialogData, confirmPassword: event.target.value });
      setConfirmPassword(event.target.value)
    }
    else{
      seteditedData({...editedData,confirmPassword:event.target.value})
    }
    
  };

  function isUniqueValue(newDisplayName, newUserName, subLogin) {
    for (const login of subLogin) {
        if (login.DisplayName === newDisplayName) {
          setagentNameError("ProfileName Already Exist");
          return false

        }
        if(login.UserName === newUserName){
          setuserNewNameError("UserName Already Exist");
          return false
        }
    }
    return true; // Return true if no match is found
}

function isUniqueEditValue(newDisplayName, newUserName, subLogin) {
  debugger
  console.log(editedData)
  let id = editedData.Id
  console.log(subLogin)
  for (const login of subLogin) {
      if (login.DisplayName === newDisplayName && login.Id !== id) {
        setEditProfileNameError("Please Enter the ProfileName");
        return false

      }
      if(login.UserName === newUserName && login.Id !== id){
        setEditNameError("Please Enter the UserName");
        return false
      }      
  }

  if(newDisplayName === newUserName){
    setEditProfileNameError("ProfileName and UserName should be different");
    setEditNameError("ProfileName and UserName should be different");
    return false
  }
  return true; // Return true if no match is found
}

  const handleSubmit = () => {

    // console.log("values ", activeClients, agentName, userNewName, password, confirmPassword, clientType)
    ////debugger
    let Count = 0;
    // setuserTypeError('')
    setError('')
    setagentNameError('')
    setuserNewNameError('')
    setpasswordTypeError('')
    setconfirmPasswordError('')
    setClientTypeError('')



    if(!clientType){
      setClientTypeError("Please select the client Type")
      Count = 1;
    }
    if(!value3){
      setError("Please select the client")
      Count = 1;
    }
    
    if(!agentName.trim()){
      setagentNameError("Please Enter the ProfileName")
      Count = 1;
    }
  
    if(!userNewName.trim()){
      setuserNewNameError("Please Enter the UserName")
      Count = 1;
    }
    if(!password.trim()){
      setpasswordTypeError("Please Enter the Password")
      Count = 1;
    }
    if(!confirmPassword.trim()){
      setconfirmPasswordError("Please Enter the ConfirmPassword")
      Count = 1;
    }

    if(password != confirmPassword){
      setconfirmPasswordError("Password and ConfirmPassword not Matching")
      Count = 1;
    }


    if(Count === 0 ){

      if (!isUniqueValue(agentName, userNewName, subLogin)) {
        console.log("The agentName or userNewName already exists in the list.");
        // Modify the new values to be unique
        // For example, append some random number or string to ensure uniqueness

      }
      else{
        const data = {
          UserID : `${value3.ID}`,
          displayname: agentName,
          Name: userNewName,
          Password:password,
          Type:"sub"
        }
  
   
        console.log("data",data);
      newSubLogins(data,(response) =>{
        if(response.status === 200){
          console.log("new sub login data ",response.data)
          handleClear();
          const data = {
            type : 'sub'
          }
          getSubLoginDetails(data,(response) =>{
            if(response.status === 200){
              console.log("getSubLoginDetails",response.data);
              setSubLoginList(response.data);
            }
          })
        }
      })

      }

  }
};

  const handleClear = () => {
    // setUserType('');
    setError('');
    setValue3(null)
    setAgentName('');
    // setMobileNumber('');
    setNewUsername('');
    setPassword('');
    setConfirmPassword('');
    // setImage(null);
    setClientType('');
    setClientTypeError('')
    // setuserTypeError('')
    setagentNameError('')
    // setmobileNumberError('')
    setuserNewNameError('')
    setpasswordTypeError('')
    setconfirmPasswordError('')
    // setImageError('')

  }

  const handleEdit = (data) => {
    console.log("data to be edited ",data );
    const selectedClient = clientList.find(client => client.ID === data.ClientId[0]) || null;

    ////debugger //Image
    setShowMessage(false)
    seteditedData({
          Id:data.Id,
          agentName: data.DisplayName,
          userNewName: data.UserName,
          password: data.Password,
          confirmPassword: data.Password,
          UserID:selectedClient
    })
    setOpenDialogEdit(true);
  };


  const [editNameError,setEditNameError]=useState('')
  const [editedPasswordError,setEditedPasswordError]=useState('')
  const [showMessage,setShowMessage]=useState(false)


  const [editProfileNameError, setEditProfileNameError] = useState('')

  // const [mobileError, setMobileError] = useState('')
  const [editedConfirmPasswordError, setEditedConfirmPasswordError] = useState('')


  const handleUpdated = () => {
    let count = 0

    setEditNameError('');
    setEditProfileNameError('');
    setEditedPasswordError('')
    setEditedConfirmPasswordError('');

    console.log("editedData ",editedData );
    ////debugger
    //setShowMessage(false)
    const updateData = { 
      ID: editedData.Id,
      UserID:`${editedData.UserID.ID}`,
      displayname: editedData.agentName,
      Name: editedData.userNewName,
      Password: editedData.password,
      Type: "sub",
      Status: 1
    }
console.log("updateData==",updateData)
    // {
    //   "ID": 1175,
    //   "UserID": "766,765,767",
    //   "displayname": "test_ram",
    //   "Name": "test_ram",
    //   "Password": "test@123",
    //   "Type": "merge",
    //   "Status": 1
    // }


    // Id:'',
    // UserID:'',
    // agentName: '',
    // userNewName: '',
    // password: '',
    // confirmPassword: '',


    if (!editedData.agentName.trim()) {
      setEditProfileNameError("Please Enter the ProfileName");
      count = 1
    }
    
    if (!editedData.userNewName.trim()) {
      setEditNameError("Please Enter the UserName");
      
      count = 1
    }
    
    if (!editedData.password.trim()) {
      setEditedPasswordError("Please Enter the Password");
      count = 1
    }
    
    if (!editedData.confirmPassword.trim()) {
      setEditedConfirmPasswordError("Please Enter the ConfirmPassword");
      count = 1
    }
    
    if (editedData.password.trim() !== editedData.confirmPassword.trim()) {
      setEditedPasswordError("Password and ConfirmPassword not Matching");
      setEditedConfirmPasswordError("Password and ConfirmPassword not Matching");
      count = 1
    }


    if(editedData.password !== editedData.confirmPassword ){
      console.log("password and confirm password is not matching ")
    }

    if ( count === 0 && editedData.password === editedData.confirmPassword && updateData.UserID !== '' && updateData.UserID !== null && editedData.password !== '' && editedData.password !== null && editedData.agentName !== '' && editedData.agentName !== null && editedData.userNewName !== '' && editedData.userNewName !== null) {
     
      if (!isUniqueEditValue(editedData.agentName, editedData.userNewName, subLogin)) {
        console.log("The agentName or userNewName already exists in the list.");
        // Modify the new values to be unique
        // For example, append some random number or string to ensure uniqueness

      }
      else{
      updateLoginDetails(updateData,(response) =>{
        if(response.status === 200){
          setOpenDialogEdit(false);
  
          const data = {
            type : 'sub'
          }
          getSubLoginDetails(data,(response) =>{
            if(response.status === 200){
              console.log("getSubLoginDetails",response.data);
              setSubLoginList(response.data);
            }
          })
        }
      })
    }

    }

    
    

    


  
  }

  const handleClose = () => {
    setOpenDialogEdit(false);
    setIsEdited(false)
    setPrvValue('')
    setEditNameError('')
    setEditedPasswordError('')
    setShowMessage(false)
    setOpenMessage(false)

    setEditProfileNameError('');
    setEditedConfirmPasswordError('');
  };




  const handleDisable = (data) => {
    // Add your disable logic here
    console.log("disable data...",data);
    setOpenMessage(true)   
    // let Id=data.ID?data.ID:0
    setId(data)
    
  };

  const disableUser = () =>{
    console.log("rowData",rowData.Status);
    const disable = { 
      ID: rowData.Id,
      UserID:`${rowData.ClientId}`,
      displayname: rowData.DisplayName,
      Name: rowData.UserName,
      Password: rowData.Password,
      Type: "sub",
      Status: rowData.Status === 0 ? 1:0 ,
    }

    console.log("disbale option ",disable)

    updateLoginDetails(disable,(response) =>{
      if(response.status === 200){
        setOpenMessage(false)
        const data = {
          type : 'sub'
        }
        getSubLoginDetails(data,(response) =>{
          if(response.status === 200){
            console.log("getSubLoginDetails",response.data);
            setSubLoginList(response.data);
          }
        })
      }
    })



  //   const updateData = { 
  //       ID: editedData.Id,
  //       UserID:`${editedData.UserID.ID}`,
  //       displayname: editedData.agentName,
  //       Name: editedData.userNewName,
  //       Password: editedData.password,
  //       Type: "sub",
  //       Status: 1
  //     }
  // console.log("updateData==",updateData)

      // disableUserData(body,(response)=>{
      //   if(response.status===200){
      //     getUserDetails((response)=>{
      //       if(response.status === 200){
      //         setUserDetails(response.data) 
      //         setOpenMessage(false)
      //       }
      //     })
      //   }
      // })
    
  }


  // Function to handle the icon click
  const handlePasswordVisibilityToggle = (index) => {
    setVisiblePasswordIndex(visiblePasswordIndex === index ? null : index);
  };

  const handleClientType = (event) =>{
    // console.log("client type",event.target.value)
    setClientType(event.target.value)
        // setUserType('');
        setError('');
        setValue3(null)
        setAgentName('');
        // setMobileNumber('');
        setNewUsername('');
        setPassword('');
        setConfirmPassword('');
        // setImage(null);
        // setClientType('');
        // setClientTypeError('')
        // setuserTypeError('')
        setagentNameError('')
        // setmobileNumberError('')
        setuserNewNameError('')
        setpasswordTypeError('')
        setconfirmPasswordError('')
        // setImageError('')

  }

  return (
    
    <Card variant="outlined" sx={{ p: 3 }}>
      <CardContent>
      <Typography variant="h4" >Login Creations</Typography>          

        <Grid container spacing={2} alignItems="center">
          <Grid container spacing={2} alignItems="center" marginTop={4}>
            
          <Grid item md={2}>
                  <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-labell">Client Type</InputLabel>
                  <Select
                    
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={clientType}
                    label="Client Type"
                    onChange={handleClientType}
                    // helperText={touched.ClientType && errors.ClientType}
                    error={clientTypeerror  ? true : false }
                  >
                    
                    <MenuItem value="US">US</MenuItem>
                    <MenuItem value="INDIA">INDIA</MenuItem>

                </Select>
                {clientTypeerror  && <Typography variant="body2" color="error">{clientTypeerror}</Typography>}


                  </FormControl>
                  
                 
                  </Grid>
         
               <Grid item xs={2}>
               
                  <Autocomplete                 
                  value={value3}
                  onChange={(event, newValue) => {
                      setIsClientSelected(false)
                      setValue3(newValue);
                  }}
                  options={setTypeClient}
                  getOptionLabel={(option)=>option.displayname}
                  isOptionEqualToValue={(option, value) => option.ID === value.ID} 
                  renderOption={(props, option) => (
                    <li {...props} key={option.ID}>  
                      {option.displayname} 
                    </li>
                  )}
                  renderInput={(params) => (
                      <TextField
                      {...params}
                      placeholder="Client"
                      variant="outlined"
                      error={error  ? true : false }
                      />
                  )}
                  />
                {error  && <Typography variant="body2" color="error">{error}</Typography>}
            </Grid>

            <Grid item xs={2}>
              <FormControl fullWidth>
                  <TextField
                    id="agent-name"
                    variant="outlined"
                    value={agentName}
                    label="Profile Name"
                    onChange={(e)=>handleAgentNameChange(e,"add")}  
                    error={agentNameError  ? true : false }
                  />
              {agentNameError && <Typography color="error" variant="body2"  >{agentNameError}</Typography>}
              </FormControl>
            </Grid>

            {/* <Grid item xs={3}>
              <FormControl fullWidth>
                <TextField
                  id="mobile-number"
                  label="Mobile Number"
                  variant="outlined"
                  value={mobileNumber}
                  onChange={(e)=>handleMobileNumberChange(e,"add")}
                  error={mobileNumberError  ? true : false }
                  inputProps={{
                    pattern: "[0-9]{10}",
                    title: "Mobile number must be 10 digits",
                  }}
                  InputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />
                {mobileNumberError && <Typography color="error" variant="body2"  >{mobileNumberError}</Typography>}
              </FormControl>
            </Grid> */}
            
            <Grid item xs={2}>
              <FormControl fullWidth>
                <TextField
                  id="userdisplayName"
                  label="User Name"
                  variant="outlined"
                  value={userNewName}
                  onChange={(e)=>handleUsername(e,"add")}
                  error={userNewNameError  ? true : false }  
                  // inputProps={{
                  //   autocomplete: 'off',
                  //   form: {
                  //     autocomplete: 'off',
                  //   },
                  // }}
                />
              {userNewNameError && <Typography color="error" variant="body2"  >{userNewNameError}</Typography>}
              </FormControl>
            </Grid>

            <Grid item xs={2}>
              <FormControl fullWidth>
                {/* <TextField
                  id="userpassword"
                  label="Password"
                  variant="outlined"
                  value={password}
                  onChange={(e)=>handlePasswordChange(e,"add")}
                  error={passwordError  ? true : false }                  
                /> */}

                  <TextField
                  fullWidth
                  name="userpassword"
                  label="Password"
                  onChange={(e)=>handlePasswordChange(e,"add")}
                  value={password}
                  type={showPassword ? 'text' : 'password'}
                  error={passwordError  ? true : false }                  
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                    autoComplete: 'new-password'
                  }}
                />
      {passwordError && <Typography color="error" variant="body2"  >{passwordError}</Typography>}


              </FormControl>
            </Grid>

            <Grid item xs={2}>
              <FormControl fullWidth>

              <TextField
                  fullWidth
                  name="userpassword"
                  label="Confirm Password"
                  onChange={(e)=>handleConfirmPasswordChange(e,"add")}
                  value={confirmPassword }
                  type={showConfirmPassword ? 'text' : 'password'}
                  error={confirmPasswordError  ? true : false }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                          <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
      {confirmPasswordError && <Typography color="error" variant="body2"  >{confirmPasswordError}</Typography>}



                 </FormControl>
            </Grid>

             <Grid item xs={2} sx={{ height: "100%", marginTop: 2 }}>
            <Button variant="contained"  onClick={handleSubmit} disabled={isButtonDisabled}>
            Add
          </Button>
          <Button color="error" variant="contained" onClick={handleClear} sx={{ marginLeft: 1 }}>
            Clear
          </Button>

            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          alignItems="center"
          sx={{ marginTop: "1rem" }}
        >
          <Scrollbar>
            <TableContainer 
            >
 
              <Table stickyHeader>
                <TableHead sx={{background: '#f3f3f3' }}>
                  <TableRow sx={{background: '#f3f3f3' }}>
                    <TableCell>SNo</TableCell>
                    <TableCell>Client</TableCell>
                    <TableCell>ProfileName</TableCell>
                    <TableCell>Username</TableCell>
                    <TableCell>Password</TableCell>
                    {/* <TableCell>Image</TableCell>
                    <TableCell>Created On</TableCell>
                    <TableCell>Created By</TableCell> */}
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Your table rows go here */}
                  {subLogin.map((data, index) => (
                    <TableRow key={index}
                    // style={{ backgroundColor: data.Status === 0 ? '#F5B7B1' : 'inherit' }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      {/* <TableCell>{data.ID}</TableCell> */}
                      <TableCell>{data.ClientName}</TableCell>
                      {/* <TableCell>{data.UserType === 4 ? "Agent" :data.UserType === 3 ? "Supervisor" : data.UserType ===  9 ? 'Deployment':'' }</TableCell> */}
                      <TableCell>{data.DisplayName}</TableCell>
                      <TableCell>{data.UserName}</TableCell>

                        <TableCell>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body2" style={{ marginRight: 8 }}>
                        {visiblePasswordIndex === index ? data.Password : '••••••••'}
                      </Typography>
                      <IconButton
                        onClick={() => handlePasswordVisibilityToggle(index)}
                        edge="end"
                      >
                        {visiblePasswordIndex === index ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </div>
                  </TableCell>

                      <TableCell>
                        <Grid container spacing={2}>
                         <Grid item>
                           <Button
                            disabled={data.Status === 0}
                            style={{
                              backgroundColor: data.Status === 0 ? "gray" : "",
                              color: "white"
                            }}
                            variant="contained" 
                            onClick={() => handleEdit(data)}>
                              Edit
                            </Button>
                          </Grid>
                          
                          <Grid item>
                            <Button

                              variant="contained"
                              style={{
                                backgroundColor: data.Status === 1 ? "red" : "green",
                                color: "white"
                              }}
                              onClick={()=>handleDisable(data)}
                            >
                              {data.Status === 1 ? "Disable" : "Enable"}
                            </Button>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Grid>

        <Dialog open={openDialogEdit} onClose={handleClose} fullWidth={fullWidth} maxWidth={maxWidth}>
        <DialogContent>
       
        <Grid container spacing={2} alignItems="center">
            <Grid container spacing={2} alignItems="center" marginTop={4}>
              

            <Grid item xs={3}>
               <Autocomplete    
               value={editedData.UserID}             
               onChange={(event, newValue) => {
                   setIsClientSelected(false)
                   setValue3(newValue);
               }}
               options={clientList}
               getOptionLabel={(option)=>option.displayname}
               isOptionEqualToValue={(option, value) => option.ID === value.ID} 
               disabled
               renderOption={(props, option) => (
                 <li {...props} key={option.ID}>  
                   {option.displayname} 
                 </li>
               )}
               renderInput={(params) => (
                   <TextField
                   {...params}
                   placeholder="Client"
                   variant="outlined"
                   error={error  ? true : false }
                   disabled
                   />
               )}
               
               />
             {error  && <Typography variant="body2" color="error">{error}</Typography>}
         </Grid>
         
         
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <TextField
                    id="agent-name"
                    variant="outlined"
                    value={editedData.agentName}
                    label="Name"
                    onChange={(e)=>handleAgentNameChange(e,"edit")}
                    error={editProfileNameError ? true : false}

                  />
            {editProfileNameError && <Typography color="error" variant="body2"  >{editProfileNameError}</Typography>}

                </FormControl>
              </Grid>

            
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <TextField
                    id="userdisplayName"
                    label="User Name"
                    variant="outlined"
                    value={editedData.userNewName}
                    onChange={(e)=>handleUsername(e,"edit")}
                    error={editNameError ? true : false}
                  />
                  {editNameError && <Typography color="error" variant="body2"  >{editNameError}</Typography>}

                </FormControl>
                {/* {editNameError && (<Typography color='error' fontSize={12}>{editNameError}</Typography>)} */}
              </Grid>

              <Grid item xs={3}>
                <FormControl fullWidth>
                  <TextField
                    id="userpassword"
                    label="Password"
                    variant="outlined"
                    value={editedData.password}
                    onChange={(e)=>handlePasswordChange(e,"edit")}
                    error={editedPasswordError ? true : false}
                  />
              {editedPasswordError && <Typography color="error" variant="body2"  >{editedPasswordError}</Typography>}
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <FormControl fullWidth>
                <TextField
                  id="confirmPassword"
                  label="Confirm Password"
                  variant="outlined"
                  value={editedData.confirmPassword}
                  onChange={(e) => handleConfirmPasswordChange(e, "edit")}
                  error={editedConfirmPasswordError ? true : false}
                />
              {editedConfirmPasswordError && <Typography color="error" variant="body2"  >{editedConfirmPasswordError}</Typography>}
                </FormControl>
              </Grid>

             

              <Grid item xs={12} sx={{ height: "100%",textAlign:'right'}}>
                <Button color="error" variant="contained" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="contained" sx={{ marginLeft: 1 }} onClick={handleUpdated}>
                  Save
                </Button>
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center" marginTop={4} marginLeft={1}> 
                 {showMessage && (<Typography color='#3f51b5'>Details Updated Successfully!!</Typography>)}
            </Grid>
          </Grid>
          


        </DialogContent>
      </Dialog>

  

      <Dialog 
        open={openMessage}
        onClose={handleClose}>
            <DialogContent>
              <Stack
                //  direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ maxWidth: 350, textAlign: "center" }}
              >
                <Iconify
                  icon={"eva:alert-triangle-outline"}
                  sx={{ fontSize: 92, color: "#ffb2a3" }}
                />
                <Typography sx={{ color: "gray" }} variant="h5">
                  {" "}
                  Are you sure you want to {rowData.Status === 1 ? "Disable" : "Enable"}  the user :  {rowData.DisplayName}?
                </Typography>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={handleClose}
                color="error"
              >
                No
              </Button>
              <Button variant="contained"  onClick={disableUser}>
                Yes
              </Button>
            </DialogActions>
          </Dialog>

      </CardContent>
    </Card>

  );
};

export default AddNewLogin;