import axios from '../axios'
import { getURL, jsonToParam } from 'src/utils/config';
import { handleResponse } from 'src/utils/responseHandler';
import { jsonToFormData } from 'src/utils/config';


// get cameras data
const getLicensePlateData = (callback) => {
    // let params = jsonToParam(body)
    // params = params ? `?${params}`: ''
    axios({
        method: 'get',
        url: `${getURL('/license-plate-information')}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

// const getVehicalTracking = (callback) => {
//     // let params = jsonToParam(body)
//     // params = params ? `?${params}`: ''
//     axios({
//         method: 'get',
//         url: `${getURL('/object-tracking')}`,
//         validateStatus: (status) => handleResponse(status)
//     }).then((response) => callback(response))
// }


const getVehicalTracking = (data, callback) => {
    const { from_date , to_date } = data
    axios({
      method: 'get',
      url: `${getURL('/object-tracking')}?from_time=${from_date}&to_time=${to_date}`,
      validateStatus: (status) => handleResponse(status),
    }).then((response) => callback(response));
  };


const setDeleteToken = (formdata, callback) => {
    axios({
        method: 'post',
        url: getURL('notification/web-logout'),
        data: jsonToFormData(formdata),
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

export { getLicensePlateData ,getVehicalTracking,setDeleteToken}