
import React, { createRef, useEffect, useRef, useState } from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Divider, InputAdornment, ListItemText, OutlinedInput, Tab, TablePagination, TextareaAutosize, useRadioGroup, useStepContext } from '@mui/material';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '@mui/material';
import Iconify from 'src/components/Iconify';
import axios from 'src/axios';
import {
  Checkbox,
  CardContent,
  Card,
  List,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  ListItem,
  TableContainer,
  FormControl,
  Paper,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableHead,
} from '@mui/material';
import Page from 'src/components/Page';
import { getCamerasList , getClientList} from 'src/api/analytics';

import { getProtocols , setAddProtocol ,getClientInfo , setSaveProtocol ,getProtocolsInfo ,deleteMessage ,setMultiDevicesProtocol,updateDeviceProtocolsInfo,addIntrusion, getInrusionInfo,setProtocolsInfoMessages,deleteIntrusionType,addUpdateIntrusion,UpdateMessage,DeleteDeviceMapping} from 'src/api/protocol';
import { getClientInfo as clientGroupInfo } from 'src/api/groups';
import { Field } from 'formik';
import { CompressOutlined, Label, TableRows, ViewKanban, VpnLock } from '@mui/icons-material';
import { filter, last, set } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import client from 'src/api/clients';
//import { getDevices } from 'src/api/devices';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { getClientGroupInfo } from 'src/api/liveCameras';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const protoIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const protoCheckedIcon = <CheckBoxIcon fontSize="small" />;
const Protocols= () => {


const [setClient,setSelectedClient] = useState([]);
const [setTypeClient,setSelectedTypeClient] = useState([]);

const [clientName,selectedClientName] = useState([]);
const [cameraList,setCameras] = useState([]);
const [selectedCamesList,setCamsList] = useState([]);
const [selectedCamItems, setCamSelectedItems] = useState([]);


const [protocolList,setProtocols] = useState([]); 
const [open, setOpen] = useState(false);
const [newProtocolname,setNewProtoName] = useState('');
const [selectedProto,setSelectedProtocol] = useState([]);
const [protocolInfo,setProtocolInfo] = useState('');


const [clientInfo,setClientDetails] = useState([]);

const [groupInfo,setgroupInfo] = useState([])
const [clientGroups,setClientGroups] = useState([])

// Form Fields

////console.log("selected Cam Items",selectedCamItems);

// police Number 
const [policeno, setPoliceNo] = useState('');

//mobile number 
const [mobileno, setMobileNo] = useState('');

//emil Change

const [email, setEmail] = useState('');

//Fire Number
const [fireNo, setFireNo] = useState('');

// address
const [address, setAddress] = useState('');

// Client ID mandatory 
const [isClientSelected, setIsClientSelected] = useState(false); // State variable to track client selection
const [saveError, setSaveError] = useState(false); // State to track save error

// Add new protocol Error
const [protocolNameError, setProtocolNameError] = useState(false);


// protocol Messages
const [data,setData] = useState([])
const [introsionMessages,setIntrosionMessages] = useState([]);

const [messages, setMessages] = useState([]);

const [message1,setMessage1] = useState('');
const [message2,setMessage2] = useState('');
const [message3,setMessage3] = useState('');


const [errorCams,setErrorCams]=useState('')
const [openError,setOpenError]=useState(false)

const [list,setList]=useState([])
const [header,setHeader]=useState('')

const [deviceHist,setDeviceHist]=useState({})

const [showConfirm,setShowConfirm]=useState(false)

const [protocolError,setProtocolError]=useState('')

const [policenoTextFields, setPolicenoTextFields] = useState(1);

const [activeCameras, setActiveCameras] = useState([])
const [inputValue, setInputValue] = useState([]);
const [checkValue,setCheckValue]=useState(false)

const [checkProtoValue,setCheckProtoValue]=useState(false)
const [activeProto,setActiveProto] = useState([])

const [assignProtocol,setAssignProtocol] = useState(false)
const [addProtocol,setAddProtocol] = useState(false)

const [addMessage,setAddMessage] = useState(false)

const [isGroupExist,setIsGroupExist] = useState(false)

const [groupList,setGroupList] = useState([])

const [removeData,setRemoveData] = useState([])
const [delProto,setDelProto] = useState(false)

const [displayedData,setDisplyedData] = useState([])


const [intrusionTypeError, setIntrusionTypeError] = useState(false);
const [incidentTypeError, setIncidentTypeError] = useState(false);
const [message1Error, setMessage1Error] = useState(false);

const [originalDataNew,setOriginalDataNew] = useState([])

const handleCloseError = ()=>{
  setOpenError(false)
}

const [clientType, setClientType] = useState('')
const [clientTypeerror, setClientTypeError] = useState(false)


const [existingDeviceIntoID,setExistingDevicesIntoID] = useState([])

////console.log("Introsion Messages",existingDeviceIntoID);
////console.log("selectedProto",selectedProto);

const [deviceDataExist,setDeviceDataExist] = useState(false);
const [groupCamerasInfo,setGroupCamersInfo] = useState([])

const [messageErrors, setMessageErrors] = useState({});

const handlePoliceNoChange = (event) => {
  setPoliceNo(event.target.value);
};


const handleMobileChange = (event) => {
  setMobileNo(event.target.value);
};

const handleEmailChange = (event) => {
  setEmail(event.target.value);
};

const handleFireChange = (event) => {
  setFireNo(event.target.value);
};

const handleAddressChange = (event) => {
  setAddress(event.target.value);
};

  useEffect(() => {

    getClientList((response) => {   
      if (response.status === 200) {
        setSelectedClient(response.data)
      }
    })

    getCamerasList((response) => {
      if (response.status === 200) {
        setCameras(response.data)
      }
    })    

  },[])

  useEffect(() =>{
    if(clientType === "INDIA"){
      console.log("clientType",clientType)
     const indiaClients = setClient.filter((row) => row.ClientType === 'INDIA');
     setSelectedTypeClient(indiaClients)
     
    }
    else {
     const usClients = setClient.filter((row) => row.ClientType === 'US');
     setSelectedTypeClient(usClients)


    }

  },[clientType])
  
  const [clientAvail,setClientAvail] = useState(false)


  useEffect(() => {
    ////debugger
    console.log("===============================",clientName.ID);
    let matchedCameras = [];

    // getCamerasList((response) => {
    //   if (response.status === 200) {
    //     setCameras(response.data)
    //   }
    // }) 
    
    //let clientId = value.ID
    //let groups = groupInfo.filter(item => item.client_id === cleintId)

    if(clientName.ID){
      //setClientAvail(true)
      let body = {groupId:0,clientId:clientName.ID}

      getClientGroupInfo(body,(response)=>{
     
        if(response.status === 200){
          
          if(response.data.length>0){
            setIsGroupExist(true)
            setGroupCamersInfo(response.data)
            const uniqueResult = Array.from(
              new Map(response.data.map(item => [`${item.GroupId}-${item.GroupName}`, item])).values()
              ).map(item => ({
                  GroupId: item.GroupId,
                  GroupName: item.GroupName
              }));       
            setGroupList(uniqueResult)
          }
          else{
            setIsGroupExist(false)
            setGroupList([])


            console.log("=================cameraList======================",cameraList);
  
            matchedCameras = cameraList.filter(camera => camera.clientid === clientName.ID);
            setCamsList(matchedCameras)
          }
        }
      })
    }

    else{
      //setClientAvail(false)
    }


    // if(!isGroupExist){
    //   matchedCameras = cameraList.filter(camera => camera.clientid === clientName.ID);
    //   setCamsList(matchedCameras)
    // }
    
 
    if(clientName.ID!==undefined && clientName.ID!==null){
    getClientInfo(clientName.ID,(response) => {
      if (response.status === 200) {
        setClientDetails(response.data);
      }
    })
  }
  else{
    setClientDetails([])
  }
  },[clientName])

  useEffect(()=>{
    console.log('clientAvail',clientAvail);
    console.log('protocol List',protocolList);
  },[clientAvail])

  const jsonToFormData = (data) => {
    let formdata = new FormData()
    for (let x in data) 
      formdata.append(x, data[x])
    return formdata
}

useEffect(()=>{
  //debugger
  if(data.length>0){
    const displayedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    setDisplyedData(displayedData)
  }
  else{
    setDisplyedData([])
  }
},[data])

let listHist=[]
  
useEffect(() => {

    let params={}

    debugger
    if(activeProto.length>0 && activeCameras.length===0){
       params = {
        device_id: [],
        intrusion_id: activeProto.join(',')
      };

      ////console.log(protocolList)

      if(protocolList.length>0){
        
        const IntrusionInfo = protocolList.filter(item=> activeProto.includes(item.Id))
        if (IntrusionInfo.length > 0) {
          const messageValues = Object.keys(IntrusionInfo)
              .filter(key => key.startsWith("Message"))
              .map(key => IntrusionInfo[key])
              .filter(value => value !== null && value !== "")
          setIntrosionMessages(messageValues)
          return;
        } 
      }

    }
    else if(activeCameras.length>0 && activeProto.length>0){

      //let deviceIds = selectedCamItems.map(item => item.deviceid);
  
      params = {
        device_id: activeCameras,
        intrusion_id: activeProto.join(',')
      };

      //setDeviceDataExist(true) commented on 2024-11-10

    }
    else if(activeCameras.length>0 && activeProto.length===0){
    
    //  let deviceIds = selectedCamItems.map(item => item.deviceid);
  
        params = {
          device_id: activeCameras,
          intrusion_id: ''
        };

      //setDeviceDataExist(true) commented on 2024-11-10
    }
  
    if(Object.keys(params).length){

    getProtocolsInfo(params,(response) => {
    
      if (response.status === 200) {       
        if(response.data.length>0){  
            //  if(response.data.length !== activeCameras.length && activeProto.length){
            //     setDeviceDataExist(false)
            //     if(protocolList.length>0){               
            //       const IntrusionInfo = protocolList.filter(item=>activeProto.includes(item.Id))
            //       if (IntrusionInfo.length > 0) {
            //         const messageValues = Object.keys(IntrusionInfo[0])
            //             .filter(key => key.startsWith("Message"))
            //             .map(key => IntrusionInfo[0][key])
            //             .filter(value => value !== null && value !== "")
            //         setIntrosionMessages(messageValues)
            //         //return;
            //     } 
            //     }
            //   }  
            //  else{
            //   const filteredData = response.data.filter(item => Object.keys(item).filter(key => key.startsWith('Message')).some(key => item[key] !== ""));
            //   setIntrosionMessages(filteredData)
            //   setDeviceDataExist(true)
            //  }
             
            //  if(response.data.length>1){
            //    // setProtocolInfo('')
            //  }
            const filteredData = response.data.filter(item => Object.keys(item).filter(key => key.startsWith('Message')).some(key => item[key] !== ""));
            setIntrosionMessages(filteredData)
            setDeviceDataExist(true)
        }
        else{
      
        setIntrosionMessages([])
        ////console.log(deviceDataExist)
        setDeviceDataExist(false);
         
        if(activeProto.length>0){
        
          if(protocolList.length>0){           
            const IntrusionInfo = protocolList.filter(item=>activeProto.includes(item.Id))
            if (IntrusionInfo.length > 0) {
              const messageValues = IntrusionInfo
              .flatMap(item => 
                Object.keys(item)
                  .filter(key => key.startsWith("Message"))
                  .map(key => item[key])
              )
              .filter(value => value !== null && value !== "");         
              setIntrosionMessages(IntrusionInfo)
              return
          }
        }
        }      
      }}
    })
    }
  
    // commented on 2024-11-10
    // if(existingDeviceIntoID.length>0 && (selectedProto.Id!==undefined && selectedProto.Id!==null)){
    // if(existingDeviceIntoID[0] === selectedProto.Id){
    
    //   setDeviceDataExist(true);
    //  }
    //  else{
    //   setDeviceDataExist(false);
    //  }
    // }

  },[activeCameras,activeProto])

  

// useEffect(() => {
//     debugger
//     console.log(introsionMessages)
//       if(introsionMessages.length === 0){
//         setMessage1('');
//         setMessage2('');
//         setMessage3('');
//       }
//       else{
//         if(introsionMessages.length=== 1 && !deviceDataExist){
//                 setMessage1(introsionMessages[0].Message1?introsionMessages[0].Message1:introsionMessages[0]);
//                 setMessage2(introsionMessages[0].Message2?introsionMessages[0].Message2:introsionMessages[1]);
//                 setMessage3(introsionMessages[0].Message3?introsionMessages[0].Message3:introsionMessages[2]);
//         }
//         else if(introsionMessages.length > 1 && !deviceDataExist){
//           introsionMessages[0] ? setMessage1(introsionMessages[0]) : setMessage1('')
//           introsionMessages[1] ? setMessage2(introsionMessages[1]) : setMessage2('')
//           introsionMessages[2] ? setMessage3(introsionMessages[2]) : setMessage3('')
//         }
//      }
//   },[introsionMessages])

useEffect(() => {
  if (introsionMessages.length > 0) {
    console.log('introsionMessages',introsionMessages)
    setMessages(introsionMessages);
  } else {
    setMessages([]); 
  }
}, [introsionMessages]);
  
useEffect(() => {
  ////console.log('clientInfo--------',clientInfo)
    // setFianlClientData([]);


   getInrusionInfo(0,(response)=>{
    if(response.status===200){
      setProtocols(response.data)
    }
    })

    if(clientInfo.length == 1){
      ////console.log("clientInfo Length" ,clientInfo)
      if(clientInfo[0].PoliceNo !== null){
        setPoliceNo(clientInfo[0].PoliceNo)
      }
      else{
        setPoliceNo('')
      }

      if(clientInfo[0].MobileNo !== null){
        setMobileNo(clientInfo[0].MobileNo)
      }
      else
      {
        setMobileNo('')
      }
      // setAddress(clientInfo[0].Address)
      if(clientInfo[0].FireNo !== null){
        setFireNo(clientInfo[0].FireNo)

      }
      else{
        setFireNo('')
      }

      if(clientInfo[0].Email !== null){
        setEmail(clientInfo[0].Email)
      }
      else{
        setEmail('')
      }
      if(clientInfo[0].Address !== null){
        setAddress(clientInfo[0].Address)
      }
      else{
        setAddress('')

      }
    }
    else{
      // setFianlClientData([]);

      setPoliceNo('')
      setMobileNo('')
      setAddress('')
      setFireNo('')
      setEmail('')
      setInputValue('');
    }

  },[clientInfo])

//const [clientSelected,setClientSelected]=useState(false)
const handleClient = (_, value) => {
  console.log("===============================client dropdown==============",value);
  setActiveCameras([])
  setCheckValue(false)
  setGroupCamersInfo([])
  setActiveProto([])
  setCheckProtoValue(false)

  if (!value) {
    setIsGroupExist(false)
    selectedClientName([]);
    setCamSelectedItems([]);
    setCamsList([]); 
    setPoliceNo('')
    setMobileNo('')
    setAddress('')
    setFireNo('')
    setEmail('')
    setIsClientSelected(false);
    setSaveError(false);
    setClientGroups([])
    setSelectedProtocol([]); 
    setGroupList([])
    //setSelectedProto([]);
    //setList([])
  } else {
    //setList([])
  
    setDeviceDataExist(false);
    setCamSelectedItems([]);
    selectedClientName(value);
    setIsClientSelected(true);
    setLastEmail(false)
    setLastFireNumber(false)
    setLastNumber(false)
    setLastContact(false)
    setSelectedProtocol([]);
    setGroupList([]) 
    setCamsList([])
    // if(groups.length>0){
    //   setIsGroupExist(true)
    //   setGroupList(groups)
    // }
    // else{
    //   setIsGroupExist(false)
    //   setGroupList([])
    // }
    
    
    //console.log(groups)
    
  }
};

const [openAuto,setOpenAuto]=useState(false)

const handleCamera = (event,value)=>{
  let actCams=[];
  if (value.some(obj => obj.hasOwnProperty('selectall'))){
    if(activeCameras.length===selectedCamesList.length){
      setActiveCameras([])
      setCheckValue(false)
    }
    else{
      selectedCamesList.forEach(item=>{
        actCams.push(item.deviceid)
      })
      setActiveCameras(actCams)
      setCheckValue(true)
    }
  }
  else{
    let id=value[0].deviceid;
    if(activeCameras.includes(id)){
      let filterArr = activeCameras.filter(divid => divid !== id);
      
      if(filterArr.length === 0){
        setIntrosionMessages([])  // updated on 2024-10-29
      }
      setActiveCameras(filterArr);
      if(checkValue){
        setCheckValue(false)
      }
    }
    else{
      setActiveCameras((prv)=>[...prv,id])
    }
  }
}

useEffect(()=>{
  if(activeCameras.length===selectedCamesList.length && activeCameras.length>0){
    setCheckValue(true)
  }

  if(activeCameras.length > 0){
    setClientAvail(true)
  }
  else{
    setClientAvail(false)
  }
},[activeCameras])


const handleOpen = () => {
  setIsExisted(false)
  setOpen(true);
};
const handleClose = () => {
  setOpen(false);
  setNewProtoName('');
  setProtocolNameError(false);
};

const [isExisted,setIsExisted]=useState(false)

useEffect(()=>{
  setMessageErrors({})
  if(activeProto.length === protocolList.length && activeProto.length>0){
    setCheckProtoValue(true)
  }
},[activeProto])

const handleProtocol = (event ,value) => {
  let actProto=[];
  if (value.some(obj => obj.hasOwnProperty('selectall'))){
    if(activeProto.length===protocolList.length){
      setActiveProto([])
      setCheckProtoValue(false)
      setSelectedProtocol([]);
      setIntrosionMessages([]);
      setDeviceDataExist(false)
      setProtocolInfo('')
    }
    else{
      protocolList.forEach(item=>{
        actProto.push(item.Id)
      })
      setActiveProto(actProto)
      setCheckProtoValue(true)
    }
  }
  else{
    let id=value[0].Id;
    if(activeProto.includes(id)){
      let filterArr = activeProto.filter(val => val !== id);
      
      setActiveProto(filterArr);
      if(checkProtoValue){
        setCheckProtoValue(false)
      }
    }
    else{
      setActiveProto((prv)=>[...prv,id])
    }
  }
}

// complete Protocol Save 

const handleProtocolSave = () => {
  if(!clientTypeerror){
    setClientTypeError(true); // Set save error if client is not selected
    return;
  }

  if (!isClientSelected) {
    setSaveError(true); // Set save error if client is not selected
    return;
  }

  // //console.log('Email',email);
const data = {
  ClientId: clientName.ID,
  PoliceNo: policeno,
  FireNo: fireNo,
  Address:address,
  Email: email,
  MobileNo: mobileno
};
  
setSaveProtocol(data, (response) => {
    if(response.status === 200) {
      ////console.log('Protocol Name:',response);

      setPoliceNo('')
      setMobileNo('')
      setAddress('')
      setFireNo('')
      setEmail('')

      if(clientName.ID!==undefined && clientName.ID!==null){
        getClientInfo(clientName.ID,(response) => {
          if (response.status === 200) {
            if(response.data !== null){
              setClientDetails(response.data);
            }
          }
        })
      }
    }
  })


}

//Handle Intosion Messages

const handleIntrusionMessageOneChange = (event) => {
    setMessage1(event.target.value);
}
const handleIntrusionMessageTwoChange = (event) => {
  setMessage2(event.target.value);

}
const handleIntrusionMessageThreeChange = (event) => {
  setMessage3(event.target.value);

}

// Handle protocol message Save
const handleProtocolMessageSave = () => {
  debugger
  const deviceIds = selectedCamItems.map(item => item.deviceid);

  if(activeCameras.length !== 0 ){
    ////console.log("Devices",deviceIds);
  
    // const data = activeCameras.flatMap(DeviceId => {
    //     // const messages = [message1, message2, message3];
    //     // const filteredMessages = messages.filter(message => message !== null);
    //     return {
    //       DeviceId: DeviceId,
    //       IntrusionId: selectedProto.IntrusionId,
    //     };

       
    //     // return filteredMessages.map((message, index) => ({
    //     //   DeviceId: DeviceId,
    //     //   IntrusionId: selectedProto.IntrusionId,
    //     //   Message: message
    //     // }));
    //   });

    const data = {
      DeviceId: activeCameras.map(item => `${item}`).join(','),
      IntrusionId: activeProto.join(',')
    }
    
      //console.log(data)
      addUpdateIntrusion(data,(response)=>{
        ////console.log(response)
        if(response.status === 200){
          setHeader('')
          setErrorCams('Protocols Added Successfully')
          setOpenError(true)
          setDeviceDataExist(true)
          HandleProtocolInfo()
          setProtocolInfo('')
          setSelectedProtocol([])
        }
      })
    // setMultiDevicesProtocol(data,(response)=>{
    //   if(response.status === 200){
    //     // need to show alert box
        
    //     setHeader('')
    //     setErrorCams('Protocols Added Successfully')
    //     setOpenError(true)

    //     HandleProtocolInfo()
        
    //   }})
    }
  else {
  
    const body = {
      IntrusionId: activeProto.join(',') ,
      Message1: message1,
      Message2: message2,
      Message3: message3
    }

    if (message1 === null || message1 === '') delete body.Message1; if (message2 === null || message2 === '') delete body.Message2; if (message3 === null || message3 === '') delete body.Message3;

    if(Object.keys(body).length){
    setProtocolsInfoMessages( body,(response) =>{
      
      if(response.status === 200 ){
        setOpenError(true)
        setHeader('Protocol Message Added Successfully!!')
        setErrorCams('')
        HandleProtocolInfo()
        // setMessage1('');
        // setMessage2('');
        // setMessage3('');

      }
  
    })
  }
  }  
}


const [newText,setNewText]=useState('')
const addProtocolMessage = (values,deviceId)=>{
   
    let body=[{
      "DeviceId":parseInt(deviceId),
      "IntrusionId":selectedProto.IntrusionId,
      "Message":newText
    }]

    setMultiDevicesProtocol(body,(response) => {
      if (response.status === 200) {
          HandleProtocolInfo();
          ////console.log(newMessage)
          setNewMessage(newMessage.filter(item => item.sno !== values))
          setNewText('');
      }
    })
}

const textFieldRefs = useRef([]);

const [editable,setEditable]=useState({})

const [indexVal,setIndexVal] = useState(null)
const [deviceVal,setDeviceVal] = useState(0)

//const [originalData, setOriginalData] = useState({});


const handleEdit = (row,index,deviceid) => {
  ////debugger
  const ind = introsionMessages.findIndex(item => item.Id === row.Id);
  if (ind !== -1) {
    console.log(introsionMessages[ind])
    setOriginalDataNew(introsionMessages[ind]);
  }
 
     setEditable((prev) => ({
      ...prev,
      [`${deviceid}_${index}`]: true 
  }))
};

const HandleProtocolInfo = ()=>{
//debugger
 // let deviceIds = selectedCamItems.map(item => item.deviceid);

  let status=0;
  
  let params = {
    device_id: activeCameras,
    intrusion_id: selectedProto.IntrusionId?selectedProto.IntrusionId:activeProto.length>0?activeProto.join(','):''
  };
  getProtocolsInfo(params,(response) => {
    if (response.status === 200) {
      status=response.status
      setIntrosionMessages(response.data)
      // let obj = response.data;
      // let MessageCount=Object.keys(obj).filter(key => key.startsWith('Message') && obj[key] !== "").length;
    }
  })
 // callback(status)
}

const deleteProtoMessage = () =>{
  deleteMessage(protoData,(response) => {
    if (response.status === 200) {
      // rendering 
        handleCloseError()
        setprotoData({})
        setShowConfirm(false)  
        HandleProtocolInfo()      
    }
  })
}

const [protoData,setprotoData]=useState({})

// Define handleRemove function
const handleRemove = (index, key) => {
  const data = {
    Id : key
  }
  setprotoData(data)
  setShowConfirm(true)
  setOpenError(true)
  setHeader('Do You Want to Delete Protocol Message?')
  setErrorCams('')

};

const handleSaveData = () =>{
  debugger
  if(removeData){

    console.log(removeData)

    const data = removeData[0]
    const key = removeData[2]

    // if(data.hasOwnProperty(key)){
    //   data[key] = "";

    // let body = {DeviceId:row.DeviceId.toString(),IntrusionId:row.IntrusionId}
    //  DeleteDeviceMapping(body,(response)=>{
    //   //console.log(response)
    //   if(response.status === 200){
    //     HandleProtocolInfo()
    //     setRemoveData([])
    //     setDelProto(false)
    //   }
    // })
    // }

    const hasValuesAtIndices = removeData.slice(0, 3).every(value => value !== null && value !== undefined && value !== "");
    if (hasValuesAtIndices) {
      const [row, index, messageId] = removeData;
      handleSave(row, index, messageId, "")
    }
    
    
  }
}

// Define handleSave function
const handleSave = (row,index,messageId,type) => {
  debugger
  setMessageErrors(prev => {
    const newErrors = { ...prev };
    newErrors[`${row.DeviceId}_${index}`] = "";
    return newErrors;
  });
  let hasError = false
  setDelProto(false)
  if(!messageId){
    let allMessagesContainValues =[]

    if(!row.data){
      const messageKeys = Object.keys(row).filter(key => key.startsWith("Message"));
      allMessagesContainValues = [row.Message1, row.Message2, row.Message3].every(msg => msg !== null && msg.trim() !== "");
    
      const keyToCheck =allMessagesContainValues ? messageKeys[index-1] :  messageKeys[index];
      const isNullValue = row[keyToCheck] === null || row[keyToCheck] === "" || row[keyToCheck] === "null";
      console.log(isNullValue)
  
      if (isNullValue) {
        setMessageErrors(prev => ({
          ...prev,
          [`${row.DeviceId}_${index}`]: "This field is required"
        }));
        hasError = true;
      }
    }
    else {
      if(activeProto.length > 0){
        const messageKeys = Object.keys(row.data).filter(key => key.startsWith("Message"));
        const key = messageKeys[(row.sno-1)]
        const isNullValue = row.data[key] === null || row.data[key] === "" || row.data[key] === "null";
        console.log(newText)
        if(isNullValue && !newText){
          setMessageErrors(prev => ({
            ...prev,
            [`${row.data.DeviceId}_${index}`]: "This field is required"
          }));
          hasError = true;
        }
      }
      // else if(activeProto.length>1){
      //   //console.log(intrus)
      //   const val = groupMessagesByDeviceId(introsionMessages)
      //   console.log(val)
      //   return
      // }
    }
    // if(index >= 0 ){
    //   let allMessagesContainValues =[]

    //   if(!row.data){
    //     allMessagesContainValues = [row.Message1, row.Message2, row.Message3].every(msg => msg !== null && msg.trim() !== "");
      
    //     const keyToCheck =allMessagesContainValues ? messageKeys[index-1] :  messageKeys[index];
    //     const isNullValue = row[keyToCheck] === null || row[keyToCheck] === "" || row[keyToCheck] === "null";
    //     console.log(isNullValue)
    
    //     if (isNullValue) {
    //       setMessageErrors(prev => ({
    //         ...prev,
    //         [`${row.DeviceId}_${index}`]: "This field is required"
    //       }));
    //       hasError = true;
    //       // messageKeys.forEach((key, idx) => {
    //       //   if (!row[key] || row[key] === "null") {
    //       //     setMessageErrors(prev => ({
    //       //       ...prev,
    //       //       [`${row.DeviceId}_${index}`]: "This field is required"
    //       //     }));
    //       //     hasError = true;
    //       //   }
    //       // });
    //     }
    //   }
    // }

    
  }
  
  if(hasError){
    // setHeader('')
    // setOpenError(true)
    // setErrorCams('Please add Protocol Messages')
    return;
  }
  
  let text = ""; let callRemove = false;
   if(type === 'addNew'){
       row = row.data
       //console.log(row)
       if(key && newText){
        row[key] = newText
        setNewText('')
       }

       else{
        setHeader('Enter Protocol Message')
        return;
       }
      setNewMessage([])
   }
   if(messageId){
      //console.log(row)
      //console.log(row[messageId])
      row[messageId] = '';
      //text = "Protocol Message Removed Successfully!!";    
      let MessageCount=Object.keys(row).filter(key => key.startsWith('Message') && row[key] !== "").length; 
      if(MessageCount === 0){
        callRemove = true // to track if all messages are deleted 
      }
   }
   else {
    text = "Protocol Message Updated Successfully!!";
   }
    
   if(callRemove){
     let body = {DeviceId:row.DeviceId.toString(),IntrusionId:row.IntrusionId}
     DeleteDeviceMapping(body,(response)=>{
      //console.log(response)
      if(response.status === 200){
        HandleProtocolInfo()
        setRemoveData([])
        setDelProto(false)
      }
    })
   }
   else{

    let body={};
    body={IntrusionId:row.IntrusionId,DeviceId:row.DeviceId,Message1:row.Message1,Message2:row.Message2,Message3:row.Message3,Message4:row.Message4,Message5:row.Message5}

    // if(type !== 'addNew'){
    //   body={IntrusionId:row.IntrusionId,DeviceId:row.DeviceId,Message1:row.Message1,Message2:row.Message2,Message3:row.Message3}
    // }
    // else {
    //   body={IntrusionId:row.IntrusionId,DeviceId:row.DeviceId,Message1:row.Message1,Message2:row.Message2,Message3:row.Message3,Message4:row.Message4,Message5:row.Message5}
    // }
   
    UpdateMessage(body,(response)=>{
      //console.log(response)
      if(response.status === 200){
        setEditable((prev) => ({
          ...prev,
          [`${row.DeviceId}_${index}`]: false,
      }));
        if(text){
          setOpenError(true)
          setHeader(text)
        }
        setErrorCams('')
        HandleProtocolInfo();
        setAddMessage(false)

      }
    })
   
  }

};

const [newMessage,setNewMessage]=useState([])
const [key,setKey] = useState('')

const countNonEmptyMessages = (obj) => {
  return Object.keys(obj)
      .filter(key => key.startsWith('Message') && obj[key] !== "")
      .length;
};

const handleAdd = (DeviceId,messagesForDevice) => {
  
if(activeProto.length !== 0 && activeProto.length>1){
  setOpenError(true)
  setHeader('')
  setErrorCams('Select a single protocol to add a custom intrusion message for the device.')
  setAddMessage(false)


  return;
}

setAddMessage(true)

if(activeProto.length>0){  
  const keys = Object.keys(groupMessagesByDeviceId(introsionMessages));
  let filteredMessages=[]
  if (keys.includes(DeviceId.toString())) {
    filteredMessages = introsionMessages.filter(message => message.DeviceId ===parseInt(DeviceId,10));
    // //console.log(filteredMessages);
} 
const allMessagesAreNonNull = [
  filteredMessages[0]['Message1'],
  filteredMessages[0]['Message2'],
  filteredMessages[0]['Message3'],
  filteredMessages[0]['Message4'],
  filteredMessages[0]['Message5']
].every(message => message !== "" && message !== null);

  if(allMessagesAreNonNull){
    setOpenError(true)
    setHeader('')
    setErrorCams('You can only add 5 intrusion messages per device')
    setAddMessage(false)
    return;
  }
let messageKey; 
let obj =filteredMessages[0];
let sno=Object.keys(obj).filter(key => key.startsWith('Message') && obj[key] !== "").length;

// for (let key in filteredMessages[0]) {
//     if (key.startsWith('Message4') || key.startsWith('Message5')){
       
//        if(filteredMessages[0][key] === "") {
//         sno = sno+1;
//         messageKey = key;
//         break;
//     }
//   }
// }

messageKey = Object.keys(filteredMessages[0]).filter(key => filteredMessages[0][key] === null || filteredMessages[0][key] === "")[0];

if (!messageKey) {
    sno=sno+1;
    let maxMessageIndex = Math.max(...Object.keys(filteredMessages).filter(key => key.startsWith('Message')).map(key => parseInt(key.replace('Message', ''))));
    messageKey = `Message${maxMessageIndex + 1}`;
}

setKey(messageKey)
//console.log(messageKey)

const snoCount = filteredMessages.reduce((total, obj) => {
  return total + countNonEmptyMessages(obj);
}, 0);


setNewMessage(prevMessages => [
    ...prevMessages,
    {
      deviceid:DeviceId,
      sno: snoCount+1,
      message: '',
      data : filteredMessages[0],
    }
  ]);
  
  }
else{
    setOpenError(true)
    setHeader('')
    setErrorCams('Select Protocol Information')
    setAddMessage(false)
}
};


const groupMessagesByDeviceId= (messages) => {  
  ////debugger
  const groupedMessages = {};
  messages.forEach(message => {
    
    if (!groupedMessages[message.DeviceId]) {
      groupedMessages[message.DeviceId] = [];
    }
    groupedMessages[message.DeviceId].push(message);
    //console.log(groupedMessages)
  });

  return groupedMessages;
}





const handleInputChange = (row, value,deviceId,text,messageType) => {
  if(text === 'editable'){
    
    // let updatedIntrosionMessages = [...introsionMessages]; 
    // updatedIntrosionMessages = updatedIntrosionMessages.filter(item => item.Id === row.Id)
    // updatedIntrosionMessages[0][messageType] = value;
    
      setIntrosionMessages(prevData =>
        prevData.map(item => {
            if (item.Id === row.Id) {
                return {
                    ...item,
                    [messageType] : value === '' ? 'null' : value
                };
            }
            return item;
        })
      );
    
    
    //if (index !== -1) {
      //  const updatedIntrosionMessages = [...introsionMessages]; 
      //  //console.log(updatedIntrosionMessages[1][messageType])
      // // updatedIntrosionMessages[index][messageType] = value; 
      // // setIntrosionMessages(updatedIntrosionMessages); 
    //}
  }
 
};

const [lastNumber,setLastNumber]=useState(false)
const [lastFireNumber,setLastFireNumber]=useState(false)
const [lastEmail,setLastEmail]=useState(false)
const [lastContact,setLastContact]=useState(false)



const removeNumber = (e,index,type) => {
  e.stopPropagation()
  if(type==='policeNumber'){
    const numbersArray = policeno.split(',');
    numbersArray.splice(index, 1); 
    const updatedNumbers = numbersArray.join(',');
    if(updatedNumbers){
      setPoliceNo(updatedNumbers);
    }
    else{
      setPoliceNo('');
      setLastNumber(true)
    }
   
  }
 else if(type==='fireNo'){
    const numbersArray = fireNo.split(',');
    numbersArray.splice(index, 1); 
    const updatedNumbers = numbersArray.join(',');
    if(updatedNumbers)
      setFireNo(updatedNumbers);
    else{
      setFireNo('')
      setLastFireNumber(true)
    }
 }
 else if(type==='email'){
  const numbersArray = email.split(',');
  numbersArray.splice(index, 1); 
  const updatedNumbers = numbersArray.join(',');
  if(updatedNumbers){
    setEmail(updatedNumbers);
  }
  else{
    setEmail('')
    setLastEmail(true)
  }
 
}
else if(type==='mobile'){
  const numbersArray = mobileno.split(',');
  numbersArray.splice(index, 1); 
  const updatedNumbers = numbersArray.join(',');
  if(updatedNumbers){
    setMobileNo(updatedNumbers);
  }
  else{
    setMobileNo('')
    setLastContact('')
  }
}
};

const [addEmptyBox,setEmptyBox]=useState([
  {
    police:false,
    fire:false,
    email:false,
    contact:false
  }
])

const handleBoxClick = (val)=>{
  
  if(val==='policeno'){
      if(policeno){
      setPoliceNo(policeno + ',')
      }
      else{
        setEmptyBox(prevState => [
          {
            ...prevState[0], 
            police: true 
          },
          ...prevState.slice(1)
        ]);
        if(lastNumber){
          setLastNumber(false)
        }
      }
  }
  else if(val==='fireno'){
    //setFireNo(fireNo + ',');
    if(fireNo){
      setFireNo(fireNo + ',')
      }
      else{
        setEmptyBox(prevState => [
          {
            ...prevState[0], 
            fire: true 
          },
          ...prevState.slice(1)
        ]);
        if(lastFireNumber){
          setLastFireNumber(false)
        }
      }
  }
  else if(val==='email'){
    //setEmail(email + ',')
    if(email){
      setEmail(email + ',')
      }
      else{
        setEmptyBox(prevState => [
          {
            ...prevState[0], 
            email: true 
          },
          ...prevState.slice(1)
        ]);
        if(lastEmail){
          setLastEmail(false)
        }
      }
  }
  else if(val==='mobileno'){
   // setMobileNo(mobileno + ',')
   if(email){
    setMobileNo(mobileno + ',')
    }
    else{
      setEmptyBox(prevState => [
        {
          ...prevState[0], 
          contact: true 
        },
        ...prevState.slice(1)
      ]);
      if(lastContact){
        setLastContact(false)
      }
    }
  }
}
const [newIndex, setNewIndex] = useState(-1);

const handleNumberChange = (event, index,type) => {
  if(type==='police'){
    const numbersArray = policeno.split(',');
    numbersArray[index] = event.target.value;
    const updatedNumbers = numbersArray.join(',');
    setPoliceNo(updatedNumbers);
  }
  else if(type==='fire'){
    const numbersArray = fireNo.split(',');
    numbersArray[index] = event.target.value;
    const updatedNumbers = numbersArray.join(',');
    setFireNo(updatedNumbers);
  }
  else if(type==='email'){
    const numbersArray = email.split(',');
    numbersArray[index] = event.target.value;
    const updatedNumbers = numbersArray.join(',');
    setEmail(updatedNumbers);
  }
  else if(type==='mobile'){
    const numbersArray = mobileno.split(',');
    numbersArray[index] = event.target.value;
    const updatedNumbers = numbersArray.join(',');
    setMobileNo(updatedNumbers);
  }
};

const handleFilterOptions = (options, params) => {
  const filter = createFilterOptions();
  const filtered = filter(options, params);
  
  // Separate selected and unselected options
  const selectedOptions = filtered.filter(option => activeCameras.includes(option.deviceid));
  const unselectedOptions = filtered.filter(option => !activeCameras.includes(option.deviceid));
  
  // Sort options: selected options first, then unselected
  const sortedOptions = [...selectedOptions, ...unselectedOptions];

  return [{ title: 'Select All', selectall: true }, ...filtered];
};

const [value, setValue] = useState('one');
const handleChange = (event, newValue) => {
  ////debugger
  setErrors({})
    if(newValue === 'one'){
      setActiveProto([])
      setCheckProtoValue(false)
      setProtocolInfo('')
      setMessageErrors({})
      setEditable({})
      setAddProtocol(true)
      setAssignProtocol(false)
      if(deviceDataExist){
        setDeviceDataExist(false)
      }
      setClientType('')
      setIntrosionMessages([])
      setActiveCameras([])
      setSelectedProtocol([])
      setGroupList([])
      setIsGroupExist(false)
      setPoliceNo('')
      setMobileNo('')
      setEmail('')
      setAddress('')
      setFireNo('')
      setCamsList([])
      setCheckValue(false)
      IntrusionDetails(0)
      // if(data.length>0){
      //   const displayedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      //   setDisplyedData(displayedData)
      // }
    }
    else if(newValue === 'two'){
      setData([])
      setAddProtocol(false)
      setAssignProtocol(true)
      const initialFormDetails = {
        introsionType: '',
        incidentType: '',
        messageOne: '',
        messageTwo: '',
        messageThree: ''
      };
      setFormDetails(initialFormDetails);
      setIndexHist(null)
      //setData([])
      // //debugger
      // console.log(data)
      
    }
    setValue(newValue);
  };

  useEffect(()=>{

   // //console.log(introsionMessages)
    if(value === 'one'){
      setAddProtocol(true)
      setAssignProtocol(false)
      //setIntrosionMessages([])
    }
  },[])

  const [formDetails,setFormDetails] = useState({
    introsionType : '',
    incidentType : '',
    messageOne : '',
    messageTwo : '',
    messageThree : ''
  })

  const clearValues = () =>{
    setFormDetails({
      introsionType : '',
      incidentType : '',
      messageOne : '',
      messageTwo : '',
      messageThree : ''
    })

    setErrors({})
  }

 
  const [originalData,setOriginalData] = useState([])
  
  const [errors, setErrors] = useState({});

  const handleFormChange = (event,type) =>{
    
      if(type){
        setFormDetails((prv)=>({
          ...prv,
          [type] : event.target.value
        }))

        setErrors(prevErrors => ({
          ...prevErrors,
          [type]: ''
        }));
      }
  }

  const IntrusionDetails = (id,text) =>{
    getInrusionInfo(id,(response)=>{
      ////debugger
        if(response.status===200){
          setData(response.data)  
          if(text === 'updatevalues'){
            setProtocols(response.data)
          }       
        }
    })
  }

  const updateIntrusionTypes = (body) =>{
    setProtocolsInfoMessages(body,(response)=>{
      if(response.status === 200){
        getInrusionInfo(0,(response)=>{
          if(response.status===200){
            setProtocols(response.data)
          }
          })
      }
    })
  }


  useEffect(()=>{
    IntrusionDetails(0)
  },[])

const addIntrosion = () =>{ 
  ////debugger
  const newErrors = {};
  ////console.log(formDetails)
  Object.keys(formDetails).forEach(key => {
    
    //console.log(key)
    if (!formDetails[key] && (key !== 'messageTwo' && key !== 'messageThree')) {
      newErrors[key] = `${key.replace(/([A-Z])/g, ' $1').toLowerCase()} is required`;
    }
  });

  if(data.length>0){
    let isTypeAvailable = data.filter(item => item.IntrusionType === formDetails.introsionType)
    if(isTypeAvailable.length>0){
      //console.log('existed')
      newErrors['introsionType'] = `Intrusion Type Existed Already`;
    }
  }

  if (Object.keys(newErrors).length > 0) {
    setErrors(newErrors);
    return; 
  }

  let body = {"IntrusionType":formDetails.introsionType,"IncidentType":formDetails.incidentType,"Message1":formDetails.messageOne,"Message2":formDetails.messageTwo,"Message3":formDetails.messageThree}
  addIntrusion (body,(response)=>{
    if(response.status===200){
      
      //setData(prevData => [...prevData, formDetails]);

      IntrusionDetails(0,'updatevalues')

      setFormDetails({
        introsionType: '',
        incidentType: '',
        messageOne: '',
        messageTwo: '',
        messageThree: ''
      });
    }
  })

 
  }
 
  const [isEdited,setIsEdited] = useState(false)
  const [indexHist,setIndexHist] = useState(null)
  const [delPopUp,setDelPopUp] = useState(false)
  const [delIndex,setDelIndex] = useState(null)

  const handleDelete = () =>{
    
    let body = {"Id" : delIndex}
    deleteIntrusionType(body,(response)=>{
      ////debugger
      if(response.status === 200){      
        IntrusionDetails(0,'updatevalues')
        setDelPopUp(false)
      }
    })
  }


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
     setPage(newPage);
   };
 
  const handleChangeRowsPerPage = (event) => {
     setRowsPerPage(parseInt(event.target.value, 10));
     setPage(0);   };
 
  // const displayedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
 
  
  const handleUpdate = (type,index) =>{
    debugger
    if(type === 'edit'){
      setMessage1Error(false)
      setIncidentTypeError(false)
      setIntrusionTypeError(false)
      if(isEdited){
        const currentData = displayedData[indexHist];
        if (JSON.stringify(originalData) !== JSON.stringify(currentData)) {
          const updatedData = [...displayedData];
          updatedData[indexHist] = originalData;
          setData(updatedData);
        }
      }
      setOriginalData(JSON.parse(JSON.stringify(displayedData[index])));
      setIsEdited(true)
      setIndexHist(index)
    }
    else if(type === 'delete'){
        
        const currentData = displayedData[index];
        ////console.log(typeof(currentData))
        if(Object.keys(currentData).length !== 0){
          let IntrusionId = currentData.Id
          setDelPopUp(true)
          setDelIndex(IntrusionId)
        }      
    }
    else if(type === 'save'){
      ////debugger
      let body = data[index]
      console.log(body)
      let hasError = false
      if(body){
        if(!body.IntrusionType){
          hasError = true
          setIntrusionTypeError(true);
        } else {
          setIntrusionTypeError(false);
        }
        

        if(!body.IncidentType){
          hasError = true
          setIncidentTypeError(true);
        } else {
          setIncidentTypeError(false);
        }

        if(!body.Message1){
          hasError = true
          setMessage1Error(true);
        } else {
          setMessage1Error(false);
        }
      }

      if(hasError){
        // console.log(indexHist)
        // let text = !body.IntrusionType && !body.IncidentType ? "Please add IntrusionType and IncidentType" : !body.IntrusionType ? "Please Add IntrusionType" : !body.IncidentType ? "Please Add IntrusionType" : ''
        // if(text){
        //   setOpenError(true)
        //   setErrorCams(text)
        //   return;
        // }
        return
      }
      
      updateIntrusionTypes(body)
      setIsEdited(false)
      setIndexHist(null); 
    }
  }

  const defaultProtocol = {
    "Id": 0,
    "IntrusionType": "",
    "IncidentType": "",
    "Message1": "",
    "Message2": "",
    "Message3": "",
    "customKey": 0
  };

  const handleGroupChange = (_,value) =>{
    ////debugger
    if(value){
      setActiveCameras([])
      setActiveProto([])
      setCheckProtoValue(false)
      // let body = {group_id:value.GroupId,client_id:0}
      // clientGroupInfo(body,(response)=>{
      //   if(response.status === 200){
      //      if(response.data.length>0){
      //       setCheckValue(false)
      //       setCamsList(response.data)
      //      }
      //   }
      // })

      if(groupCamerasInfo.length>0){   
        let groupDetails = groupCamerasInfo.filter(val => val.GroupId === value.GroupId)
        setCheckValue(false)
        setCamsList(groupDetails)
      }    
    }
    else{
      setCheckValue(false)
      setCamsList([])
    }
  }


  const handleClientType = (event) =>{
    // console.log("client type",event.target.value)
    setActiveProto([])
    setCheckProtoValue(false)
    setIntrosionMessages([])
      setActiveCameras([])
      setSelectedProtocol([])
      setGroupList([])
      setIsGroupExist(false)
      setPoliceNo('')
      setMobileNo('')
      setEmail('')
      setAddress('')
      setFireNo('')
      setCamsList([])
      setCheckValue(false)
      IntrusionDetails(0)
      setGroupCamersInfo([])

    setClientType(event.target.value)
  }


  return (
<>
<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Protocols
          </Typography>
          {/* <Button variant="contained" to="#" 
         onClick={() => handleOpen()} startIcon={<Iconify icon="eva:plus-fill" />}
          
          >
            Add Protocols
          </Button> */}

</Stack>


    <Card>
            <TabContext value={value}>
              <TabList
                  onChange={handleChange}
                  aria-label="wrapped label tabs example"
                  sx={{ borderBottom: "1px solid #ebebeb", backgroundColor: "#f3f3f3" }}
                >
                  <Tab
                    value="one"
                    label="Add Protocols"
                    sx={{ marginRight: 10 }}
                  />
                  <Tab 
                    value="two" 
                    label="Assign Protocols"/>
                </TabList>
                
            </TabContext>
    </Card>

    {addProtocol && (
      <>
        <Box>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={3}>
                  <TextField 
                    label='Intrusion Type' 
                    value={formDetails.introsionType}
                    onChange={(e)=>handleFormChange(e,'introsionType')}
                    error={!!errors.introsionType}
                    helperText={errors.introsionType}
                    autoComplete="off"
                    fullWidth 
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField 
                    label='Incident Type' 
                    value={formDetails.incidentType}
                    onChange={(e)=>handleFormChange(e,'incidentType')}
                    error={!!errors.incidentType}
                    helperText={errors.incidentType}
                    autoComplete="off"
                    fullWidth />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField 
                    label='Message One' 
                    value={formDetails.messageOne}
                    onChange={(e)=>handleFormChange(e,'messageOne')}
                    autoComplete="off"
                    error={!!errors.messageOne}
                    helperText={errors.messageOne}
                    fullWidth />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField 
                    label='Message Two' 
                    value={formDetails.messageTwo}
                    onChange={(e)=>handleFormChange(e,'messageTwo')}
                    autoComplete="off"
                    fullWidth />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField 
                    label='Message Three' 
                    value={formDetails.messageThree}
                    onChange={(e)=>handleFormChange(e,'messageThree')}
                    autoComplete="off"
                    fullWidth />
                </Grid>
                <Grid item xs={6} sm={1} mt={1}>
                  <Button variant='contained' onClick={addIntrosion}>Submit</Button>
                </Grid>
                <Grid item xs={6} sm={1} mt={1}>
                  <Button color="error" variant="contained" onClick={clearValues}>Cancel</Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>

        <Box mt={1}>
          <Card>
             <CardContent>
             <TableContainer sx={{ width: '100%' }}>
               <Table>
                 <TableHead>
                   <TableRow sx={{backgroundColor: '#f2f2f2'}}>
                    <TableCell align="center">Sno</TableCell>
                    <TableCell align="center">Intrusion Type</TableCell>
                    <TableCell align="center">Incident Type</TableCell>
                    <TableCell align="center">Messages</TableCell>
                    <TableCell align="center">Action</TableCell>
                   </TableRow>
                 </TableHead>
                  <TableBody>
                    {displayedData.length > 0 ? (
                      displayedData.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            {indexHist === index ? (
                              <TextField
                                value={item.IntrusionType}
                                onChange={(e) => {
                                  const newData = [...data];
                                  newData[index].IntrusionType = e.target.value;
                                  setData(newData);
                                }}
                                error={intrusionTypeError && indexHist === index}
                                helperText={intrusionTypeError && indexHist === index ? "Intrusion Type is required" : ""}
                              />
                            ) : (
                              item.IntrusionType
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {indexHist === index ? (
                              <TextField
                                value={item.IncidentType}
                                onChange={(e) => {
                                  const newData = [...data];
                                  newData[index].IncidentType = e.target.value;
                                  setData(newData);
                                }}
                                error={incidentTypeError && indexHist === index} 
                                helperText={incidentTypeError && indexHist === index ? "Incident Type is required" : ""}
                              />
                            ) : (
                              item.IncidentType
                            )}
                          </TableCell>                                                  
                          <TableCell align="center" sx={{ width: '500px', overflow: 'hidden', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                            {indexHist === index ? (
                              <Box display="flex" flexDirection="column" gap={1}>
                                <TextField
                                  value={item.Message1}
                                  onChange={(e) => {
                                    const newData = [...data];
                                    newData[index].Message1 = e.target.value;
                                    setData(newData);
                                  }}
                                  label='Message One'
                                  fullWidth
                                  error={message1Error && indexHist === index}
                                  helperText={message1Error && indexHist === index ? "Message One is required" : ""}
                                />
                                <TextField
                                  value={item.Message2}
                                  onChange={(e) => {
                                    const newData = [...data];
                                    newData[index].Message2 = e.target.value;
                                    setData(newData);
                                  }}
                                  label="Message Two"
                                  fullWidth
                                />
                                <TextField
                                  value={item.Message3}
                                  onChange={(e) => {
                                    const newData = [...data];
                                    newData[index].Message3 = e.target.value;
                                    setData(newData);
                                  }}
                                  label = "Message Three"
                                  fullWidth
                                />
                              </Box>
                            ) : (
                              <Box display="flex" flexDirection="column" gap={1} textAlign="left">
                                <Box>
                                  {item.Message1 ? (
                                    `1 . ${item.Message1}`
                                      ) : (
                                        <Typography variant="body2" color="textSecondary">This message is currently unavailable.</Typography>
                                      )}
                                </Box>
                                <Divider />
                                  <Box>
                                    {item.Message2 ? (
                                      `2 . ${item.Message2}`
                                    ) : (
                                      <Typography variant="body2" color="textSecondary">This message is currently unavailable.</Typography>
                                    )}
                                  </Box>
                                <Divider />
                                  <Box>
                                    {item.Message3 ? (
                                      `3 . ${item.Message3}`
                                    ) : (
                                      <Typography variant="body2" color="textSecondary">This message is currently unavailable.</Typography>
                                    )}
                                  </Box>
                              </Box>
                            )}
                          </TableCell>                                                 
                          <TableCell align="center">
                            {indexHist === index ? (
                              <Box display="flex" justifyContent="center" gap={1}>
                                <Button variant='contained' onClick={() => handleUpdate('save',index)}>Save</Button>
                                <Button variant='contained' onClick={() => {
                                  if(isEdited){
                                    const currentData = displayedData[indexHist];
                                    if (JSON.stringify(originalData) !== JSON.stringify(currentData)) {
                                      const updatedData = [...displayedData];
                                      updatedData[indexHist] = originalData;
                                      setData(updatedData);
                                    }
                                  }
                                  setIsEdited(false) 
                                  setIndexHist(null)
                                  setOpenError(false)
                                  setErrorCams('')
                                }} sx={{ marginLeft: '3px' }}>Cancel</Button>
                              </Box>
                            ) : (
                              <Box display="flex" justifyContent="center" gap={1}>
                                <Button variant='contained' onClick={() => handleUpdate('edit', index)}>Edit</Button>
                                <Button variant='contained' onClick={() => handleUpdate('delete', index)} sx={{ marginLeft: '3px' }}>Remove</Button>
                              </Box>
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                          No Data
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
               </Table>
             </TableContainer>
             <TablePagination
              rowsPerPageOptions={[10, 50, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
             </CardContent>
          </Card>
        </Box>
      </>
    )}

    {assignProtocol && (<Box>
    <Card style={{ marginBottom: '5px' }}>
      <CardContent style={{ marginBottom: '-25px' }}>                    
        <Grid container spacing={2}>     
          <Grid item sx={{ flexBasis: '12.5%' }}>
                  <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-labell">Client Type</InputLabel>
                  <Select
                    
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={clientType}
                    label="Client Type"
                    onChange={handleClientType}
                    error={clientTypeerror && !clientType}
                    // helperText={touched.ClientType && errors.ClientType}
                  >
                    
                    <MenuItem value="US">US</MenuItem>
                    <MenuItem value="INDIA">INDIA</MenuItem>

                </Select>

                {(clientTypeerror && !clientType) && ( // Show error message if saveError and !isClientSelected
                                                  <Typography variant="body2" color="error">
                                                    Please select a client.
                                                  </Typography>
                                    )}  
                  </FormControl>
                  
                 
          </Grid>
          <Grid item sx={{ flexBasis: '20.8333%' }}>
                                    <FormControl fullWidth>
                                                    
                                    <Autocomplete
                                      id="tags-outlined-client"
                                      options={setTypeClient.map((client, clientIndex) => ({
                                        ...client,
                                        customKey: clientIndex, // Assuming clientIndex is a unique identifier
                                      }))}
                                      isOptionEqualToValue={(option, value) =>
                                        option.customKey === value.customKey
                                      }
                                      // options={setClient}
                                      getOptionLabel={(option) => `${option.displayname}`}
                                      //value={clientName}
                                      onChange={(e,val)=>{
                                        setProtocolInfo('')
                                        handleClient(e,val)
                                      }}
                                      //value={clientName.ID ? setClient.find((option) => option.ID === clientName.ID) : null}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Select Client"
                                          placeholder="Search Client Name..."
                                          error={saveError && !isClientSelected} // Add error state based on saveError and isClientSelected
                                        />
                                      )}
                                      renderOption={(props, option) => (
                                        <li {...props} key={option.customKey}>
                                          {option.displayname}
                                        </li>
                                      )}
                                    />
                                    {(saveError && !isClientSelected) && ( // Show error message if saveError and !isClientSelected
                                                  <Typography variant="body2" color="error">
                                                    Please select a client.
                                                  </Typography>
                                    )}
                                                              
                                    </FormControl>
          </Grid>
          {isGroupExist && (<Grid item sx={{ flexBasis: '20.8333%' }}>
          <FormControl fullWidth>
                        <Autocomplete
                            options={groupList} 
                            //value={groupList}
                            renderInput={(params) => <TextField {...params} label="Select Group" variant="outlined" />}
                            freeSolo 
                            onChange={handleGroupChange}
                            renderOption={(props, option) => (
                              <li {...props} key={option.GroupId}>
                                {option.GroupName}
                              </li>
                            )}
                            getOptionLabel={(option) => `${option.GroupName}`}
                        />
          </FormControl>
          </Grid>)}          
          <Grid item sx={{ flexBasis: '20.8333%' }}>
                                      <FormControl fullWidth>                                  
                                              <Autocomplete
                                                multiple
                                                id="tags-outlined-client"
                                                options={selectedCamesList}
                                                //getOptionLabel={(option) => `${option.deviceid} ${option.devicename}`}
                                                onChange={handleCamera}
                                                //value={selectedCamItems}
                                                
                                                //value={(checkValue) ? [{ title: 'Select All', all: true }] : inputValue.filter((camera) => activeCameras.includes(camera.deviceid))}
                                                value={[]}

                                                getOptionLabel={(option) => (option.selectall && selectedCamesList.length === activeCameras.length) ? 'Select All' : `${option.deviceid} ${option.devicename}`}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    label="Select Cameras"
                                                    placeholder="Search Camera Name..."
                                                  />
                                                )}
                                                
                                                disableCloseOnSelect={openAuto}
                                                open={openAuto}
                                                onOpen={() => setOpenAuto(true)}
                                                onClose={() => setOpenAuto(false)}

                                                // filterOptions={(options, params) => {
                                                //   const filter = createFilterOptions();
                                                //   const filtered = filter(options, params);
                                                //   return [{ title: 'Select All', selectall: true }, ...filtered];
                                                // }}

                                                filterOptions={handleFilterOptions}
                                        
                                                renderOption={(props, option, { selected }) => (
                                                  <li {...props}>
                                                    {option.selectall ? (
                                                      <>
                                                        <Checkbox
                                                          icon={icon}
                                                          checkedIcon={checkedIcon}
                                                          style={{ marginRight: 8 }}
                                                          checked={checkValue}
                                                          //onChange={() => setCheckValue(!checkValue)}
                                                        />
                                                        {option.title}
                                                      </>
                                                    ) : (
                                                      <>
                                                        <Checkbox
                                                          icon={icon}
                                                          checkedIcon={checkedIcon}
                                                          style={{ marginRight: 8 }}
                                                          checked={activeCameras.includes(option.deviceid)}
                                                          onChange={() => {
                                                            const isChecked = activeCameras.includes(option.deviceid);
                                                            const newActiveCameras = isChecked
                                                              ? activeCameras.filter((deviceId) => deviceId !== option.deviceid)
                                                              : [...activeCameras, option.deviceid];
                                                            setActiveCameras(newActiveCameras);
                                                          }}
                                                        />
                                                        {`${option.devicename}`}
                                                      </>
                                                    )}
                                                  </li>
                                                )}
                                                //getOptionKey={(option) => option.deviceid}
                                                isOptionEqualToValue={(option, value) => option.deviceid === value.deviceid}
                                              />
                                      </FormControl>
          </Grid>    
          <Grid item sx={{ flexBasis: '20.8333%' }}>
                        <FormControl fullWidth>
                        <Autocomplete
                            multiple
                            id="tags-outlined-client"
                            options={selectedCamesList.length>0 ? protocolList : []}
                            onChange={handleProtocol}
                            value={[]}
                            getOptionLabel={(option) => (option.selectall && protocolList.length === activeProto.length) ? 'Select All' : `${option.IntrusionType}`}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Protocol"
                                placeholder="Search Protocol Name..."
                              />
                              )}                                             
                              disableCloseOnSelect                           
                              filterOptions={(options, params) => {
                                const filter = createFilterOptions();
                                const filtered = filter(options, params);
                                return [{ title: 'Select All', selectall: true }, ...filtered];
                              }}                                      
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  {option.selectall ? (
                                    <>
                                      <Checkbox
                                        icon={protoIcon}
                                        checkedIcon={protoCheckedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={checkProtoValue}
                                      />
                                      {option.title}
                                    </>
                                  ) : (
                                    <>
                                      <Checkbox
                                        icon={protoIcon}
                                        checkedIcon={protoCheckedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={activeProto.includes(option.Id)}
                                        onChange={() => {
                                         // //debugger
                                          const isChecked = activeProto.includes(option.Id);
                                          const newActiveProtocols = isChecked
                                            ? activeProto.filter((val) => val !== option.Id)
                                            : [...activeProto, option.Id];
                                          setActiveProto(newActiveProtocols);
                                        }}
                                      />
                                      {`${option.IntrusionType}`}
                                    </>
                                  )}
                                </li>
                              )}
                              isOptionEqualToValue={(option, value) => option.Id === value.Id}
                            />
                        </FormControl>
          </Grid>
        </Grid>
      </CardContent> 
      <CardContent style={{ marginBottom: '-16px' }}>

          <Grid container spacing={2}>                           
                  <Grid item md={3}>
                      <InputLabel shrink>
                        Police Number
                      </InputLabel>
                      <Box
                        sx={{
                          border: '1px solid #b4b4b4',
                          padding: '8px',
                          borderRadius: '8px',
                          minHeight: isClientSelected && clientInfo.length == 1 && policeno && !lastNumber ? 'auto' : '50px', 
                        }}
                        onClick={() => handleBoxClick('policeno')}
                      >
                        {!lastNumber && isClientSelected && policeno.split(',').map((policeNumber, index) => (                                      
                          <Box
                            key={index}
                            sx={{
                              marginBottom: 1,
                              width:'70%',
                              backgroundColor:'#E8E8E8',
                              position:'relative',
                              borderRadius: '100px',
                              border:'none',
                              borderColor:'none'
                            }}                   
                          >
                                <TextField
                                  placeholder='Add Police Number'
                                  value={policeNumber}
                                  onClick={(e)=>{
                                    e.stopPropagation()
                                  }}
                                  onChange={(e) => handleNumberChange(e, index,'police')}
                                  fullWidth
                                  size="small"
                                  sx={{
                                    '& input': {
                                      height: '14px', 
                                      fontSize: '14px',
                                    },
                                    '& fieldset': {
                                      //borderRadius: '90px',
                                      border:'none'
                                    },
                                  }}
                                />
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    right: '8px',
                                    transform: 'translateY(-50%)',
                                    width: '15px',
                                    height: '15px',
                                    borderRadius: '50%',
                                    backgroundColor: '#B2BEB5',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                  }}>
                                  <IconButton
                                    onClick={(e) => removeNumber(e,index,'policeNumber')}                            
                                  >
                                  <CloseIcon fontSize="small" style={{ fontSize: '14px' }}/>
                                </IconButton>
                              </Box>                   
                          </Box>                                                     
                        ))}                 
                  
                      </Box>
                  </Grid>
                  
                  <Grid item md={3}>
                      <InputLabel shrink>
                            Fire Number
                      </InputLabel>
                      <Box
                        sx={{
                          border: '1px solid #b4b4b4',
                          padding: '8px',
                          borderRadius: '8px',
                          minHeight: isClientSelected && clientInfo.length == 1 && fireNo && !lastFireNumber ? 'auto' : '50px', 
                        }}
                        onClick={() => handleBoxClick('fireno')}
                      >
                        {!lastFireNumber && isClientSelected && fireNo.split(',').map((fireNo, index) => (
                          <Box
                            key={index}
                            sx={{
                              marginBottom: 1,
                              width:'70%',
                              backgroundColor:'#E8E8E8',
                              borderRadius: '100px',
                              border:'none',
                              position:'relative',
                              borderColor:'none'
                            }}                   
                          >
                                <TextField
                                  placeholder='Add Fire Number'
                                  value={fireNo}
                                  onClick={(e)=>e.stopPropagation()}
                                  onChange={(e) => handleNumberChange(e, index,'fire')}
                                  fullWidth
                                  size="small"
                                  sx={{
                                    '& input': {
                                      height: '14px', 
                                      fontSize: '14px',
                                    },
                                    '& fieldset': {
                                      borderRadius: 50,
                                      border:'none'
                                    }
                                  }}

                                />
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    right: '8px',
                                    transform: 'translateY(-50%)',
                                    width: '15px',
                                    height: '15px',
                                    borderRadius: '50%',
                                    backgroundColor: '#B2BEB5',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                  }}
                                >
                                  <IconButton
                                    sx={{
                                        position: 'absolute',
                                        top: '-8px',
                                        right: '-8px',                                               
                                        }}
                                    onClick={(e) => removeNumber(e,index,'fireNo')}
                                  >
                                    <CloseIcon fontSize="small" style={{ fontSize: '14px' }}/>
                                  </IconButton> 
                                </Box>                     
                          </Box>
                        ))}

                        {/* {!fireNo && isClientSelected && addEmptyBox[0].fire && (
                          <Box
                            sx={{
                              marginBottom: 1,
                              width:'70%',
                              backgroundColor:'#E8E8E8',
                              position:'relative',
                              borderRadius: '100px',
                              border:'none',
                              borderColor:'none'
                            }}                   
                          >
                                <TextField
                                  //className={classes.root}
                                  //value={policeNumber}
                                  onClick={(e)=>{
                                    e.stopPropagation()
                                  }}
                                  placeholder='Add Fire Number'
                                  onChange={(e) => {
                                    setFireNo(e.target.value)
                                  }}
                                  fullWidth
                                  size="small"
                                  sx={{
                                    '& input': {
                                      height: '14px', 
                                      fontSize: '14px',
                                    },
                                    '& fieldset': {
                                      //borderRadius: '90px',
                                      border:'none'
                                    },
                                  }}
                                />
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    right: '8px',
                                    transform: 'translateY(-50%)',
                                    width: '15px',
                                    height: '15px',
                                    borderRadius: '50%',
                                    backgroundColor: '#B2BEB5',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                  }}>
                                  <IconButton
                                    onClick={(e) => removeNumber(e,0,'fireNo')}                            
                                  >
                                  <CloseIcon fontSize="small" style={{ fontSize: '14px' }}/>
                                </IconButton>
                              </Box>                   
                          </Box>
                        )} */}

                        
                      </Box>
                  </Grid>

                  <Grid item md={3}>
                  <InputLabel shrink>
                            Email
                  </InputLabel>
                  <Box
                        sx={{
                          border: '1px solid #b4b4b4',
                          padding: '8px',
                          borderRadius: '8px',
                          minHeight: isClientSelected && clientInfo.length == 1 && email && !lastEmail ? 'auto' : '50px'
                        }}
                        onClick={() => handleBoxClick('email')}
                      >
                        {!lastEmail && isClientSelected && email.split(',').map((email, index) => (
                          <Box
                            key={index}
                            sx={{
                              marginBottom: 1,
                              width:'70%',
                              backgroundColor:'#E8E8E8',
                              borderRadius: '100px',
                              position:'relative',
                              border:'none'
                            }}                   
                          >
                                <TextField
                                  placeholder='Add Email'
                                  value={email}
                                  onClick={(e)=>e.stopPropagation()}
                                  onChange={(e) => handleNumberChange(e, index,'email')}
                                  fullWidth
                                  size="small"
                                  sx={{
                                    '& input': {
                                      height: '14px', 
                                      fontSize: '14px',
                                    },
                                    '& fieldset': {
                                      borderRadius: 50,
                                      border:'none'
                                    }
                                  }}
                                />
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    right: '8px',
                                    transform: 'translateY(-50%)',
                                    width: '15px',
                                    height: '15px',
                                    borderRadius: '50%',
                                    backgroundColor: '#B2BEB5',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                  }}
                                >
                                  <IconButton
                                    sx={{
                                        position: 'absolute',
                                        top: '-8px',
                                        right: '-8px',                                               
                                        }}
                                    onClick={(e) => removeNumber(e,index,'email')}
                                  >
                                    <CloseIcon fontSize="small" style={{ fontSize: '14px' }}/>
                                  </IconButton> 
                                </Box>                     
                          </Box>
                        ))}
                        
                  </Box>
                  </Grid>

                  <Grid item md={3}>
                  <InputLabel shrink>
                            Contact Number
                  </InputLabel>
                  <Box
                        sx={{
                          border: '1px solid #b4b4b4',
                          padding: '8px',
                          borderRadius: '8px',
                          minHeight: isClientSelected && clientInfo.length == 1 && mobileno && !lastContact ? 'auto' : '50px', 
                        }}
                        onClick={() => handleBoxClick('mobileno')}
                      >
                        {!lastContact && isClientSelected && mobileno.split(',').map((mobile, index) => (
                          <Box
                            key={index}
                            sx={{
                              marginBottom: 1,
                              width:'70%',
                              backgroundColor:'#E8E8E8',
                              borderRadius: '100px',
                              border:'none',
                              position:'relative',
                            }}                   
                          >
                                <TextField
                                  placeholder='Add Contact Number'
                                  value={mobile}
                                  onClick={(e)=>e.stopPropagation()}
                                  onChange={(e) => handleNumberChange(e, index,'mobile')}
                                  fullWidth
                                  size="small"
                                  sx={{
                                    '& input': {
                                      height: '14px', 
                                      fontSize: '14px',
                                    },
                                    '& fieldset': {
                                      borderRadius: 50,
                                      border:'none'
                                    }
                                  }}
                                />
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    right: '8px',
                                    transform: 'translateY(-50%)',
                                    width: '15px',
                                    height: '15px',
                                    borderRadius: '50%',
                                    backgroundColor: '#B2BEB5',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                  }}
                                >
                                    <IconButton
                                      sx={{
                                          position: 'absolute',
                                          top: '-8px',
                                          right: '-8px',                                               
                                          }}
                                      onClick={(e) => removeNumber(e,index,'mobile')}
                                    >
                                      <CloseIcon fontSize="small" style={{ fontSize: '14px' }}/>
                                    </IconButton>
                                </Box>                      
                          </Box>
                        ))}  
                  </Box>
              </Grid>

                  <Grid item md={3}>
                  <InputLabel shrink>
                            Address
                  </InputLabel>
                                      <TextField
                                        fullWidth
                                        value={address}
                                        onChange={handleAddressChange}
                                        InputLabelProps={{
                                          shrink: !!address,
                                        }}
                                        sx={{
                                          '& input': {
                                            //height: '10px', 
                                            fontSize: '14px',
                                          }
                                        }}
                                      />

                  </Grid>
                            
          </Grid>

      </CardContent> 
      <DialogActions>                       
          <LoadingButton type="submit"  onClick={handleProtocolSave} variant='outlined'>Save</LoadingButton>
      </DialogActions>         
    </Card>

    <Card >
      <CardContent style={{ marginBottom: '-16px' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Messages
            </Typography>
            
            

          </Stack>

                  { deviceDataExist && activeCameras.length !== 0  ? (
                  
                  Object.entries(groupMessagesByDeviceId(introsionMessages)).map(([deviceId, messagesForDevice]) => {
                    let show = 0;
                    console.log(messagesForDevice)
                  return  (                   
                    <div key={deviceId}>
                      <Typography variant="h6">{messagesForDevice[0]?messagesForDevice[0].devicename:''}</Typography>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{textAlign:'center'}}>S.No</TableCell>
                              <TableCell sx={{textAlign:'center'}}>Message</TableCell>
                              <TableCell sx={{textAlign:'center'}}>Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>                                                      
                            { messagesForDevice.length > 0 && messagesForDevice.map((row, idx) => {
                                let snoCounter = 0;
                                const messageKeys = Object.keys(row).filter(key => key.startsWith('Message'));                             
                                const messageValues = messageKeys.map(key => ({
                                  id: key,
                                  value: row[key]
                                })).filter(msg => (msg.value))
                                .map(item =>({
                                  id : item.id,
                                  value : item.value === 'null' ? '' : item.value
                                }));                                                          
                                return messageValues.map((message, index) =>                                  
                                {
                                  snoCounter++; 
                                  const continuousSno = messagesForDevice.slice(0, idx).reduce((acc, curr) => {
                                    const count = Object.keys(curr).filter(key => key.startsWith('Message')).map(key => curr[key]).filter(msg => msg).length;
                                    return acc + count;
                                  }, 0) + snoCounter;                              
                                  return (
                                  <TableRow key={`${row.DeviceId}_${message.id}`}>
                                    <TableCell sx={{ width: '5%' }}>{continuousSno}</TableCell>
                                    <TableCell sx={{ width: '85%' }}>                                 
                                      <TextField
                                        fullWidth                                
                                        value={message.value}  
                                        id={deviceId+'_'+message.value}   
                                        InputProps={{
                                          readOnly: !editable[`${row.DeviceId}_${continuousSno}`],
                                        }}     
                                        error={messageErrors[`${row.DeviceId}_${continuousSno}`]}
                                        helperText={messageErrors[`${row.DeviceId}_${continuousSno}`]}                  
                                        onChange={(e) => {
                                          handleInputChange(row, e.target.value,deviceId,'editable',message.id)
                                        }}
                                      />                                  
                                    </TableCell>
                                    <TableCell sx={{ width: '10%' }}>
                                      <Box sx={{display: 'flex',justifyContent: 'center'}}>
                                        {!(editable[`${row.DeviceId}_${continuousSno}`]) && (
                                          <>
                                            <Button variant='outlined' onClick={() => handleEdit(row,continuousSno,row.DeviceId)}>Edit</Button>
                                            <Button variant='outlined' onClick={() => {
                                              setRemoveData([row,continuousSno,message.id,""])
                                              setDelProto(true)
                                            }} sx={{marginLeft:'3px'}}>Remove</Button>
                                          </>
                                        )}
                                      </Box>
                                      <Box sx={{ marginTop: '2px'}}>
                                        {(editable[`${row.DeviceId}_${continuousSno}`]) && (
                                          <>
                                            <Button sx={{marginTop:'2px'}} variant='outlined' 
                                              onClick={() => 
                                              handleSave(row,continuousSno,"","update")}>
                                                Save
                                            </Button>
                                            <Button variant='outlined' sx={{marginLeft:'3px'}} onClick={()=>{
                                              ////debugger
                                              if(originalDataNew){
                                                //console.log(originalDataNew,introsionMessages,index,continuousSno)
                                                const index = introsionMessages.findIndex(item => item.Id === originalDataNew.Id);
                                                if (index !== -1) {
                                                  const updatedMessages = [...introsionMessages];
                                                  updatedMessages[index] = originalDataNew;
                                                  console.log(updatedMessages)
                                                  setIntrosionMessages(updatedMessages);
                                                  setMessageErrors({})
                                                }
                                                //setIntrosionMessages(updatedMessages);
                                              }
                                               setEditable((prev) => ({
                                                ...prev,
                                                [`${row.DeviceId}_${continuousSno}`]: false 
                                            }))
                                            }}>
                                              Cancel
                                            </Button>
                                          </>
                                        )}
                                      </Box>
                                    </TableCell>
                                  </TableRow>)}
                                  )                                                            
                            })}
                            {newMessage.length>0?newMessage[0].deviceid===deviceId.toString() && 
                              newMessage.map((values,idx)=>(
                                <TableRow key={idx}>
                                    <TableCell>{values.sno}</TableCell>
                                    <TableCell style={{ width: '100%' }}>
                                    <TextField
                                      fullWidth                              
                                      value={newText}
                                      onChange={(e)=>setNewText(e.target.value)}
                                      error={messageErrors[`${newMessage[0].deviceid}_${idx}`]}
                                      helperText={messageErrors[`${newMessage[0].deviceid}_${idx}`]}  
                                    />
                                    </TableCell>
                                    <TableCell>
                                      <Box sx={{display: 'flex',justifyContent: 'center'}}>
                                      <Button variant='outlined' onClick={()=>handleSave(values,idx,'','addNew')} sx={{marginRight:'3px'}}>Save</Button>
                                    
                                        <Button variant='outlined' onClick={(prv)=>{
                                          setMessageErrors({})
                                          setAddMessage(false)
                                          setNewMessage(prv =>
                                            prv.filter(entry => entry.sno !== values.sno)
                                          )
                                        }}>Remove</Button>
                                      </Box>
                                    </TableCell>
                                </TableRow>
                            )):''}

                          <TableRow>
                            <TableCell colSpan={3} style={{ textAlign: 'right' }}>
                              <Button 
                                variant="contained" 
                                onClick={() => handleAdd(deviceId, messagesForDevice)} 
                                //disabled={addMessage}
                                //disabled={messagesForDevice.every(msg => msg !== null && msg.trim() !== "")}    
                                // disabled={
                                //   [messagesForDevice[0].Message1, messagesForDevice.Message2, messagesForDevice.Message3, messagesForDevice.Message4, messagesForDevice.Message5].every(msg => msg !== null && msg.trim() !== "") // Check if all message fields are filled
                                // }                            
                              >
                                Add New Message
                              </Button>
                            </TableCell>
                          </TableRow>
                          
                          </TableBody>
                        </Table>
                        
                      </TableContainer>           
                        {/* <Box style={{ textAlign: 'right' }}>                          
                          <Button variant="contained" disabled={addMessage}
                            onClick={() => handleAdd(deviceId,messagesForDevice)}>
                              Add New Message
                          </Button>                       
                        </Box> */}
                    </div>
                  )})

                  ) : (
                    activeCameras.length !== 0 && activeProto.length>0 ? (
                      <>
                      {/* {devicename?<Typography variant="h6">{devicename}</Typography>:''} */}
                      {/* <Grid container alignItems="center" spacing={2} style={{ marginBottom: '10px' }}>
                        {message1 && (<Grid item md={10}>
                          <FormControl fullWidth>
                            <TextField
                              fullWidth
                              label="Message"
                              value={message1}
                              onChange={handleIntrusionMessageOneChange}
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                      
                        </Grid>)}
                      </Grid>
                        
                      <Grid container alignItems="center" spacing={2} style={{ marginBottom: '10px' }}>
                        <Grid item md={10}>
                          {message2 && (<FormControl fullWidth>
                            <TextField
                              fullWidth
                              label="Message"
                              value={message2}
                              onChange={handleIntrusionMessageTwoChange}
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>)}
                        </Grid>
                      </Grid>
                        
                      <Grid container alignItems="center" spacing={2} style={{ marginBottom: '10px' }}>
                        <Grid item md={10}>
                          {message3 && (<FormControl fullWidth>
                            <TextField
                              fullWidth
                              label="Message"
                              value={message3}
                              onChange={handleIntrusionMessageThreeChange}
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>)}
                        </Grid>
                      </Grid> */}

                      <Grid container alignItems="center" spacing={2} style={{ marginBottom: '10px' }}>
                              {/* {messages.map((message, index) => (
                                <Grid item md={10} key={index}>
                                  <FormControl fullWidth>
                                    <TextField
                                      fullWidth
                                      label={`Message ${index + 1}`}
                                      value={message}
                                      //onChange={(e) => handleMessageChange(e, index)}
                                      inputProps={{ readOnly: true }}
                                    />
                                  </FormControl>
                                </Grid>
                              ))} */}
                              {                              
                                messages.map((msg, index) => {
                                  console.log("Current msg:", msg);
                                  const messageValues = Object.keys(msg)
                                                      .filter(key => key.startsWith("Message"))
                                                      .map(key => msg[key])
                                                      .filter(value => value !== null && value !== "");   
                                    console.log("Filtered message values:", messageValues);
                                  return (
                                    <>
                                      <Typography variant="h6" mt={1} sx={{ ml: 2 }}>{msg.IntrusionType}</Typography>
                                        <Grid container spacing={2} sx={{marginTop:'1px'}}> 
                                          {messageValues.map((value, valueIndex) => (
                                            <Grid item md={10} key={valueIndex}>
                                              <FormControl fullWidth>
                                                <TextField
                                                  fullWidth
                                                  label={`Message ${valueIndex + 1}`}
                                                  value={value}
                                                  //onChange={(e) => handleMessageChange(e, index)}
                                                  inputProps={{ readOnly: true }}
                                                  sx={{ ml: 2 }}
                                                />
                                              </FormControl>
                                            </Grid>
                                          ))}
                                        </Grid>
                                        <Divider sx={{ my: 2, bgcolor: 'lightgray', height: '2px' }}></Divider>
                                    </>
                                  );
                                })
                              }
                      </Grid>
      
                      <DialogActions>
                    <LoadingButton type="submit" onClick={handleProtocolMessageSave} variant='outlined'>Save</LoadingButton>
                    </DialogActions>
                    </>
                    ) : null 
                  )} 

                  {/* {activeCameras.length === 0 && (selectedProto.IntrusionId || activeProto.length>0) && (
                        <>
                          <Grid container alignItems="center" spacing={2} style={{ marginBottom: '10px' }}>
                            <Grid item md={10}>
                              <FormControl fullWidth>
                                <TextField
                                  fullWidth
                                  label="Message"
                                  value={message1}
                                  onChange={handleIntrusionMessageOneChange}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid container alignItems="center" spacing={2} style={{ marginBottom: '10px' }}>
                            <Grid item md={10}>
                              <FormControl fullWidth>
                                <TextField
                                  fullWidth
                                  label="Message"
                                  value={message2}
                                  onChange={handleIntrusionMessageTwoChange}

                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid container alignItems="center" spacing={2} style={{ marginBottom: '10px' }}>
                            <Grid item md={10}>
                              <FormControl fullWidth>
                                <TextField
                                  fullWidth
                                  label="Message"
                                  value={message3}
                                  onChange={handleIntrusionMessageThreeChange}

                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </>
                      )}

                  {activeCameras.length === 0 && selectedProto.IntrusionId && (
                  <>
                              
                  <DialogActions>
                  <LoadingButton type="submit" onClick={handleProtocolMessageSave} variant='outlined'>Save</LoadingButton>
                  </DialogActions>
                  </>
                      )} */}
      </CardContent>
    </Card>
    </Box>)}



<Dialog open={openError} onClose={handleCloseError} maxWidth="xs" fullWidth>
  <DialogTitle>{header}</DialogTitle>
    <DialogContent>
             <Typography variant='h6'>{errorCams}</Typography>
  </DialogContent>
  <DialogActions>
            {showConfirm && <Button variant="contained" onClick={deleteProtoMessage}>Ok</Button>}
            <Button color='error' onClick={handleCloseError} variant='outlined' >Close</Button>
  </DialogActions>
</Dialog>

<Dialog open={delPopUp}>
    <DialogContent>Are You Sure You Want to Delete?</DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={handleDelete}>Ok</Button>
      <Button color='error' onClick={()=>setDelPopUp(false)}>Close</Button>
    </DialogActions>
</Dialog>

<Dialog open={delProto}>
    <DialogContent>Are You Sure You Want to Delete Protocol Messages?</DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={handleSaveData}>Ok</Button>
      <Button color='error' onClick={()=>{
        setRemoveData([])
        setDelProto(false)
      }}>Close</Button>
    </DialogActions>
</Dialog>


</>
  );
};

const ArrowBox = ({ field, entry, index, removeEntry }) => {
  const containerStyle = {
    position: 'relative',
    width: '100%',
    marginBottom: '8px', 
  };

  const boxStyle = {
    backgroundColor: '#90ee90',
    borderRadius: '4px',
    border: '0px solid #ccc',
    padding: '10px',
    boxSizing: 'border-box', 
    width: '100%',
  };

  const crossStyle = {
    position: 'absolute',
    right: '2px',
    top: '50%',
    transform: 'translateY(-50%)',
    color: 'grey',
    cursor: 'pointer',
  };

  return (
    <div style={containerStyle}>
      <div style={boxStyle}>
        <Typography variant="body1" style={{ textAlign: 'left', width: '100%' }}>
          {entry}
        </Typography>
        <IconButton
          onClick={() => removeEntry(field, index)}
          style={crossStyle}
        >
          <ClearIcon style={{ width: '15px' }} />
        </IconButton>
      </div>
    </div>
  );
};



export default Protocols;