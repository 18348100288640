// import React, { useState, useEffect } from "react";
// import {
//   Card,
//   CardContent,
//   Container,
//   Stack,
//   Typography,
//   TextField,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   Button,
//   Grid,
//   TableHead,
//   TableRow,
//   Box,
//   TablePagination,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
// } from "@mui/material";
// import Scrollbar from "../components/Scrollbar";
// import SaveAltIcon from "@mui/icons-material/SaveAlt";
// import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
// import { getVehicalTracking } from "src/api/vehicles";
// import * as XLSX from "xlsx";
// import Page from "src/components/Page";
// import { formatMeridiem } from "@mui/x-date-pickers/internals/utils/date-utils";
// import { exec } from "apexcharts";
// import dayjs from "dayjs";

// const VehicalTrackingInfo = () => {
//   const [vehicleData, setVehicalData] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);
//   const [searchText, setSearchText] = useState("");
//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   const [page, setPage] = useState(0);
//   const [fromDate, setFromDate] = useState(dayjs().startOf('day'));
//   const [toDate, setToDate] = useState(dayjs().endOf('day'));
//   const [currentDatestart,setCurrentDateStart] = useState(dayjs().startOf('day'));
//   const [currentDateEnd,setCurrentDateEnd] = useState(dayjs().endOf('day'));


//   // useEffect(() => {
//   //   if (fromDate && toDate) {
//   //     const body = {
//   //       from_date: fromDate.format("YYYY-MM-DD HH:mm:ss"),
//   //       to_date: toDate.format("YYYY-MM-DD HH:mm:ss"),
//   //     };

//   //     getVehicalTracking(body, (response) => {
//   //       if (response.status === 200 && response.data) {
//   //         setVehicalData(response.data);
//   //         setFilteredData(response.data);
//   //       }
//   //     });
//   //   }
//   //   else if(fromDate === currentDatestart && toDate === currentDateEnd){
//   //     const body = {
//   //       from_date: fromDate.format("YYYY-MM-DD HH:mm:ss"),
//   //       to_date: toDate.format("YYYY-MM-DD HH:mm:ss"),
//   //     };

//   //     getVehicalTracking(body, (response) => {
//   //       if (response.status === 200 && response.data) {
//   //         setVehicalData(response.data);
//   //         setFilteredData(response.data);
//   //       }
//   //     });
//   //   }
//   // }, []);



//   const fetchVehicleTracking = (fromDate, toDate) => {
//     const body = {
//       from_date: fromDate.format("YYYY-MM-DD HH:mm:ss"),
//       to_date: toDate.format("YYYY-MM-DD HH:mm:ss"),
//     };

//     getVehicalTracking(body, (response) => {
//       if (response.status === 200 && response.data) {
//         setVehicalData(response.data);
//         setFilteredData(response.data);
//       }
//     });
//   };

//   useEffect(() => {
//     const checkAndFetchData = () => {
//       if (fromDate && toDate) {
//         // If dates match the current day's start and end
//         if (
//           fromDate.format("YYYY-MM-DD HH:mm:ss") === currentDatestart.format("YYYY-MM-DD HH:mm:ss") &&
//           toDate.format("YYYY-MM-DD HH:mm:ss") === currentDateEnd.format("YYYY-MM-DD HH:mm:ss")
//         ) {
//           // Call the API for the current day
//           fetchVehicleTracking(fromDate, toDate);
//         } 
//         // else {
//         //   // If dates are not for the current day, call the API once for the given range
//         //   fetchVehicleTracking(
//         //     fromDate.format("YYYY-MM-DD HH:mm:ss"),
//         //     toDate.format("YYYY-MM-DD HH:mm:ss")
//         //   );
//         // }
//       }
//     };
  
//     // Set up interval to check every 3 seconds
//     const interval = setInterval(checkAndFetchData, 3000);
  
//     return () => clearInterval(interval); // Cleanup interval on component unmount
//   }, [fromDate, toDate]);
  


//   const handleSearch = (e) => {
//     const query = e.target.value.toLowerCase();
//     setSearchText(query);
//     const filtered = vehicleData.filter((item) =>
//       item.devicename?.toLowerCase().includes(query)
//     );
//     setFilteredData(filtered);
//   };

//   const handleGetReports = () => {

//     fetchVehicleTracking(
//       fromDate,
//       toDate
//     );
    
//   }

//   const handleExportExcel = () => {
//     if (!vehicleData || vehicleData.length === 0) {
//       alert("No data to Export.");
//       return;
//     }

//     const headers = [
//       "S.No",
//       "Camera Name",
//       "Entry Snapshot URL",
//       "Vehicle Entry Time",
//       "Exit Snapshot URL",
//       "Vehicle Exit Time",
//       "Status",
//       "Vehicle Duration",
//     ];

//     const formattedData = vehicleData.map((item, index) => ({
//       "S.No": index + 1,
//       "Camera Name": item.devicename || "",
//       "Entry Snapshot URL": item.SnapshotUrl || "",
//       "Vehicle Entry Time": item.ObjectEntryOn || "",
//       "Exit Snapshot URL": item.ExitSnapshotUrl || "",
//       "Vehicle Exit Time": item.ObjectExitOn || "",
//       "Status": item.ObjectLive === 1 ? "Arrived" : "Departed",
//       "Vehicle Duration": item.duration || "",
//     }));

//     const wb = XLSX.utils.book_new();
//     const ws = XLSX.utils.json_to_sheet(formattedData, { header: headers });
//     XLSX.utils.book_append_sheet(wb, ws, "Vehicle Data");
//     XLSX.writeFile(wb, `Vehicle_Tracking_${new Date().toISOString()}.xlsx`);
//   };

//   return (
//     <Page title="Vehicle Tracking Info">
//       <Container maxWidth="xl">
//         <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
//           <Typography variant="h4">Vehicle Tracking Info</Typography>
//         </Stack>

//         <Card>
//           <CardContent>
//           <Grid container spacing={2} alignItems="center" mb={1}>
//             {/* First row: From Date, To Date, Export to Excel */}

            
//             <Grid container spacing={2} sx={{ p: 2 ,}} alignItems="center"  mb={1}>
//               <Grid item sx={{ p: 2 }} >
//                 <LocalizationProvider dateAdapter={AdapterDayjs}>
//                   <DateTimePicker
//                     label="From Date"
//                     value={fromDate}
//                     onChange={(newValue) => setFromDate(newValue)}
//                     format="YYYY-MM-DD HH:mm"
//                     maxDate={dayjs()}
//                     renderInput={(params) => (
//                       <TextField {...params} fullWidth sx={{ marginRight: "-5px" }} />
//                     )}
//                   />
//                 </LocalizationProvider>
//               </Grid>

//               <Grid item sx={{ p: 2 }}>
//                 <LocalizationProvider dateAdapter={AdapterDayjs}>
//                   <DateTimePicker
//                     label="To Date"
//                     value={toDate}
//                     onChange={(newValue) => setToDate(newValue)}
//                     format="YYYY-MM-DD HH:mm"
//                     minDate={fromDate}
//                     maxDate={dayjs()}
//                     renderInput={(params) => (
//                       <TextField {...params} fullWidth sx={{ marginRight: "5px" }} />
//                     )}
//                   />
//                 </LocalizationProvider>
//               </Grid>

//               <Grid item sx={{ p: 2 }}>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   size="medium"
//                   startIcon={<InsertDriveFileIcon />}
//                   onClick={handleGetReports}
//                   fullWidth
//                   sx={{ marginRight: "5px", padding: "6px 12px" }}
//                 >
//                   Get Report
//                 </Button>
//               </Grid>

//               <Grid item sx={{ p: 2 }}>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   size="medium"
//                   startIcon={<InsertDriveFileIcon />}
//                   onClick={handleExportExcel}
//                   fullWidth
//                   sx={{ padding: "6px 12px" }}
//                 >
//                   Export to Excel
//                 </Button>
//               </Grid>
//             </Grid>


//             {/* Second row: Search */}
//             <Grid container spacing={2} sx={{ p: 2 ,mt: -7 }} alignItems="center" mb={1}>
//               <Grid item xs={3}>
//                 <TextField
//                   label="Search by Camera Name"
//                   variant="outlined"
//                   value={searchText}
//                   onChange={handleSearch}
//                   fullWidth
//                 />
//               </Grid>
//             </Grid>


//           </Grid>


//             <Scrollbar sx={{ mt: -2 }} >
//               <TableContainer sx={{ maxHeight: 400 }}>
//                 <Table stickyHeader>
//                   <TableHead>
//                     <TableRow>
//                       <TableCell>S.No</TableCell>
//                       <TableCell>Camera Name</TableCell>
//                       <TableCell>Entry Snapshot</TableCell>
//                       <TableCell>Vehicle Entry Time</TableCell>
//                       <TableCell>Exit Snapshot</TableCell>
//                       <TableCell>Vehicle Exit Time</TableCell>
//                       <TableCell>Status</TableCell>
//                       <TableCell>Vehicle Duration</TableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     {filteredData.map((item, index) => (
//                       <TableRow key={index}>
//                         <TableCell>{index + 1}</TableCell>
//                         <TableCell>{item.devicename}</TableCell>
//                         <TableCell>{item.SnapshotUrl || "N/A"}</TableCell>
//                         <TableCell>{item.ObjectEntryOn || "N/A"}</TableCell>
//                         <TableCell>{item.ExitSnapshotUrl || "N/A"}</TableCell>
//                         <TableCell>{item.ObjectExitOn || "N/A"}</TableCell>
//                         <TableCell>{item.ObjectLive === 1 ? "Arrived" : "Departed"}</TableCell>
//                         <TableCell>{item.duration || "N/A"}</TableCell>
//                       </TableRow>
//                     ))}
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//             </Scrollbar>

//             <TablePagination
//               rowsPerPageOptions={[10, 25, 50]}
//               component="div"
//               count={vehicleData.length}
//               rowsPerPage={rowsPerPage}
//               page={page}
//               onPageChange={(event, newPage) => setPage(newPage)}
//               onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
//             />
//           </CardContent>
//         </Card>
//       </Container>
//     </Page>
//   );
// };

// export default VehicalTrackingInfo;
import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Container,
  Stack,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Button,
  Grid,
  TableHead,
  TableRow,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
} from "@mui/material";
import Scrollbar from "../components/Scrollbar";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { getVehicalTracking } from "src/api/vehicles";
import * as XLSX from "xlsx";
import Page from "src/components/Page";
import dayjs from "dayjs";

const VehicalTrackingInfo = () => {
  const [vehicleData, setVehicalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [selectedImage, setSelectedImage] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);


  const [fromDate, setFromDate] = useState(dayjs().startOf("day"));
  const [toDate, setToDate] = useState(dayjs().endOf("day"));
  
  const fetchVehicleTracking = (fromDate, toDate) => {
    const body = {
      from_date: fromDate.format("YYYY-MM-DD HH:mm:ss"),
      to_date: toDate.format("YYYY-MM-DD HH:mm:ss"),
    };

    getVehicalTracking(body, (response) => {
      if (response.status === 200 && response.data) {
        setVehicalData(response.data);
        setFilteredData(response.data);
      }
    });
  };

  useEffect(() => {
    fetchVehicleTracking(fromDate, toDate);
  }, [fromDate, toDate]);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchText(query);
    const filtered = vehicleData.filter((item) =>
      item.devicename?.toLowerCase().includes(query)
    );
    setFilteredData(filtered);
  };

  const handleGetReports = () => {
    fetchVehicleTracking(fromDate, toDate);
  };

  const handleExportExcel = () => {
    if (!vehicleData || vehicleData.length === 0) {
      alert("No data to Export.");
      return;
    }

    const headers = [
      "S.No",
      "Camera Name",
      "Entry Snapshot URL",
      "Vehicle Entry Time",
      "Exit Snapshot URL",
      "Vehicle Exit Time",
      "Status",
      "Vehicle Duration",
    ];

    const formattedData = vehicleData.map((item, index) => ({
      "S.No": index + 1,
      "Camera Name": item.devicename || "",
      "Entry Snapshot URL": item.SnapshotUrl || "",
      "Vehicle Entry Time": item.ObjectEntryOn || "",
      "Exit Snapshot URL": item.ExitSnapshotUrl || "",
      "Vehicle Exit Time": item.ObjectExitOn || "",
      "Status": item.ObjectLive === 1 ? "Arrived" : "Departed",
      "Vehicle Duration": item.duration || "",
    }));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(formattedData, { header: headers });
    XLSX.utils.book_append_sheet(wb, ws, "Vehicle Data");
    XLSX.writeFile(wb, `Vehicle_Tracking_${new Date().toISOString()}.xlsx`);
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedImage(null);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };

  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );


  return (
    <Page title="Vehicle Tracking Info">
      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
          <Typography variant="h4">Vehicle Tracking Info</Typography>
        </Stack>

        <Card>
          <CardContent>
            <Grid container spacing={2} alignItems="center" mb={1}>
              <Grid container spacing={2} sx={{ p: 2 }} alignItems="center" mb={1}>
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="From Date"
                      value={fromDate}
                      onChange={(newValue) => setFromDate(newValue)}
                      maxDate={dayjs()}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="To Date"
                      value={toDate}
                      onChange={(newValue) => setToDate(newValue)}
                      minDate={fromDate}
                      maxDate={dayjs()}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleGetReports}
                    startIcon={<InsertDriveFileIcon />}
                  >
                    Get Report
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleExportExcel}
                    startIcon={<InsertDriveFileIcon />}
                  >
                    Export to Excel
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ p: 2 }} alignItems="center" mb={1}>
              <Grid item xs={3}>
                <TextField
                  label="Search by Camera Name"
                  variant="outlined"
                  value={searchText}
                  onChange={handleSearch}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Scrollbar>
              <TableContainer>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Camera Name</TableCell>
                      <TableCell>Entry Snapshot</TableCell>
                      <TableCell>Vehicle Entry Time</TableCell>
                      <TableCell>Exit Snapshot</TableCell>
                      <TableCell>Vehicle Exit Time</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Vehicle Duration</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedData.map((item, index) => (
                      <TableRow key={index}>
                      <TableCell>{page * rowsPerPage + index + 1}</TableCell>                    
                      <TableCell>{item.devicename}</TableCell>
                        <TableCell>
                          {item.SnapshotUrl ? (
                            <img
                              src={item.SnapshotUrl}
                              alt="Entry Snapshot"
                              style={{ width: 50, cursor: "pointer" }}
                              onClick={() => handleImageClick(item.SnapshotUrl)}
                            />
                          ) : (
                            "N/A"
                          )}
                        </TableCell>
                        <TableCell>{item.ObjectEntryOn || "N/A"}</TableCell>
                        <TableCell>
                          {item.ExitSnapshotUrl ? (
                            <img
                              src={item.ExitSnapshotUrl}
                              alt="Exit Snapshot"
                              style={{ width: 50, cursor: "pointer" }}
                              onClick={() => handleImageClick(item.ExitSnapshotUrl)}
                            />
                          ) : (
                            "N/A"
                          )}
                        </TableCell>
                        <TableCell>{item.ObjectExitOn || "N/A"}</TableCell>
                        <TableCell>{item.ObjectLive === 1 ? "Arrived" : "Departed"}</TableCell>
                        <TableCell>{item.duration || "N/A"}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </CardContent>
        </Card>

        {/* Dialog for Image Preview */}
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Image Preview</DialogTitle>
          <DialogContent>
            {selectedImage && (
              <Box component="img" src={selectedImage} alt="Snapshot" sx={{ width: "100%" }} />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="error"  variant="contained">
            Close
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
};

export default VehicalTrackingInfo;

