import { 
    Tabs, 
    Tab,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    styled,
    TableBody,
    Paper,
    Badge,
    TableCell
} from "@mui/material";
import { useEffect, useState } from "react";
import Label from "src/components/Label";
import { serverDetails} from 'src/api/servers';



export default function CameraDetails({serverInfo}) {

    const [cameraData, setCameraData] = useState({
        all: [],
        activeCameras: [],
        inActiveCameras: []
    });

    const [selectedTab, setSelectedTab] = useState(0);

    const CustomTableCell = styled(TableCell)({
        padding: 10,
      });
    
    const CameraTableCell = styled(TableCell)({
        padding: 10,
        borderRight: '1px solid rgb(211 211 211)',
        borderBottom: '1px solid rgb(211 211 211)'
    });

    useEffect(() => {
        serverDetails(serverInfo.Id, (response) => {
            if (response.status === 200) {
                const activeCamera = []
                const inActiveCameras = []
                const all = response.data.map((row) => {
                    if (row.Status === 1)
                        activeCamera.push(row)
                    else
                        inActiveCameras.push(row)
                    return row
                })
                setCameraData({
                    "all": all,
                    "activeCameras": activeCamera,
                    "inActiveCameras": inActiveCameras
                });
                console.log('response based on server ID : ', response.data);
            }
        });
    }, [serverInfo])


    return (<>
        <Tabs
            value={selectedTab}
            onChange={(event, newValue) => setSelectedTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
            aria-label="camera status tabs"
            
        >
            <Tab 
            label="All"
            style={{minHeight: "50px"}}
            icon={<Badge style={{minHeight: "auto", marginLeft: "15px"}} badgeContent={String(cameraData.all.length)} color="primary"/>} 
            iconPosition="end" 
            />
            <Tab 
            label="Active Cameras"
            style={{minHeight: "50px"}}
            icon={<Badge style={{minHeight: "auto", marginLeft: "15px"}} badgeContent={String(cameraData.activeCameras.length)} color="success"/>} 
            iconPosition="end" 
            />
            <Tab 
            label="Inactive Cameras"
            style={{minHeight: "50px"}}
            icon={<Badge style={{minHeight: "auto", marginLeft: "15px"}} badgeContent={String(cameraData.inActiveCameras.length)} color="error"/>} 
            iconPosition="end" 
            />
        </Tabs>

        <TableContainer sx={{ maxHeight: 400 }}>
            <Table sx={{border: "1px solid #e5e5e5"}} stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <CustomTableCell>S.No</CustomTableCell>
                        <CustomTableCell>Camera Name</CustomTableCell>
                        <CustomTableCell>Device ID</CustomTableCell>
                        <CustomTableCell>Client Name</CustomTableCell>
                        <CustomTableCell>Category</CustomTableCell>
                        <CustomTableCell>Status</CustomTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {selectedTab === 0 ? (
                        cameraData.all.length > 0 ? (
                            cameraData.all.map((camera, index) => (
                                <TableRow  key={index}>
                                    <CameraTableCell>{index + 1}</CameraTableCell>
                                    <CameraTableCell>{camera.devicename}</CameraTableCell>
                                    <CameraTableCell>{camera.deviceid}</CameraTableCell>
                                    <CameraTableCell>{camera.displayname}</CameraTableCell>
                                    <CameraTableCell>{camera.Category}</CameraTableCell>
                                    <CameraTableCell>
                                        <Label variant="ghost" color={camera.Status === 1? "success": "error"}>
                                            {camera.Status === 1? "Active": "Inactive"}
                                        </Label>
                                    </CameraTableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <CameraTableCell colSpan={5} align="center">No cameras available</CameraTableCell>
                            </TableRow>
                        )
                    ): selectedTab === 1 ? (
                        cameraData.activeCameras.length > 0 ? (
                            cameraData.activeCameras.map((camera, index) => (
                                <TableRow key={index}>
                                    <CameraTableCell>{index + 1}</CameraTableCell>
                                    <CameraTableCell>{camera.devicename}</CameraTableCell>
                                    <CameraTableCell>{camera.deviceid}</CameraTableCell>
                                    <CameraTableCell>{camera.displayname}</CameraTableCell>
                                    <CameraTableCell>{camera.Category}</CameraTableCell>
                                    <CameraTableCell>
                                        <Label variant="ghost" color="success">
                                            Active
                                        </Label>
                                    </CameraTableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <CameraTableCell colSpan={5} align="center">No active cameras available</CameraTableCell>
                            </TableRow>
                        )
                    ) : (
                        cameraData.inActiveCameras.length > 0 ? (
                            cameraData.inActiveCameras.map((camera, index) => (
                                <TableRow key={index}>
                                    <CameraTableCell>{index + 1}</CameraTableCell>
                                    <CameraTableCell>{camera.devicename}</CameraTableCell>
                                    <CameraTableCell>{camera.deviceid}</CameraTableCell>
                                    <CameraTableCell>{camera.displayname}</CameraTableCell>
                                    <CameraTableCell>{camera.Category}</CameraTableCell>
                                    <CameraTableCell>
                                        <Label variant="ghost" color="error">
                                            Inactive
                                        </Label>
                                    </CameraTableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <CameraTableCell colSpan={5} align="center">No inactive cameras available</CameraTableCell>
                            </TableRow>
                        )
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    </>)
}