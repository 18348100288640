import React, { useState, useEffect } from "react";
import {
  Checkbox,
  Button,
  Card,
  Container,
  FormControl,
  Autocomplete,
  TextField,
  Grid,
  Box,
  CardContent,
 
  MenuItem,
  Select, InputLabel,
  Stack,
  Typography,
  createFilterOptions,
  ListItemText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Page from "../components/Page";
import {
  getCamerasList_Live,
  getGroupsByClient,
  getCamerasByGroupID,
  getCamerasList,
  getClientGroupInfo
} from "src/api/liveCameras";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { getClientList } from "src/api/analytics";
import { VpnLockSharp } from "@mui/icons-material";


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const groupicon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const groupcheckedIcon = <CheckBoxIcon fontSize="small" />;

export default function LiveCameras() {
  const agentBased = localStorage.getItem('IsAgentBased') 
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedCameras, setSelectedCameras] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [liveCamerasList, setLiveCamerasList] = useState([]);
  const [clientGroupList, setClientGroupsList] = useState([]);
  const [camerasByGroupId, setCamerasByGroup] = useState([]);
  const [camerawithoutGroups, setCameraListWithoutGroups] = useState([]);
  const [showOptions, setShowOptions] = useState(true);
  // Inside the component function
  const [gridLayout, setGridLayout] = useState({ xs: 12 });
  const [errorMessage, setErrorMessage] = useState('');

  const [checkValue,setCheckValue]=useState(false)
  const [groupCheckValue,setGroupCheckValue] = useState(false)

  const [openAuto,setOpenAuto]=useState(false)

  const [activeCameras, setActiveCameras] = useState([])
  const [inputValue, setInputValue] = useState([]);

  const [activeGroups,setActiveGroups] = useState([])
  //Live cameras Length and height adjust
  const [outerBoxWidth, setOuterBoxWidth] = useState('')
  const [outerBoxHeight, setOuterBoxHeight] = useState('')
  const [cameWidth , setCamewidth] = useState('');
  const [cameHeight , setCameHeight] = useState('');
  const [xsCount , setXSCountVal] = useState(null);

 // const [showClient,setShowClient] = useState(false)
  const [clientList,setClientList] = useState([]);
  const [clientName,selectedClientName] = useState([]);
  const [saveError, setSaveError] = useState(false); 
  const [isClientSelected, setIsClientSelected] = useState(false);
  const [isClient,setIsClient] = useState('')
  const [totalCameras,setTotalCameras] = useState([])

  const [selectedClient,setSelectedClient]=useState('')

  const [errorGroup, setErrorGroup] = useState(null);
  const [client, setClient] = useState('');

  const [clientType, setClientType] = useState('')
  const [clientTypeerror, setClientTypeError] = useState('')
  const [setTypeClient,setSelectedTypeClient] = useState([]);

  const [groupinfo,setGroupinfo] = useState([]);

  const handleClient = (_, value) => {
   // //debugger
    setClientGroupsList([])
    setCameraListWithoutGroups([])
    setCamerasByGroup([])
    setLiveCamerasList([])
    let isGrouped = false;
    if(value){
      if(checkValue){
        setCheckValue(!checkValue)
      }
      let clientId = value.ID;
      setClient(clientId)
      //let body = {"client_id":clientId}
      let body = {clientId : clientId , groupId : 0}

      getClientGroupInfo(body,(response) => {
        if (response.status === 200) {
          if(response.data.length>0){
            setGroupinfo(response.data)
            const uniqueGroups = Array.from(
              new Map(response.data.map(item => [item.GroupId, { GroupId: item.GroupId, GroupName: item.GroupName }])).values()
            );   
            setClientGroupsList(uniqueGroups);
            isGrouped = true;
          }
          else{
            isGrouped = false;
          }
        }
      });

      if(!isGrouped){
        let ClientCameras = totalCameras.filter(item=>item.clientid===clientId);
        if(ClientCameras.length>0){
          setCameraListWithoutGroups(ClientCameras)
        }
      }
      else{

      }
    }
    else{
      setClient('')
    }
   };

  // Inside the handleSubmit function

  useEffect(() => {
   debugger
    let userType = localStorage.getItem('user_type')
    if (userType === "2") {
      setIsClient(true);
    } else {
      setIsClient(false);
    }
   
    getCamerasList((response) => {
      if (response.status === 200) {
        // console.log("response data cameras", response.data);
        if(response.data !== null){
          if(userType==='2'){
            setCameraListWithoutGroups(response.data);
          }
          else{
            setTotalCameras(response.data)
          }
        }
      }
    });

    if(userType === "2"){
      //setIsClient(true)
      let clientId = localStorage.getItem('client_id')
      let groupId = 0
      let body = {clientId : clientId , groupId : groupId}
      getClientGroupInfo(body,(response) => {
        if (response.status === 200) {
          // console.log("response Data group ", response.data);
          setGroupinfo(response.data)
          const uniqueGroups = Array.from(
            new Map(response.data.map(item => [item.GroupId, { GroupId: item.GroupId, GroupName: item.GroupName }])).values()
        );       
          setClientGroupsList(uniqueGroups);
        }
      });
    }
    else{
      //setIsClient(false)

      getClientList((response) => {   
        if (response.status === 200) {
          setClientList(response.data)
        }
      })
    }

  }, []);



  useEffect(() =>{
    setGroupinfo([])
    setClientGroupsList([])
    setCheckValue(false)
    setActiveCameras([])
    setCamerasByGroup([])
    setCameraListWithoutGroups([])
    setSelectedClient(null)
    setLiveCamerasList([])

    if(clientType === "INDIA"){
      console.log("clientType",clientType)
     const indiaClients = clientList.filter((row) => row.ClientType === 'INDIA');
     setSelectedTypeClient(indiaClients)
    //  const indiaClientsSub = subLogin.filter((row) => row.ClientType === 'INDIA');

    //  setSubLoginSelectList(indiaClientsSub)

     
    }
    else {
     const usClients = clientList.filter((row) => row.ClientType === 'US');
     setSelectedTypeClient(usClients)
    //  const usClientsSub = subLogin.filter((row) => row.ClientType === 'US');

    //  setSubLoginSelectList(usClientsSub)

     
    }

  },[clientType])

  useEffect(() => {
  // debugger
  //  if(clientGroupList.length>0){
  //     getCamerasByGroupID(selectedGroup, (response) => {
  //       if (response.status === 200) {
  //         // console.log("cameras List by Group ", selectedGroup, response.data);
  //         setCamerasByGroup(response.data);
  //       }
  //     });
  //   }
  }, [selectedGroup]);

  useEffect(() => {
    ////debugger
   // console.log("liveCamerasList.......................", liveCamerasList.length);
  
    // switch (liveCamerasList.length) {
    //   case 1:
    //     setOuterBoxWidth('1480px');
    //     setOuterBoxHeight('550px');
    //     setCamewidth('1470px');
    //     setCameHeight('500px');
    //     break;
    //   case 2:
    //     setOuterBoxWidth('740px');
    //     setOuterBoxHeight('550px');
    //     setCamewidth('730px');
    //     setCameHeight('495px');
    //     setXSCountVal(6);
    //     break;
    //   case 3 : 
    //     setOuterBoxWidth('730px');
    //     setOuterBoxHeight('270px');
    //     setCamewidth('700px');
    //     setCameHeight('235px');
    //     setXSCountVal(6);
    //     break;
    //   case 4:
    //     setOuterBoxWidth('730px');
    //     setOuterBoxHeight('270px');
    //     setCamewidth('700px');
    //     setCameHeight('235px');
    //     setXSCountVal(6);
    //     break;
    //   case 5 :
    //     setOuterBoxWidth('480px');
    //     setOuterBoxHeight('200px');
    //     setCamewidth('475px');
    //     setCameHeight('165px');
    //     setXSCountVal(4);
    //     break;
    //   case 6 :
    //     setOuterBoxWidth('480px');
    //     setOuterBoxHeight('200px');
    //     setCamewidth('475px');
    //     setCameHeight('165px');
    //     setXSCountVal(4);
    //     break;
    //   case 7 :
    //     setOuterBoxWidth('360px');
    //     setOuterBoxHeight('200px');
    //     setCamewidth('355px');
    //     setCameHeight('160px');
    //     setXSCountVal(3);
    //     break;  
    //   case 8 :        
    //   // setOuterBoxWidth('100%');
    //   // setOuterBoxHeight('100%');
    //   // setCamewidth('100%');
    //   // setCameHeight('90%');
    //     setOuterBoxWidth('360px');
    //     setOuterBoxHeight('200px');
    //     setCamewidth('355px');
    //     setCameHeight('160px');
    //     setXSCountVal(3);
    //     break;  
    //   default:
    //     setOuterBoxWidth('360px');
    //     setOuterBoxHeight('200px');
    //     setCamewidth('355px');
    //     setCameHeight('160px');
    //     setXSCountVal(3);
    //     break;
    // }

    switch (liveCamerasList.length) {
         
        case 1:
          ////debugger
          setOuterBoxWidth('100%');
          setOuterBoxHeight('80vh');
          setCamewidth('100%');
          setCameHeight('75vh');
          setXSCountVal(12);
          break;
        case 2:
          setOuterBoxWidth('100%');
          setOuterBoxHeight('60vh');
          setCamewidth('100%');
          setCameHeight('50vh');
          setXSCountVal(6);
          break;
        case 3:
          setOuterBoxWidth('100%');
          setOuterBoxHeight('40vh');
          setCamewidth('100%');
          setCameHeight('35vh');
          setXSCountVal(6);
          break;
        case 4:
          setOuterBoxWidth('100%');
          setOuterBoxHeight('40vh');
          setCamewidth('100%');
          setCameHeight('35vh');
          setXSCountVal(6);
          break;
        case 5:
          setOuterBoxWidth('100%');
          setOuterBoxHeight('40vh');
          setCamewidth('100%');
          setCameHeight('35vh');
          setXSCountVal(4);
          break;        
        case 6:
            setOuterBoxWidth('100%');
            setOuterBoxHeight('40vh');
            setCamewidth('100%');
            setCameHeight('35vh');
            setXSCountVal(4);
          break;   
        default:
          setOuterBoxWidth('360px');
          setOuterBoxHeight('200px');
          setCamewidth('355px');
          setCameHeight('160px');
          setXSCountVal(3);
          break;
  } 
  }, [liveCamerasList]);
  

  const handleGroupChange = (event,val) => {
    

    if(event.target.value && errorGroup){
      setErrorGroup(null)
    }

    setCheckValue(false)
    const containsAllProperty = val.some(obj => obj.hasOwnProperty('all'));

    if(clientGroupList.length === activeGroups.length && containsAllProperty){
        setGroupCheckValue(false)
        setActiveGroups([])
        setCheckValue(false)
        setActiveCameras([])
        setCamerasByGroup([])
    }

    else if(containsAllProperty){
      setGroupCheckValue(true)
      let groupId = clientGroupList.map(val => val.GroupId)
      setActiveGroups(groupId)
    }

    else{
      const grpId =  val[0].GroupId
      const avail =activeGroups.length > 0 ? activeGroups.filter(item => item === grpId) : []
  
      if(avail.length>0){ // remove the group id
        const id = activeGroups.filter(item => item !== grpId)
        setActiveGroups(id)
        setGroupCheckValue(false)

        if(id.length === 0){
          setCheckValue(false)
          setActiveCameras([])
          setCamerasByGroup([])
        }
      }
      else{ //  add group id
        //setActiveCameras()
        setActiveGroups((id) => [...id, grpId]);

        //[...activeGroups, grpId].length === clientGroupList ? setGroupCheckValue(true) : setGroupCheckValue(false)
      }
    }
  
    // setLiveCamerasList([]);
    // setSelectedCameras([]);
    // setCamerasByGroup([]);  
    // setSelectedGroup(event.target.value);
    // setInputValue([])
    // setActiveCameras([])
  
  };

  useEffect (()=>{
    debugger
    if(activeGroups.length === clientGroupList.length && activeGroups.length>0){
       setGroupCheckValue(true)
    }

    if(activeGroups.length > 0){
      const cameras = groupinfo.filter(val => activeGroups.includes(val.GroupId))
      setCamerasByGroup(cameras)
      const newId = cameras.map(val => val.deviceid)
      const filArr = activeCameras.filter(val => newId.includes(val))
      console.log(filArr)
      setActiveCameras(filArr)

      if(cameras.length === filArr.length && filArr.length>0){
        setCheckValue(true)
      }
    }

  },[activeGroups])

  const handleCameraChange = (event, newValue) => {
    setSelectedCameras(newValue);
  };
  const handleGridLayout = () => {
    ////debugger
    if (activeCameras.length === 1 || activeCameras.length === 2 || activeCameras.length === 3) {
      setGridLayout({ xs: 3 });
    }  
    else {
      const numRows = Math.ceil(activeCameras.length / 4);
      const numCols = Math.min(4, activeCameras.length);
      setGridLayout({
        xs: 12 / numCols,
        style: { gridColumnEnd: `span ${numRows}` },
      });
    }
  };

  const handleSubmit = () => {
    ////debugger
    setSaveError(false)
    setErrorGroup(null)
    if(!isClient && selectedClient===''){
     // console.log('select client')
      setSaveError(true)
      return
    }

    if(clientGroupList.length>0 && activeGroups.length === 0){
      setErrorGroup({ message: 'Select Group' })
      return
    }
  
    setLiveCamerasList([]);
    // const deviceIds = selectedCameras.map((camera) => {
    //   if (clientGroupList.length !== 0) {
    //     return camera.DeviceId; // Change DeviceId to deviceid
    //   } else {
    //     return camera.deviceid;
    //   }
    // });

    if (activeCameras.length === 0) {
      setErrorMessage('Please Select Atleast one Camera');
      return;
    } else {
      setErrorMessage('');

      getCamerasList_Live(activeCameras, (response) => {
        if (response.status === 200) {
          // console.log("resonce Live ", response.data);
          setLiveCamerasList(response.data);
          setSubmitted(true); // Set submitted to true after fetching live cameras list
          // handleGridLayout(); // Update grid layout after data fetching
          setOpenAuto(false)
        }
      });
      

    }
  };

  const handleClear = () => {
    setLiveCamerasList([]);
    setSelectedCameras([]);
    setSelectedGroup("");
    setCamerasByGroup([]);
    setSubmitted(false); // Reset the submitted state when clearing
    setActiveCameras([])
    setOpenAuto(false)
    setInputValue([])
    setCheckValue(false)
    setClient('')
  };

  const getGridLayout = () => {
    if (!submitted || selectedCameras.length === 0) {
      // If not submitted or no cameras selected, return default layout
      return {
        xs: 12, // Occupy the entire width of the screen
      };
    } else {
      // Logic to calculate grid layout based on selected cameras
      if (selectedCameras.length === 1) {
        return {
          xs: 12, // Occupy the entire width of the screen
        };
      } else if (selectedCameras.length === 2 || selectedCameras.length === 3) {
        return {
          xs: 6, // Display two or three cameras side by side in one row
        };
      } else {
        // Render more than three cameras with each row containing two cameras
        const numRows = Math.ceil(selectedCameras.length / 4); // Calculate the number of rows needed
        const numCols = Math.min(4, selectedCameras.length); // Maximum of 4 columns
        const rowSpan = selectedCameras.length === 1 ? numRows : 1; // Row span for grid item

        return {
          xs: 12 / numCols, // Each camera takes a portion of the row width
          style: { gridColumnEnd: `span ${rowSpan}` }, // Set the grid item to span multiple rows
        };
      }
    }
  };

  const handleLiveCamera = (event,newValue)=>{
        //debugger
        setSelectedCameras(newValue);
        if (!newValue || newValue.length === 0) {
            setLiveCamerasList([]); 
          }
        
          let lengthofCameras=clientGroupList.length?camerasByGroupId.length:camerawithoutGroups.length
          let cameraValues=clientGroupList.length?camerasByGroupId:camerawithoutGroups
          
      
                    if (newValue.some(obj => obj.hasOwnProperty('all'))) {
                      if((newValue[0].all && newValue.length!==2) || (newValue[newValue.length-1].all)){
                       // setOpenAuto(false);
                      }
                      else{
                        //setOpenAuto(true);
                      }
                    } else {
                     // let len=clientGroupList.length?camerasByGroupId.length:camerawithoutGroups.length
                      if(newValue.length===lengthofCameras){
                        //setOpenAuto(false);
                      }
                      else{
                        //setOpenAuto(true);
                      }
                    }
                  
                    if (newValue.length > 1 && newValue.every((entry, index, arr) => JSON.stringify(entry) === JSON.stringify(arr[0]))) {
                      newValue = [];
                    }
                    
                    const containsAllProperty = newValue.some(obj => obj.hasOwnProperty('all'));
      
                    if(containsAllProperty){
                      if(newValue.length==1){
                        //camerasByGroupId
                        //let val=clientGroupList.length?camerasByGroupId:camerawithoutGroups
                        setSelectedCameras(cameraValues);              
                      }
                      else{
                        let val = newValue.filter(item => !item.all)
                        setSelectedCameras(val)
                      }               
                    }
                  
                  
                    if (newValue.length === 0 || !containsAllProperty) {
                      setCheckValue(false);
                    }
                  
                    let actCameras = [];
                  
                    if (newValue.length > 0 && !containsAllProperty) {
                      newValue.forEach((row) => {
                        clientGroupList.length?actCameras.push(row.deviceid):actCameras.push(row.deviceid)
                      });
      
                      //let lenVal=clientGroupList.length?camerasByGroupId.length:camerawithoutGroups.length
                      if (lengthofCameras === actCameras.length) {
                        setCheckValue(true);
                        //setOpenAuto(false);
                      }
                  
                      setActiveCameras([...actCameras]);
                      setInputValue([...newValue]);
                    }
                  
                    if (newValue.length > 0 && containsAllProperty) {
                      
                      let Cameras =cameraValues;
                  
                      actCameras = [];
                      
                      //let samplVal=clientGroupList.length?camerasByGroupId.length:camerawithoutGroups.length
                      if (activeCameras && activeCameras.length === lengthofCameras) {
                        setCheckValue(false);
                        let deviceIds =[] 
                        
                        if(clientGroupList.length){
                        deviceIds=newValue.filter(obj => !obj.hasOwnProperty('all')).map(obj => obj.deviceid);
                        Cameras = Cameras.filter(item => !deviceIds.includes(item.deviceid));
                        Cameras.forEach((row) => {
                          actCameras.push(row.deviceid);
                        });
                        setInputValue(camerasByGroupId);
                        }
                        else{
                          deviceIds=newValue.filter(obj => !obj.hasOwnProperty('all')).map(obj => obj.deviceid);
                          Cameras = Cameras.filter(item => !deviceIds.includes(item.deviceid));
                          Cameras.forEach((row) => {
                            actCameras.push(row.deviceid);
                          });
                          setInputValue(camerawithoutGroups);
                        }
      
                       
                        setActiveCameras([...actCameras]);
                       
                        //setOpenAuto(false);
                        return;
                      }
                     
                      if (!checkValue) {
                       
                        if(clientGroupList.length){
                          Cameras.forEach((row) => {
                            actCameras.push(row.deviceid);
                          });
                          setActiveCameras([...actCameras]);
                          setInputValue(camerasByGroupId);
                          setCheckValue(true);
                        }
                       else{
                        Cameras.forEach((row) => {
                          actCameras.push(row.deviceid);
                        });
                        setActiveCameras([...actCameras]);
                        setInputValue(camerawithoutGroups);
                        setCheckValue(true);
                       }
                      } else {
                        setActiveCameras([]);
                        setInputValue([]);
                      }
                    } else if (newValue.length === 0) {
                      setActiveCameras([]);
                      setCheckValue(false);
                      setInputValue([]);
                      //setOpenAuto(true)
                    }         
  }

  const [showGrid,setShowGrid]=useState(false)
  const showFilter = ()=>{
    setShowGrid(!showGrid)
  }

  const handleFilterOptions = (options, params) => {
    //debugger
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    
    // Separate selected and unselected options
    const selectedOptions = filtered.filter(option => activeCameras.includes(option.deviceid));
    const unselectedOptions = filtered.filter(option => !activeCameras.includes(option.deviceid));
    
    // Sort options: selected options first, then unselected
    const sortedOptions = [...selectedOptions, ...unselectedOptions];
  
    //return [{ title: 'Select All', all: true }, ...sortedOptions];
    return [{ title: 'Select All', all: true }, ...filtered];
  };

  const handleGroupFilterOptions = (options, params) => {
    //debugger
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    //return [{ title: 'Select All', all: true }, ...sortedOptions];
    return [{ title: 'Select All', all: true }, ...filtered];
  };


  const generateIframeSrc = (camera) => {

    if (camera.LiveUrl !== null && camera.LiveUrl && camera.LiveUrl !== 'null'){
      console.log("live url block",camera.LiveUrl)
      return camera.LiveUrl
    }
    else{
      console.log("RTSP url block",camera.LiveUrl)
      return `https://restreamservers.com:8888/embed_player?urlServer=wss://restreamservers.com:8443&streamName=${encodeURIComponent(camera.substream)}&mediaProviders=WebRTC,Flash,MSE,WSPlayer&autoplay=1`;
    }
  };



  const handleClientType = (event) =>{
    // console.log("client type",event.target.value)
    setClientType(event.target.value)
    setClientTypeError('')
  }

  return (
    <Page title="Live">
      <Container maxWidth="xl">
        
        {!showGrid && <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Live
          </Typography>
        </Stack>}

        {!showGrid && <Grid
          container
          spacing={1}
          style={{ display: showOptions ? "flex" : "none" }}
        >
         
        <Grid container spacing={2} >

          {!isClient && (<Grid item xs={3}>    
                    <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-labell">Client Type</InputLabel>
                    <Select
                      
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={clientType}
                      label="Client Type"
                      onChange={handleClientType}
                      // helperText={touched.ClientType && errors.ClientType}
                      // error={clientTypeerror  ? true : false }
                    >
                      <MenuItem value="US">US</MenuItem>
                      <MenuItem value="INDIA">INDIA</MenuItem>
                    
                  </Select>
                  {clientTypeerror  && <Typography variant="body2" color="error">{clientTypeerror}</Typography>}


                    </FormControl>
                    
                  
              </Grid>
          )}

          {!isClient && (<Grid item xs={3}>                           
                  <FormControl fullWidth>                                             

                      {/* <Autocomplete
                            value={selectedClient}
                            onChange={(event,val)=>{
                              setSelectedClient(val)
                              handleClient(event,val)
                            }}
                            options={clientList}
                            getOptionLabel={(option) => option.displayname || ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select Client"
                                variant="outlined"
                                error={saveError && !isClientSelected}
                              />
                            )}
                            filterOptions={(options, state) => {
                              return options.filter((option) => 
                                option.displayname.toLowerCase().includes(state.inputValue.toLowerCase())
                              );
                            }}
                      /> */}

                      <Autocomplete
                                  fullWidth
                                  id="tags-outlined"
                                  options={setTypeClient.map((client, clientIndex) => ({
                                    ...client,
                                    customKey: clientIndex, 
                                  }))}
                                  isOptionEqualToValue={(option, value) =>
                                    option.customKey === value.customKey
                                  }
                                  getOptionLabel={(option) => option.displayname}
                                // onChange={handleClient}
                                onChange={(event,val)=>{
                                  setSelectedClient(val)
                                  handleClient(event,val)
                                }}
                                // value={client.ID}
                                value={selectedClient}
                                  filterSelectedOptions
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Clients"
                                      placeholder="Enter Client Name"                                   
                                    />
                                  )}
                                  renderOption={(props, option) => (
                                    <li {...props} key={option.customKey}>
                                      {option.displayname}
                                    </li>
                                  )}
                                />

                    {(saveError) && (<Typography variant="body2" color="error">Please select a client.</Typography>)}                                                     
                  </FormControl>                                          
          </Grid>)}

          {(clientGroupList.length > 0) && (<Grid item xs={3}>
                  <Autocomplete
                      multiple
                      id="tags-outlined-groups"
                      options={clientGroupList}
                      onChange={handleGroupChange}
                      value={[]}
                      disableCloseOnSelect
                      //getOptionLabel={(option) => (option.selectall && selectedCamesList.length === activeCameras.length) ? 'Select All' : `${option.deviceid} ${option.devicename}`}
                      renderInput={(params) => (
                      <TextField
                          {...params}
                          label="Select Groups"
                          placeholder="Search Group Name..."
                        />
                      )}
                      filterOptions={handleGroupFilterOptions}                                      
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                        {option.all ? (
                            <>
                            <Checkbox
                              icon={groupicon}
                              checkedIcon={groupcheckedIcon}
                              style={{ marginRight: 8 }}
                              checked={groupCheckValue}
                              //onChange={() => setCheckValue(!checkValue)}
                            />
                          {option.title}
                        </>
                        ) : (
                        <>
                        <Checkbox
                        icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={activeGroups.includes(option.GroupId)}
                          onChange={() => {
                            const isChecked = activeGroups.includes(option.GroupId);
                            const newActiveGroups = isChecked
                              ? activeGroups.filter((groupId) => groupId !== option.GroupId)
                              : [...activeGroups, option.GroupId];
                            setActiveGroups(newActiveGroups);
                        }}
                      />
                      {`${option.GroupName}`}
                    </>
                    )}
                </li>
              )}
                                                  //getOptionKey={(option) => option.deviceid}
                      isOptionEqualToValue={(option, value) => option.GroupId === value.GroupId}
                      /> 
                {errorGroup && <Typography color="error" variant="body2" align="center">{errorGroup.message}</Typography>}
              </Grid>
          )}

          <Grid item xs={(clientGroupList.length > 0 || !isClient) ? 3 : 5}>             
                <Autocomplete
                  multiple
                  id="tags-outlined-cameras"
                  options={
                    clientGroupList.length > 0
                      ? camerasByGroupId
                      : camerawithoutGroups
                  }
                  getOptionLabel={(option) => {
                    const options =
                      clientGroupList.length > 0
                        ? camerasByGroupId
                        : camerawithoutGroups;
                        return options === camerawithoutGroups
                        ? option.all ? 'Select All' : `${option.devicename}`
                        : option.all ? 'Select All' : `${option.devicename}`;
                  }}
                  
                  onChange={handleLiveCamera}

                  disableCloseOnSelect
                  
                  // open={openAuto}
                  // onOpen={() => setOpenAuto(true)}
                  // onClose={() => setOpenAuto(false)}

                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <>
                        {(!clientGroupList.length || activeGroups) && option.all ? (
                        <>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={checkValue}
                            onChange={() => setCheckValue(!checkValue)}
                          />
                          {option.title}
                        </>
                        ) :
                        option.deviceid && activeGroups.length===0 ? ( // no group cameras
                          <>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={activeCameras.includes(option.deviceid)}
                              onChange={() => {
                              // //debugger
                                const isChecked = activeCameras.includes(option.deviceid);
                                const newActiveCameras = isChecked
                                  ? activeCameras.filter((deviceId) => deviceId !== option.deviceid)
                                  : [...activeCameras, option.deviceid];
                                setActiveCameras(newActiveCameras);
                              }}
                            />
                            {`${option.devicename}`}
                          </>
                        ) : activeGroups.length>0 && ( // group cameras
                          <>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={activeCameras.includes(option.deviceid)}
                            onChange={() => {
                            // debugger
                              const isChecked = activeCameras.includes(option.deviceid);
                              const newActiveCameras = isChecked
                                ? activeCameras.filter((deviceId) => deviceId !== option.deviceid)
                                : [...activeCameras, option.deviceid];
                              setActiveCameras(newActiveCameras);
                            }}
                          />
                          {`${option.devicename}`}
                        </>
                        )                  
                      }
                      </>
                    </li>
                  )}
                  
                  onInputChange={(event, newInputValue) => {
                    if (!newInputValue) {
                      setLiveCamerasList([]); // Clear live cameras list when input value is empty
                    }
                  }}
                  
                  //value={selectedCameras}
                  value={ (checkValue) ? [{ title: 'Select All', all: true }] : inputValue.filter((camera) =>clientGroupList.length?activeCameras.includes(camera.deviceid):activeCameras.includes(camera.deviceid) )}
                  

                  filterOptions={handleFilterOptions}

                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Camera"
                      placeholder="Search Camera ID Or Name..."

                    />
                  )}
                  renderTags={() => null} 

                  clearOnBlur={true} // Clear the value when Autocomplete loses focus
                />
                {errorMessage && <Typography color="error" variant="body2" align="center">{errorMessage}</Typography>}

          </Grid>

          <Grid item xs={2}>
                <Stack direction="row" spacing={1} alignItems="center">            
                  
                  <LoadingButton variant="contained" onClick={handleSubmit}>
                    Submit
                  </LoadingButton>

                  <LoadingButton
                    variant="contained"
                    color="error"
                    onClick={handleClear}
                  >
                    Cancel
                  </LoadingButton>
                  
                </Stack>
          </Grid>

        </Grid>   
      </Grid>}

        {activeCameras.length>0 && submitted && (<Grid item xs={12} mt={2}>
          <Stack direction="row" justifyContent="flex-end">
            <LoadingButton variant="contained" onClick={showFilter}>
              Show/Hide Filters
            </LoadingButton>
          </Stack>
        </Grid>)}

        {submitted && liveCamerasList.length > 0 && (
          <Grid container spacing={2} mt={3} >
            {/* Render grid items dynamically */}
            {liveCamerasList.map((camera, index) => (
              <Grid item {...gridLayout} key={index} sx={{ padding: '10px' }}  xs={xsCount} > 
                <Card sx={{ background: "#f0f3f5", padding: "10px",width:outerBoxWidth, height:outerBoxHeight  }}>
                <CardContent sx={{ pt: 1, pb: 0 }}>
                    <Typography variant="h6">
                      {camera.devicename}
                    </Typography>
                  </CardContent>
                   <Box sx={{   
                        borderRadius: 3.5,    
                        width: cameWidth ,
                        height: cameHeight,
                        // width:"100%",
                        // height:"100%",
                      }}>                 
                          <iframe id="fp_embed_player"   
                          src={generateIframeSrc(camera)}
                          // src={`https://restreamservers.com:8888/embed_player?urlServer=wss://restreamservers.com:8443&streamName=${camera.substream}&amp?&autoplay=1&mediaProviders=WebRTC,Flash,MSE,WSPlayer&amp;`}                       
                          allowFullScreen={true} 
                          style={{
                             width: cameWidth ,
                              height: cameHeight,
                               border: 'none',padding:0,margin:0 }}                           
                          >
                          </iframe>             
                  </Box>




                </Card>
              </Grid>
            ))}
          </Grid>
        )}


      </Container>
    </Page>
  );
}