import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { FormControl, Grid, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Stack, Box, LinearProgress } from '@mui/material';
import Label from '../../components/Label';
import ServerUtilization from './ServerUtilization';
import { useTheme } from '@mui/material/styles';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LoadingButton } from '@mui/lab';
import CameraDetails from './CameraDetails';
import { serverTrack } from 'src/api/servers';
import dayjs from 'dayjs';
 

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomTableCell = styled(TableCell)({
    padding: 10,
  });

const CameraTableCell = styled(TableCell)({
    padding: 10,
    borderRight: '1px solid rgb(211 211 211)',
    borderBottom: '1px solid rgb(211 211 211)'
});

function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {props.displaytext ? props.displaytext : `${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

export default function ServerDetails({open, handleDetailBox, serverData}) {

    const theme = useTheme();
    const [fromDate, setFromDate] = React.useState(dayjs().startOf('day').hour(0).minute(0));
    const [toDate, setToDate] = React.useState(dayjs().startOf('day').hour(23).minute(59))
    const [CPUusage, setCPUusage] = React.useState({labels: [], data: []});
    const [RAMusage, setRAMusage] = React.useState({labels: [], data: []});
    const [StorageUsage, setStorageUsage] = React.useState({labels: [], data: []});
    const [isGettingTrack, setGetStatus] = React.useState(false);


    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
      setToDate(date);
  };

    

    React.useEffect(() => {
      if(serverData.hasOwnProperty("ServerId") && serverData.ServerId) {
        getServerUsage(false)
      }
    }, [serverData.ServerId])

    const getServerUsage = React.useCallback((eventStatus) => {
      if (eventStatus)
        setGetStatus(true)
      serverTrack({
          serverId: serverData.ServerId,
          fromDateTime: fromDate.format("YYYY-MM-DD HH:mm:ss"), 
          toDateTime: toDate.format("YYYY-MM-DD HH:mm:ss")
        }, (response) => {
          if (eventStatus)
            setGetStatus(false)
          if (response.status === 200) {
            
            const cpuUsage = []
            const cpuUsageLabels = []
            const ramUsage = []
            const ramUsageLabels = []
            const storageUsageData = []
            const storageUsageLabels = []
            const result = response.data.map((data) => {
              cpuUsage.push(data.CPUUsage)
              ramUsage.push(data.RAMUsage)
              storageUsageData.push(data.StorageUsage)
              cpuUsageLabels.push(data.Datetime)
              ramUsageLabels.push(data.Datetime)
              storageUsageLabels.push(data.Datetime)
              return data
            })
            // console.log("cpuUsage", cpuUsage)
            setCPUusage({labels: cpuUsageLabels, data: cpuUsage})
            setRAMusage({labels: ramUsageLabels, data: ramUsage})
            setStorageUsage({labels: storageUsageLabels, data: storageUsageData})
          }          
        })
        
    }, [serverData, fromDate, toDate])

    

  return (
      <Dialog
        fullScreen
        open={open}
        onClose={() => handleDetailBox({isOpen: false, data: serverData})}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleDetailBox({isOpen: false, data: serverData})}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">{serverData.ServerName || ""}</Typography>           
          </Toolbar>
        </AppBar>
        <Grid sx={{p: 2}} container spacing={2}>
            <Grid item xs={6}>
                {/* <Typography sx={{ background: "#e5e5e5", padding: "9px 17px"}} variant='h6'>Server Details</Typography> */}
                <Table sx={{border: "1px solid #e5e5e5"}}>
                    <TableBody>
                        <TableRow>
                            <CustomTableCell><b>Server Name</b></CustomTableCell>
                            <CustomTableCell>{serverData.ServerName || ""}</CustomTableCell>
                            <CustomTableCell><b>Server Monitoring Status</b></CustomTableCell>
                            <CustomTableCell>{serverData.hasOwnProperty("MonitoringStatus") ? (
                                <Label variant="ghost" color={serverData.MonitoringStatus === 1 ? 'success' : 'error'}>
                                    {serverData.MonitoringStatus === 1 ? 'Online' : 'Offline'}
                                </Label>): ""}                                
                            </CustomTableCell>
                        </TableRow>
                        <TableRow>
                            <CustomTableCell><b>Server Type</b></CustomTableCell>
                            <CustomTableCell>{serverData.ServerName || ""}</CustomTableCell>
                            <CustomTableCell><b>Server ID</b></CustomTableCell>
                            <CustomTableCell>{serverData.ServerId || ""}</CustomTableCell>
                        </TableRow>
                        <TableRow>
                            <CustomTableCell><b>Server Local IP</b></CustomTableCell>
                            <CustomTableCell>{serverData.ServerIp || ""}</CustomTableCell>
                            <CustomTableCell><b>Server Public IP</b></CustomTableCell>
                            <CustomTableCell>{serverData.PublicIp || ""}</CustomTableCell>
                        </TableRow> 
                        <TableRow>
                            <CustomTableCell><b>AI Port</b></CustomTableCell>
                            <CustomTableCell>{serverData.AiPort || ""}</CustomTableCell>
                            <CustomTableCell><b>AI Protocol</b></CustomTableCell>
                            <CustomTableCell>{serverData.Protocol || ""}</CustomTableCell>
                        </TableRow> 
                        <TableRow>
                            <CustomTableCell><b>Server Domain</b></CustomTableCell>
                            <CustomTableCell>{serverData.DNS || ""}</CustomTableCell>
                            <CustomTableCell><b>Storage</b></CustomTableCell>
                            <CustomTableCell>{serverData.Storage || ""}</CustomTableCell>
                        </TableRow>
                        <TableRow>
                            <CustomTableCell><b>Cores</b></CustomTableCell>
                            <CustomTableCell>{serverData.cores || ""}</CustomTableCell>
                            <CustomTableCell><b>RAM</b></CustomTableCell>
                            <CustomTableCell>{serverData.RAM || ""}</CustomTableCell>
                        </TableRow> 
                        <TableRow>
                            <CustomTableCell><b>Server Created On</b></CustomTableCell>
                            <CustomTableCell>{serverData.CreatedOn || ""}</CustomTableCell>
                            <CustomTableCell><b>Last Updated From Server</b></CustomTableCell>
                            <CustomTableCell>{serverData.LastUpdate || ""}</CustomTableCell>
                        </TableRow> 
                        <TableRow>
                            <CustomTableCell><b>Status</b></CustomTableCell>
                            <CustomTableCell>{serverData.hasOwnProperty("Status") ? (
                                <Label variant="ghost" color={Number(serverData.Status) === 1 ? 'success' : 'error'}>
                                    {Number(serverData.Status) === 1 ? 'Active' : 'In Active'}
                                </Label>): ""}                                
                            </CustomTableCell>
                            <CustomTableCell><b>Camalytics Webhook URL</b></CustomTableCell>
                            <CustomTableCell>{`${serverData.Protocol || 'http'}://${serverData.IsPrivateIp ? serverData.PublicIp: serverData.ServerIp}:${serverData.AiPort}/gen-camlytic-event`}</CustomTableCell>
                        </TableRow> 
                                             
                    </TableBody>
                </Table>
                <Divider />
                <Typography sx={{ background: "#e5e5e5", padding: "9px 17px", mt: 2}} variant='h6'>Server Cameras</Typography>             
                <CameraDetails serverInfo={serverData}/>
            </Grid>
            <Grid item xs={6}>
                <Stack
                direction="row"
                spacing={2}
                sx={{
                    justifyContent: "flex-end",
                    alignItems: "center",
                  mb: 2
                }}
                >
                    <FormControl >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                            label="From Date"
                            id="from-date"
                            format="YYYY-MM-DD HH:mm"
                            value={fromDate}
                            required={true}
                            onChange={handleFromDateChange}
                            sx={{ border: 'none' }}
                            //maxDate={dayjs()}
                            // maxDate={calculatedDay}
                            // disableHighlightToday
                            //minDate={calculatedDay}
                            ampm={true}
                            />
                        </LocalizationProvider>
                        {/* {errorFromdate && <Typography color="error" variant="body2" align="center">{errorFromdate}</Typography>} */}
                    </FormControl>

                    <FormControl>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                            label="To Date Time"
                            id="from-date"
                            format="YYYY-MM-DD HH:mm"
                            value={toDate}
                            required={true}
                            onChange={handleToDateChange}
                            sx={{ border: 'none' }}
                            //maxDate={dayjs()}
                            // maxDate={calculatedDay}
                            // disableHighlightToday
                            //minDate={calculatedDay}
                            ampm={true}
                            />
                        </LocalizationProvider>
                        {/* {errorFromdate && <Typography color="error" variant="body2" align="center">{errorFromdate}</Typography>} */}
                    </FormControl>

                    <LoadingButton loading={isGettingTrack} size="large" variant='contained' onClick={() => getServerUsage(true)}>Get</LoadingButton>

                </Stack>
                <ServerUtilization
                    title="Server Usage"
                    chartLabels={CPUusage.labels}
                    chartData={[
                        { name: 'CPU', data: CPUusage.data },
                        { name: 'RAM', data: RAMusage.data },
                        { name: 'Storage', data: StorageUsage.data }
                    ]}
                    chartcolors={[
                      theme.palette.primary.main,
                      theme.palette.success.main,
                      theme.palette.warning.main
                    ]}
                    sx={{ backgroundColor: '#f8f4ff', px: 1 }}
                />                
                {/* <Divider/>
                <Box sx={{ p: 3}}>
                    <Typography variant='h6'>Storage</Typography>
                    <LinearProgressWithLabel sx={{height: "27px"}} variant="determinate" displaytext={`${serverData.usedStorageGB}GB /${serverData.Storage}GB`} value={serverData.StorageUtilisation ? serverData.StorageUtilisation: 0} color={serverData.StorageUtilisation >= 90 ? 'error' : serverData.StorageUtilisation < 90 && serverData.StorageUtilisation >= 80 ? 'warning': 'success'} />    
                </Box> */}
            </Grid>
        </Grid>
      </Dialog>
  );
}