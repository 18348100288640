
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";
const firebaseConfig = {
    apiKey: "AIzaSyAW0ggL0-mcGMDyGuuTXUVM6Ac6DbEAJaI",
    authDomain: "ai-vdointel.firebaseapp.com",
    projectId: "ai-vdointel",
    storageBucket: "ai-vdointel.firebasestorage.app",
    messagingSenderId: "363252601808",
    appId: "1:363252601808:web:08dbab1fdc3c7215b45fd5",
    measurementId: "G-T6G7M9GDJ8"
};
const V_API_KEY = "BFKQ8MsfNLBR9oXchU3RS8hGr_mgAD-OC2-ux7IVoHM7XAEnYs0Xf5kAGnhumTKC2mLQC18ASSXwEgsexmtYET4"
// let app, messaging;
// Initialize Firebase and Messaging
// export const initializeFirebase = () => {
//     try {
//         const app = initializeApp(firebaseConfig);
//         const messaging = getMessaging(app);
//         return { app, messaging };
//     } catch (err) {
//         console.info("Error getting messaging")
//         return { app: null, messaging: null };
//     }
// };
export const initializeFirebase = async () => {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
        const app = initializeApp(firebaseConfig);
        const messaging = getMessaging(app);
        console.log("Firebase Messaging initialized successfully.");
        return { app, messaging };
    } else {
        console.warn("Messaging not supported in this browser.");
        const app = initializeApp(firebaseConfig);
        return { app, messaging: null };
    }
};
// Request Notification Permission and Get Token
export const requestPermissionAndToken = async (app_messaging) => {
    try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
            const token = await getToken(app_messaging, {
                vapidKey: V_API_KEY,
            });
            if (token) {
                console.log("FCM Token:", token);
                return token;
            } else {
                console.log("No registration token available.");
            }
        } else {
            console.log("Notification permission denied.");
        }
    } catch (error) {
        console.error("Error retrieving token:", error);
    }
};