import {React,useEffect, useRef, useState} from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Bar, Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Plugin,
    ArcElement
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; 
import { Box, Table, TableRow,TableCell, Button, Typography, TableHead,TableBody, Grid } from '@mui/material';
import { BatteryCharging20, Pages } from '@mui/icons-material';
// import { html2pdf } from 'html2pdf.js';
import * as html2pdf from 'html2pdf.js';



ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend, ChartDataLabels);

const FormComponent = ({barGraphData,Data,Month,Year,clientName}) => {
    console.log(barGraphData,Data,Month,Year)
    let {cameras,total_event_count,true_event_count,escalated_event_count} = Data[0]
    //console.log('clientname',clientName)
    const contentRef = useRef();

    const chartData = {
        labels: barGraphData.map(item => item.devicename),
        datasets: [
            {
                label: 'True Event Count',
                data: barGraphData.map(item => item.true_event_count),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                datalabels: {
                    anchor: 'end',
                    align: 'end',
                    formatter: (value, context) => {
                        return value; // Display value only if greater than 0
                    },
                    color:'black'
                },
            },
            {
                label: 'False Event Count',
                data: barGraphData.map(item => item.false_event_count),
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
                datalabels: {
                    anchor: 'end',
                    align: 'end',
                    formatter: (value, context) => {
                        return value; 
                    },
                     color:'black'
                },
            },
        ],
    };

    const totalTrueEvents = barGraphData.reduce((sum, item) => sum + item.true_event_count, 0);
    const totalFalseEvents = barGraphData.reduce((sum, item) => sum + item.false_event_count, 0);
    const totalEvents = totalTrueEvents + totalFalseEvents;

    const trueEventPercentage = ((totalTrueEvents / totalEvents) * 100).toFixed(2);
    const falseEventPercentage = ((totalFalseEvents / totalEvents) * 100).toFixed(2);

    const pieChartData = {
        labels: ['True Events', 'False Events'],
        datasets: [
            {
                label: 'Event Distribution',
                data: [trueEventPercentage, falseEventPercentage],
                backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(255, 99, 132, 0.6)'],
            },
        ],
    };
    
    const splitTableData = (data, itemsPerPage) => {
        const pages = [];
        for (let i = 0; i < data.length; i += itemsPerPage) {
            pages.push(data.slice(i, i + itemsPerPage));
        }
        return pages;
    };

   // const [pages,setPage]=useState([])
    const ItemsPerPage = 10;
    
const pdfRef = useRef();
const downloadPDF = () =>{
    const element = pdfRef.current;
    let month = Month.slice(0, 3).toUpperCase()
    const options = {
        margin: 10,
        filename: `${clientName}_${month}_${Year}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
        // pagebreak: { mode: ['avoid-all', 'css', 'legacy'], before: '.page-break' },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
      };

    // Generate PDF
    html2pdf()
        .from(element)
        .set(options)
        .save();
}

    const A4Sheet = {
        width: '297mm',   
        height: '420mm',   
        margin: 'auto',   
        //padding: '20mm', 
        border: '1px solid black',   
        boxSizing: 'border-box', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center' 
    };
      
      const Content = {
        width: '100%', 
        height: '100%',   
        overflow: 'auto'
      };
  
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box sx={{paddingLeft:'1rem'}}>
                    <Button onClick={downloadPDF} variant="contained">Download PDF</Button>
                </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={{ ...A4Sheet }}>
                        <Box sx={{ ...Content }}>         
                            <Box>
                                <Box
                                ref={pdfRef} 
                                id="pdf-content"
                                sx={{ fontFamily: "Calibri, 'Segoe UI', Tahoma, sans-serif", fontSize: "small", padding: '10px', overflow: 'hidden' }}
                                >

                                <Grid sx={{color:'white',textAlign:'center'}}>
                                <Grid container>
                                        <Grid item xs={12}>
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: '50px',
                                                    width: '100px',
                                                }}
                                                alt="logo"
                                                src="/static/logo.svg"
                                                >

                                            </Box>
                                        </Grid>
                                    </Grid>
                                <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" component="h1" style={{ fontWeight: "bold",color:'black',textAlign: 'left' }}>
                                                        VDOintel Service Overview Dashboard
                                            </Typography>
                                        </Grid>
                                </Grid>
                                <Grid container style={{ marginTop: '20px' }}>
                                    <Grid item xs={12}>
                                            <Typography variant="body1" style={{ 
                                                backgroundColor: "black",
                                                fontWeight:'bold', 
                                                padding: '16px 0',
                                                }}>
                                                    SERVICE HIGHLIGHTS
                                            </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container style={{ marginTop: '10px' }}> 
                                        <Grid item xs={4}>
                                            <Typography variant="body2" style={{ backgroundColor: "black",padding: '8px 0' }}>
                                                                    SERVICE PERIOD
                                            </Typography>
                                            <Typography variant="body2" style={{ color: 'black' }}>
                                                                    <b>{Month} - {Year}</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body2" style={{ backgroundColor: "black",padding: '8px 0' }}>
                                                                    NUMBER OF CAMERAS MONITORED
                                            </Typography>
                                            <Typography variant="body2" style={{ color: 'black' }}>
                                                                    <b>{cameras}</b>
                                            </Typography>
                                        </Grid>
                                </Grid>
                                <Grid container style={{ marginTop: '10px' }}>
                                        <Grid item xs={4}>
                                            <Typography variant="body2" style={{ backgroundColor: "black",padding: '8px 0' }}>
                                                                TOTAL EVENTS TRIGGERED 
                                            </Typography>
                                            <Typography variant="body2" style={{ color: 'black' }}>
                                            <b>{total_event_count}</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body2" style={{ backgroundColor: "black",padding: '8px 0' }}>
                                                                TOTAL TRUE EVENTS
                                            </Typography>
                                            <Typography variant="body2" style={{ color: 'black' }}>
                                            <b>{true_event_count}</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body2" style={{ backgroundColor: "black",padding: '8px 0' }}>
                                                                TOTAL ESCALATIONS
                                            </Typography>
                                            <Typography variant="body2" style={{ color: 'black' }}>
                                            <b>{escalated_event_count}</b>
                                            </Typography>
                                        </Grid>
                                </Grid>
                            </Grid>
                                <Table sx={{marginTop:'20px'}}>                                   
                                    {/* bar chart */}
                                    <TableRow>
                                    <TableCell
                                        style={{
                                        textAlign: "center",
                                        //height: "40px",
                                        backgroundColor: "black",
                                        color: "white",
                                        }}
                                        colSpan="4"
                                    >
                                        <b>TREND OF AUDITS BY CAMERA</b>
                                    </TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell
                                        colSpan="4"
                                        style={{
                                        textAlign: "center",
                                        //height: "40px",
                                        backgroundColor: "white",
                                        }}
                                    >
                                        <Box height={300} padding={2}>
                                        <Bar
                                            data={chartData}
                                            options={{
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                layout: {
                                                    padding: {
                                                        left: 10,
                                                        right: 20,
                                                        top: 10,
                                                        bottom: 10, 
                                                    },
                                                },
                                                plugins: {
                                                    legend: {
                                                        position: 'top',
                                                    },
                                                    datalabels: {
                                                        color: '#fff',
                                                    },
                                                },
                                                scales: {
                                                    x: {
                                                        stacked: false,
                                                        ticks: {
                                                            autoSkip: false, 
                                                        },
                                                    },
                                                    y: {
                                                        stacked: false,
                                                        beginAtZero:true
                                                    },
                                                },
                                            }}
                                        />
                                        </Box>
                                    
                                    </TableCell>
                                    </TableRow>
                                </Table>
                               
                                <Table>
                                    <TableRow>
                                    <TableCell colSpan="1" style={{ width: "40%", height: "150%" }}>
                                        {/* <img src="#piechart" alt="piechart" id="image1" /> */}
                                        <Box height={300} padding={2}>
                                                        <Pie
                                                            data={pieChartData}
                                                            options={{
                                                                plugins: {
                                                                    tooltip: {
                                                                        callbacks: {
                                                                            label: function (context) {
                                                                                const label = context.label || '';
                                                                                const value = context.raw || 0;
                                                                                return `${label}: ${value} (${((value / totalEvents) * 100).toFixed(2)}%)`;
                                                                            }
                                                                        }
                                                                    },
                                                                },
                                                            }}
                                                        />
                                        </Box>
                                    </TableCell>
                                    <TableCell
                                        style={{
                                        border: "solid",
                                        width: "60%",
                                        fontFamily: "Calibri",
                                        fontSize: "small",
                                        }}
                                        id="definition"
                                    >
                                        <Table>
                                        <TableRow>
                                            <TableCell>
                                            <p>
                                                <b>Definitions:</b>
                                            </p>
                                            <ul>
                                                <li style={{ fontFamily: "Calibri", fontSize: "small" }}>
                                                Events triggered - Video feeds are transmitted in real time
                                                whenever there is a motion detected in the camera view.
                                                </li>
                                                <li style={{ fontFamily: "Calibri", fontSize: "small" }}>
                                                True Event - Video feed that has any kind of person or
                                                vehicle movement is categorized as a true event.
                                                </li>
                                                <li style={{ fontFamily: "Calibri", fontSize: "small" }}>
                                                False Event - Events triggered by motion of
                                                Animals/Insects/Rain/Snow/Plant movement due to air are
                                                categorized under false event.
                                                </li>
                                                <li style={{ fontFamily: "Calibri", fontSize: "small" }}>
                                                Escalation - Command center agent filters the event into
                                                false alarms/unusual activity. When unusual activity is
                                                identified, necessary action is taken by the command
                                                center as per protocol and the evidence is escalated to
                                                the client as evidence.
                                                </li>
                                                {/* <li
                                                style={{
                                                    fontFamily: "Calibri",
                                                    fontSize: "small",
                                                    color: "red",
                                                }}
                                                >
                                                Note - All the appended URLs are only valid up to 180 days
                                                from the date of escalation.
                                                </li> */}
                                            </ul>
                                            </TableCell>
                                        </TableRow>
                                        </Table>
                                    </TableCell>
                                    </TableRow>
                                </Table>
                               
                                
                                <Box
                                    style={{
                                    //width: "100%",
                                    backgroundColor: "dodgerblue",
                                    height: "40px",
                                    color: "white",
                                    textAlign: "center",
                                    borderRadius: "8px",
                                    }}
                                >
                                    URL's OF ESCALATED EVENTS
                                </Box>

                                {/* <Box style={{ width: "100%", fontFamily: "Calibri", fontSize: "small" }} id="tblInformation"> */}
                                <Table>
                                
                                    <TableHead>
                                        <TableRow>
                                            <TableCell component="th" align="center" scope="col" style={{ border: "1px solid black", padding: "5px",fontWeight: "bold" }}>
                                            S.No
                                            </TableCell>
                                            <TableCell component="th" align="center" scope="col" style={{ border: "1px solid black", padding: "5px",fontWeight: "bold" }}>
                                            Event Number
                                            </TableCell>
                                            <TableCell component="th" align="center" scope="col" style={{ border: "1px solid black", padding: "5px",fontWeight: "bold" }}>
                                            Device Name
                                            </TableCell>
                                            <TableCell component="th" align="center" scope="col" style={{ border: "1px solid black", padding: "5px",fontWeight: "bold" }}>
                                            Escalated URL
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {Data && Data[0] && Data[0].Details && Data[0].Details.length > 0 && 
                                            Data[0].Details.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell style={{ border: "1px solid black", padding: "5px",fontSize:'12px' }}>
                                                    {index+1} 
                                                </TableCell>
                                                <TableCell style={{ border: "1px solid black", padding: "5px",fontSize:'12px' }}>
                                                    {item.EventNo} 
                                                </TableCell>
                                                <TableCell style={{ border: "1px solid black", padding: "5px",fontSize:'12px' }}>
                                                    {item.devicename} 
                                                </TableCell>
                                                {/* <TableCell style={{ border: "1px solid black", padding: "5px",fontSize:'12px',cursor: 'pointer' }}
                                                    onClick={()=>{
                                                        window.open(item.VideoUrl, '_blank');
                                                    }}
                                                >
                                                    {item.VideoUrl} 
                                                </TableCell> */}
                                                 <TableCell style={{ border: "1px solid black", padding: "5px", fontSize: '12px' }}>
                                                    <a href={item.VideoUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'blue' }}>
                                                        {item.VideoUrl}
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            ))
                                        }
                                    </TableBody>

                                </Table>
                                {/* </Box> */}
                            
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default FormComponent;