import axios from '../axios'
import { getURL, jsonToParam ,jsonToFormData } from 'src/utils/config';
import { handleResponse } from 'src/utils/responseHandler';


// get cameras data
const getServers = (callback) => {
    // let params = jsonToParam(body)
    // params = params ? `?${params}`: ''
    axios({
        method: 'get',
        url: `${getURL('/servers')}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const addNewServer = (formdata, callback) => {
    axios({
        method: 'post',
        url: getURL('servers/add'),
        data: jsonToFormData(formdata),
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}



const serverDetails = (serverID ,callback) => {
    axios({
        method: 'get',
        url: `${getURL('servers/server-info-by-id')}?ServerId=${serverID}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}


const changeServerStatus = (data ,callback) => {
    const { ServerId, Status  } = data;
    axios({
        method: 'put',
        url: `${getURL('servers/update-status')}?ServerId=${ServerId}&Status=${Status}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const deleteServer = (data ,callback) => {
    const { ServerId } = data;
    axios({
        method: 'DELETE',
        url: `${getURL('servers/delete')}?ServerId=${ServerId}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const updateServer = (body,callback)=>{
    axios({
        method: 'put',
        url: getURL('servers/update'),
        data: jsonToFormData(body),
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const serverTrack = (data ,callback) => {
    const { serverId, fromDateTime, toDateTime } = data;    

    axios({
        method: 'get',
        url: `${getURL('servers/server-track')}?ServerId=${serverId}&FromDate=${fromDateTime}&ToDate=${toDateTime}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}



export { getServers, addNewServer, serverDetails, changeServerStatus, deleteServer, updateServer, serverTrack}