import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Stack,
  TextField,
  Alert,
  Box,
  Typography,
  Paper,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import { addNewServer, updateServer } from 'src/api/servers';

export default function AddEditForm({ onClose, loadServersList, serverDetails }) {
  //console.log(onClose)
  const [isError, setErrorMsg] = useState({ status: false, msg: "" });


  const validationSchema = Yup.object().shape({
    serverName: Yup.string().required('Server Name is required'),
    serverType: Yup.string().required('Server Type is required'),

  });


  const formik = useFormik({
    initialValues: {
      serverName: serverDetails.serverName,
      serverType: serverDetails.serverType,
      isPrivateIp: serverDetails.isPrivateIp,
      publicIp: serverDetails.publicIp,
      localIp: serverDetails.localIp,
      aiPort: serverDetails.aiPort,
      dns: serverDetails.dns,
      protocol: serverDetails.protocol
    },
    validationSchema,
    onSubmit: (values) => {
      //debugger
      setErrorMsg({ status: false, msg: "" });
      handleSave(values);
    }
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setSubmitting, setFieldValue } = formik;

  // useEffect(() => {
  //   if (Action === 'Edit') {
  //     setName(Name)
  //     setFieldValue('serverName', Name);
  //     if (Type) {
  //       setServerType(Type)
  //     }
  //     else {
  //       setServerType('testServer')
  //     }
  //   }
  // }, [Action, Name, Type, setFieldValue])

  const handleSave = (values) => {
    setSubmitting(true)
    const insertData = {
      ServerName: values.serverName,
      ServerType: values.serverType,
      IsPrivateIp: values.isPrivateIp,
      PublicIp: values.publicIp,
      ServerIp: values.localIp,
      AiPort: values.aiPort,
      DNS: values.dns,
      Protocol: values.protocol
    }  

    if (serverDetails.serverId) {
      const updateData = {...insertData, ServerId: serverDetails.serverId}
      updateServer(updateData, (response) => {
        setSubmitting(false)
        if ([200, 201].includes(response.status)) {
          loadServersList();
          if (typeof onClose === 'function') {
            onClose("right")(false);            
          }
        }
      })      
    }
    else {      
      addNewServer(insertData, (response) => {
        setSubmitting(false)
        if ([200, 201].includes(response.status)) {     
          onClose("right")(false);
          loadServersList();
        }
      })  
    }

  };




  const handleClose = (event) => {
    if (typeof onClose === 'function' && serverDetails.serverId) {
      onClose("right", false)(event);
    }
    else if (typeof onClose === 'function' && !serverDetails.serverId) {
      onClose(event);
    }
  }


  return (
    <Box sx={{ p: 3, position: "relative", height: "100%", width: 350 }}>
      <Stack direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        spacing={1} sx={{ position: 'absolute', top: 0, left: 0, p: 2, backgroundColor: 'white' }}>
        <Typography variant='h5'>{serverDetails.serverId ? "Update Server Details": "Add Server"}</Typography>
      </Stack>
      <Paper sx={{ position: 'absolute', top: 80, right: 0, width: "100%", px: 2 }}>
        <FormikProvider value={formik}>
          {isError.status && (
            <Alert severity="error" sx={{ mb: 3 }}>{isError.msg}</Alert>
          )}
          <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ height: "100%" }}>
            <Stack spacing={3} sx={{ mb: 3 }}>
              <TextField
                fullWidth
                label="Server Name"
                {...getFieldProps('serverName')}
                error={Boolean(touched.serverName && errors.serverName)}
                helperText={touched.serverName && errors.serverName}
                value={values.serverName}
                onChange={(event) => setFieldValue('serverName', event.target.value)}
              />

              <FormControl fullWidth>
                <InputLabel id="server-type-label">Server Type</InputLabel>
                <Select
                  labelId="server-type-label"
                  id="server-type"
                  {...getFieldProps('serverType')}
                  error={Boolean(touched.serverType && errors.serverType)}
                  // helperText={touched.serverType && errors.serverType}
                  value={values.serverType}
                  label="Server Type"
                  onChange={(event) => setFieldValue('serverType', event.target.value)}
                >
                  <MenuItem value='localVirtualMachine'>Local Virtual Machine</MenuItem>
                  <MenuItem value='thirdPartyServer'>Third Party Server</MenuItem>
                  <MenuItem value='clientDevices'>Client Devices</MenuItem>
                  <MenuItem value='iotDevices'>IOT Devices</MenuItem>
                  <MenuItem value='testServer'>Test Server</MenuItem>
                </Select>
              </FormControl>


                <FormGroup>
                  <FormControlLabel 
                  control={<Switch checked={values.isPrivateIp === 1} 
                  onChange={() => setFieldValue('isPrivateIp', values.isPrivateIp === 1 ? 0: 1)} />} 
                  label="Is Private IP" />
                </FormGroup>

                <TextField
                  fullWidth
                  label="Local Ip Address"
                  {...getFieldProps('localIp')}
                  value={values.localIp}
                  onChange={(event) => setFieldValue('localIp', event.target.value)}
                />

                <TextField
                  fullWidth
                  label="Public Ip Address"
                  {...getFieldProps('publicIp')}
                  value={values.publicIp}
                  onChange={(event) => setFieldValue('publicIp', event.target.value)}
                />

                <TextField
                  fullWidth
                  label="Server Domain Name"
                  {...getFieldProps('dns')}
                  value={values.dns}
                  onChange={(event) => setFieldValue('dns', event.target.value)}
                />

                <TextField
                  fullWidth
                  label="AI Port"
                  {...getFieldProps('aiPort')}
                  value={values.aiPort}
                  onChange={(event) => setFieldValue('aiPort', event.target.value)}
                />

                <FormGroup>
                  <FormControlLabel 
                  control={<Switch checked={values.protocol === 'https'} 
                  onChange={() => setFieldValue('protocol', values.protocol === 'http' ? 'https': 'http')} />} 
                  label="Is AI Address Secured(https)" />
                </FormGroup>



            </Stack>


            <Stack direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              width="100%"
              sx={{ position: 'absolute', bottom: -45, right: 0, p: 2 }}>
              <Button variant='contained' color='error' fullWidth onClick={handleClose} >Cancel</Button>
              <LoadingButton
                fullWidth
                // size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                Save
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Paper>
    </Box>
  );
}