import {
  Container,
  Typography,
  Grid,
  Stack,
  Button,
  Drawer,
  Box,
  Tab,
  Card,
  Badge
} from '@mui/material';

// components
import Page from '../components/Page';
import { useEffect, useState } from 'react';
import { AddEditForm } from 'src/sections/servers';
import { getServers } from 'src/api/servers';

import {
  TabContext,
  TabList,
  TabPanel
} from '@mui/lab';
import ServerList from 'src/sections/servers/ServerList';


// ----------------------------------------------------------------------







const anchor = 'right'

const SERVER_INIT_VALUES = {
  serverId: null,
  serverName: "",
  serverType: "",
  isPrivateIp: 0,
  publicIp: "",
  localIp: "",
  aiPort: 9023,
  dns: "",
  protocol: "http"
}


export default function Servers() {


  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });


  const [reloadServer, setReloadServer] = useState(false)
  const [value, setValue] = useState('all');
  const [allServerTypes, setServerTypes] = useState({
    all: {
      key: "all",
      name: "All",
      data: []
    },
    localVirtualMachine: {
      key: "localVirtualMachine",
      name: "Local Virtual Machine",
      data: []
    },
    thirdPartyServer: {
      key: "thirdPartyServer",
      name: "Third Party Server",
      data: []
    },
    clientDevices: {
      key: "clientDevices",
      name: "Client Devices",
      data: []
    },
    iotDevices: {
      key: "iotDevices",
      name: "IOT Devices",
      data: []
    },
    testServer: {
      key: "testServer",
      name: "Test Server",
      data: []
    }
  })

  const [editServerDetails, setEditServerData] = useState({ ...SERVER_INIT_VALUES })
  const [isServerLoading, setIsServerLoading] = useState(false)

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };



  const loadServersList = () => {
    //  debugger
    setIsServerLoading(true)
    setReloadServer(!reloadServer)
  }

  useEffect(() => {
    //  debugger
    getServers((response) => {
      setIsServerLoading(false)
      if (response.status === 200) {
        if (response.data && response.data !== undefined && response.data !== null) {
          setServerTypes((_) => {
            const serverData = {
              all: {
                key: "all",
                name: "All",
                data: []
              },
              localVirtualMachine: {
                key: "localVirtualMachine",
                name: "Local Virtual Machine",
                data: []
              },
              thirdPartyServer: {
                key: "thirdPartyServer",
                name: "Third Party Server",
                data: []
              },
              clientDevices: {
                key: "clientDevices",
                name: "Client Devices",
                data: []
              },
              iotDevices: {
                key: "iotDevices",
                name: "IOT Devices",
                data: []
              },
              testServer: {
                key: "testServer",
                name: "Test Server",
                data: []
              }
            }
            serverData.all.data = response.data
            serverData.all.count = response.data.length
            const res = response.data.forEach((row) => {
              if (Object.keys(serverData).includes(row.ServerType)) {
                serverData[row.ServerType].data.push(row)
              }
            })
            console.log("serverData", serverData)
            return { ...serverData }
          })
        }
        // setData(response.data);
      }
    })
  }, [reloadServer])


  const toggleDrawer = (anchor, open) => (event) => {
    // console.log('toggleDrawer',event);
    //debugger
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
    setEditServerData({ ...SERVER_INIT_VALUES })
  };


  const updateServerDetails = (details) => {
    setEditServerData({
      ...{
        serverId: details.ServerId,
        serverName: details.ServerName || SERVER_INIT_VALUES.serverName,
        serverType: details.ServerType || SERVER_INIT_VALUES.serverType,
        isPrivateIp: details.IsPrivateIp || SERVER_INIT_VALUES.isPrivateIp,
        publicIp: details.PublicIp || SERVER_INIT_VALUES.publicIp,
        localIp: details.ServerIp || SERVER_INIT_VALUES.localIp,
        aiPort: details.AiPort || SERVER_INIT_VALUES.aiPort,
        dns: details.DNS || SERVER_INIT_VALUES.dns,
        protocol: details.Protocol || SERVER_INIT_VALUES.protocol
      }
    })
    setState((lastStateData) => ({ ...lastStateData, right: true }));
  }

  return (
    <Page title="Servers">
      <Container maxWidth="xl">


        <Drawer
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
        >
          <AddEditForm
            onClose={toggleDrawer}
            serverDetails={editServerDetails}
            loadServersList={loadServersList} />
        </Drawer>


        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ pb: 5 }}
        >
          <Typography variant='h4'>Servers</Typography>
          <Button variant='contained' onClick={toggleDrawer(anchor, true)}>Add Server</Button>
        </Stack>







        <Card>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', backgroundColor: "#f3f3f3" }}>
                <TabList style={{ minHeight: "auto" }} onChange={handleChange} aria-label="lab API tabs example">
                  {Object.keys(allServerTypes).map((key, index) => (
                    <Tab
                      style={{ minHeight: "50px" }}
                      key={index}
                      icon={<Badge style={{ minHeight: "auto", marginLeft: "15px" }} badgeContent={String(allServerTypes[key].data.length)} color="primary" />}
                      iconPosition="end"
                      label={allServerTypes[key].name}
                      value={key} />
                  ))}
                </TabList>
              </Box>
              {Object.keys(allServerTypes).map((key, index) => (
                <TabPanel key={index} sx={{ p: 0 }} value={key}>
                  <ServerList servers={allServerTypes[key].data} titleKey={key} handleUpdateDetails={updateServerDetails} loadServersList={loadServersList} isServerLoading={isServerLoading} />
                </TabPanel>
              ))}
            </TabContext>
          </Box>
        </Card>








      </Container>
    </Page>
  );
}