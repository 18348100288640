import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';

const BarGraph = ({ data }) => {
  const [visibleEvents, setVisibleEvents] = useState({
    'True Events': true,
    'False Events': true,
    'Escalated Events': true
  }); // Track which event types are visible

  const cameras = data.map(item => item.devicename);

  // Extracting TrueEvents, FalseEvents, and EscalatedEvents data
  const trueEvents = data.map(item => item.true_event_count);
  const falseEvents = data.map(item => item.false_event_count);
  const escalatedEvents = data.map(item => item.escalated_count);

  // Calculate the max count for selected visible event types
  const getMaxCountForVisibleEvents = () => {
    let visibleCounts = [];

    if (visibleEvents['True Events']) {
      visibleCounts = [...visibleCounts, ...trueEvents];
    }
    if (visibleEvents['False Events']) {
      visibleCounts = [...visibleCounts, ...falseEvents];
    }
    if (visibleEvents['Escalated Events']) {
      visibleCounts = [...visibleCounts, ...escalatedEvents];
    }

    return Math.max(...visibleCounts);
  };

  // Calculate nearest multiple of 50 based on max event count
  const getNearestMultipleOf50 = (maxCount) => Math.ceil(maxCount / 50) * 50;

  // Dynamic x-axis max based on selected visible event types
  const getXMaxValue = () => {
    const maxCountForVisibleEvents = getMaxCountForVisibleEvents();
    if (maxCountForVisibleEvents < 10 && maxCountForVisibleEvents!==0) {
      return 10;
    }
    return getNearestMultipleOf50(maxCountForVisibleEvents);
  };

  // Chart data setup
  const chartData = {
    labels: cameras,
    datasets: [
      {
        label: 'True Events',
        backgroundColor: 'rgba(79, 169, 44 , 1)',
        data: trueEvents,
        hidden: !visibleEvents['True Events'] // Hide this dataset if it's not visible
      },
      {
        label: 'False Events',
        backgroundColor: 'rgba(255, 181, 7 , 1)',
        data: falseEvents,
        hidden: !visibleEvents['False Events'] // Hide this dataset if it's not visible
      },
      {
        label: 'Escalated Events',
        backgroundColor: 'rgba(220, 61, 61 , 1)',
        data: escalatedEvents,
        hidden: !visibleEvents['Escalated Events'] // Hide this dataset if it's not visible
      }
    ]
  };

  // Chart options setup
  const options = {
    indexAxis: 'y',
    scales: {
      x: {
        beginAtZero: true,
        max: getXMaxValue() // Dynamically update x-axis max value based on selected event types
      },
      y: {
        beginAtZero: true
      }
    },
    plugins: {
      legend: {
        onClick: (e, legendItem) => {
          // Get the event type (True, False, Escalated) from the clicked legend item
          const eventType = legendItem.text;

          // Toggle visibility state
          setVisibleEvents(prevState => ({
            ...prevState,
            [eventType]: !prevState[eventType]
          }));
        }
      }
    }
  };

  return (
    <div>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default BarGraph;