import { useEffect, useState } from 'react';
import {
  Checkbox,
  Container,
  Stack,
  Typography,
  CircularProgress,
  OutlinedInput,
  InputAdornment,
  Toolbar,
  Box,
  Card,
  Autocomplete,
  TextField,
  Chip,
  Button,
  createFilterOptions,
  FormControl,
  Grid,
} from '@mui/material';
// components
import Page from '../components/Page';
import { AuditList } from '../sections/auditlist';
// mock
import { styled } from '@mui/material/styles';
import useAuditList from 'src/api/useAuditList';
import Iconify from 'src/components/Iconify';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from 'date-fns';
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationAlert from 'src/utils/NotificationAlert';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import RedoIcon from '@mui/icons-material/Redo';
import { getClientGroupInfo } from 'src/api/liveCameras';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const groupicon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const groupcheckedIcon = <CheckBoxIcon fontSize="small" />;

// import CloseIcon from '@mui/icons-material/Close';
// import { useState } from 'react';
// ----------------------------------------------------------------------

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  maxWidth: 200,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const CustomBox = styled(Stack)(({ theme }) => ({
  border: `1px dashed rgba(145, 158, 171, 0.16)`,
  padding: theme.spacing(1),
  borderRadius: 8
}));

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  // padding: theme.spacing(0, 1, 0, 3),
}));



export default function ListAudits({userType, clientId ,IsAgentBased}) {
  const {
    alldata,
    setSearch,
    loadMoreAudits,
    filterAudits,
    updateToClient,
    isEscalating,
    open,
    setOpen,
    cameras,
    analytics,
    fromDate,
    toDate,
    handleFromDate,
    handleToDate,
    detectedType,
    handleDetectedType,
    device,
    handleDevice,
    handleDelete,
    calculatedDay,
    handlerGetFilterData,
    alertStatus,
    handleNotificationBar
  } = useAuditList(userType,IsAgentBased)

  const agentBased = localStorage.getItem('IsAgentBased') 
  console.log("agent based",agentBased);


  const [selectedDevices, setSelectedDevices] = useState([]);

  const [activeCameras, setActiveCameras] = useState([])

  const [checkValue,setCheckValue]=useState(false)

  const [openAuto,setOpenAuto]=useState(false)


  const [openBuzzerSelect, setOpenBuzzerSelect] = useState(false);
  const [activeBuzzers, setActiveBuzzers] = useState([])

  const [errorTodate, setErrorTodate] = useState('')
  const [errorFromdate,setErrorFromdate] = useState('')
  const [clientGroupList, setClientGroupsList] = useState([]);
  const [groupinfo,setGroupinfo] = useState([]);

  const [errorGroup, setErrorGroup] = useState(null);
  const [groupCheckValue,setGroupCheckValue] = useState(false)
  const [activeGroups,setActiveGroups] = useState([])
  const [camerasByGroupId, setCamerasByGroup] = useState([]);
  const [clientType,setClientType] = useState('');
  const handleGroupChange = (event,val) => {
    //debugger
      if(event.target.value && errorGroup){
        setErrorGroup(null)
      }
    
      setCheckValue(false)
      const containsAllProperty = val.some(obj => obj.hasOwnProperty('all'));
    
      if(clientGroupList.length === activeGroups.length && containsAllProperty){
          setGroupCheckValue(false)
          setActiveGroups([])
          setCheckValue(false)
          setActiveCameras([])
          setCamerasByGroup([])
      }
    
      else if(containsAllProperty){
        setGroupCheckValue(true)
        let groupId = clientGroupList.map(val => val.GroupId)
        setActiveGroups(groupId)
      }
    
      else{
        const grpId =  val[0].GroupId
        const avail =activeGroups.length > 0 ? activeGroups.filter(item => item === grpId) : []
    
        if(avail.length>0){ // remove the group id
          const id = activeGroups.filter(item => item !== grpId)
          setActiveGroups(id)
          setGroupCheckValue(false)
    
          if(id.length === 0){
            setCheckValue(false)
            setActiveCameras([])
            setCamerasByGroup([])
          }
        }
        else{ //  add group id
          //setActiveCameras()
          setActiveGroups((id) => [...id, grpId]);
    
          //[...activeGroups, grpId].length === clientGroupList ? setGroupCheckValue(true) : setGroupCheckValue(false)
        }
      }
    };

    const handleGroupFilterOptions = (options, params) => {
      //debugger
      const filter = createFilterOptions();
      const filtered = filter(options, params);
      //return [{ title: 'Select All', all: true }, ...sortedOptions];
      return [{ title: 'Select All', all: true }, ...filtered];
    };
     
    useEffect (()=>{
      if(activeGroups.length === clientGroupList.length && activeGroups.length>0){
         setGroupCheckValue(true)
      }
    
      if(activeGroups.length > 0){
        debugger
        let cams = groupinfo.filter(val => activeGroups.includes(val.GroupId))
        let enrichedDevices = cams.map(groupDevice => {
          let correspondingCamera = cameras.find(camera => camera.deviceid === groupDevice.deviceid);
          if (correspondingCamera) {
              return {
                  ...groupDevice,
                  uid: correspondingCamera.uid 
              };
          }
          return groupDevice;
      });
        setCamerasByGroup(enrichedDevices)
        console.log(activeCameras)
        const newId = enrichedDevices.map(val => val.uid)
        const filArr = activeCameras.filter(val => newId.includes(val))
        console.log(filArr)
        setActiveCameras(filArr)

        if(enrichedDevices.length === filArr.length && filArr.length>0){
          setCheckValue(true)
        }
      }  
    },[activeGroups])

  useEffect(() => {
    let clientId = localStorage.getItem('client_id')
    let clientType = localStorage.getItem('client_type')
    setClientType(clientType)
    let groupId = 0
    let body = {clientId : clientId , groupId : groupId}
    getClientGroupInfo(body,(response) => {
      if (response.status === 200) {
        // console.log("response Data group ", response.data);
        setGroupinfo(response.data)
        const uniqueGroups = Array.from(
          new Map(response.data.map(item => [item.GroupId, { GroupId: item.GroupId, GroupName: item.GroupName }])).values()
      );       
        setClientGroupsList(uniqueGroups);
      }
    });
  }, []);
  
  const handleCamerasChange = (event, value) => {
  debugger
    let actCams=[];
  if (value.some(obj => obj.hasOwnProperty('selectall'))){
    if(activeCameras.length===cameras.length){
      setActiveCameras([])
      setCheckValue(false)
    }
    else{

      if(clientGroupList.length===0){
        cameras.forEach(item=>{
          actCams.push(item.uid)
        })
      }
      else{
        let cams = groupinfo.filter(val => activeGroups.includes(val.GroupId))
        let enrichedDevices = cams.map(groupDevice => {
          let correspondingCamera = cameras.find(camera => camera.deviceid === groupDevice.deviceid);
          if (correspondingCamera) {
              return {
                  ...groupDevice,
                  uid: correspondingCamera.uid 
              };
          }
          return groupDevice;
        });
        enrichedDevices.forEach(item=>{
          actCams.push(item.uid)
        })
      }
     
      setActiveCameras(actCams)
      setCheckValue(true)
    }
  }
  else{
    let id=value[0].uid;
    if(activeCameras.includes(id)){
      let filterArr = activeCameras.filter(devid => devid !== id);
      //console.log(typeof activeClients[0]);
      setActiveCameras(filterArr);
      if(checkValue){
        setCheckValue(false)
      }
    }
    else{
      setActiveCameras((prv)=>[...prv,id])
    }
  }
  };

  useEffect(()=>{
    debugger
    handleDevice('',activeCameras,camerasByGroupId)
    //console.log(activeCameras)
    if((activeCameras.length===cameras.length && activeCameras.length>0 && clientGroupList.length === 0) || (activeCameras.length===camerasByGroupId.length && activeCameras.length>0 && clientGroupList.length > 0)){
      setCheckValue(true)
    }
  },[activeCameras])


  const onFilterAudits = (event) => {
    setSearch(event.target.value)
    filterAudits(event.target.value)
  }

  const handleFilterOptions = (options, params) => {
    //debugger
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    
    // Separate selected and unselected options
    const selectedOptions = filtered.filter(option => activeCameras.includes(option.uid));
    const unselectedOptions = filtered.filter(option => !activeCameras.includes(option.uid));
    
    // Sort options: selected options first, then unselected
    const sortedOptions = [...selectedOptions, ...unselectedOptions];
  
    return [{ title: 'Select All', selectall: true }, ...filtered];
  };


  const handleBuzzersChange = (event, value) => {
    console.log("==BUZZER====",event.target.value, value)
    setActiveBuzzers(value.map((buzzer) => buzzer.uid));
  };

  const handleGet  = () =>{
      ////debugger
      setOpenAuto(false)
      setErrorFromdate('')
      setErrorTodate('')
      console.log(fromDate,toDate);
      const isValidDate = (dateString) => {
        const parsedDate = dayjs(dateString, 'YYYY-MM-DD HH:mm:ss', true); 
        return parsedDate.isValid();
    };

    if(fromDate === null && toDate === null){
      return;
    }

    const fromdDateObj = isValidDate(fromDate)
    const toDateObj = isValidDate(toDate)

    if(!fromdDateObj) setErrorFromdate('Select Valid From Date')
    if(!toDateObj) setErrorTodate('Select Valid To Date')

      handlerGetFilterData()
  }

  const handleExport  = () =>{

    const clientname =  localStorage.getItem('displayname');
    ////debugger
    setOpenAuto(false)
    setErrorFromdate('')
    setErrorTodate('')
    console.log(fromDate,toDate);
    const isValidDate = (dateString) => {
      const parsedDate = dayjs(dateString, 'YYYY-MM-DD HH:mm:ss', true); 
      return parsedDate.isValid();
  };

  if(fromDate === null && toDate === null){
    return;
  }

  const fromdDateObj = isValidDate(fromDate)
  const toDateObj = isValidDate(toDate)

  if(!fromdDateObj) setErrorFromdate('Select Valid From Date')
  if(!toDateObj) setErrorTodate('Select Valid To Date')
    const filteredData = alldata.filterData; // Use your fetched data here.
     console.log("fiterdat ==",filteredData);

    if (filteredData.length === 0) {
      alert('No data available to export.');
      return;
    }
  
      // Prepare data for export
  const exportData = filteredData.map((item, index) => ({
    "S.NO": index + 1,
    "Camera Name": item.devicename || 'N/A',
    // "Detect Type": item. || 'N/A',
    "Event Type": item.DetectedType || 'N/A',
    "Event Time": item.EventTime ? dayjs(item.EventTime).format('YYYY-MM-DD HH:mm:ss') : 'N/A',
    "Video URL": item.VideoUrl || 'N/A',
  }));

  // Create a worksheet and workbook
  const worksheet = XLSX.utils.json_to_sheet(exportData);

  // Adjust column widths based on data
  const columnWidths = [
    { wch: 5 },  // S.NO
    { wch: 20 }, // Camera Name
    { wch: 15 }, // Event Type
    { wch: 20 }, // Event Time
    { wch: 40 }, // Video URL
  ];
  worksheet['!cols'] = columnWidths;

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Audit Data');

  // Export to Excel
  XLSX.writeFile(workbook, `${clientname}_Report_${dayjs().format('YYYY-MM-DD_HH-mm-ss')}.xlsx`);


}
  return (
    <Page title="Audits">
      <Container maxWidth="xl">
        <NotificationAlert 
        open={alertStatus.open}  
        handleClose={handleNotificationBar} 
        title={alertStatus.title} 
        description={alertStatus.description}/>
        { agentBased === '0' ?
        <Typography variant="h4"> Audits </Typography> :
        <Typography variant="h4"> Escalated Events</Typography>
        }
        {Number(userType) === 33 && (
          <Typography sx={{ fontWeight: 800 }}>Note: Monitoring hours - 10 PM to 6 AM</Typography>
        )}        
        
        <Card sx={{ mt: 2}}>


          <Stack direction="row" spacing={2} sx={{ p: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    sx={{width: "100%"}}
                    label="From Date"
                    id="from-date"
                    // viewRenderers={{
                    //   hours: renderTimeViewClock,
                    //   minutes: renderTimeViewClock,
                    //   seconds: renderTimeViewClock,
                    // }}
                    format="YYYY-MM-DD HH:mm"
                    value={fromDate}
                    onChange={handleFromDate}
                    maxDate={calculatedDay}
                    disableHighlightToday
                  />
                </LocalizationProvider>
                {errorFromdate && <Typography color="error" variant="body2" align="center">{errorFromdate}</Typography>}
              </FormControl>
            </Grid>
            
            <Grid item xs={2}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    sx={{width: "100%"}}
                    label="To Date"
                    id="to-date"
                    // viewRenderers={{
                    //   hours: renderTimeViewClock,
                    //   minutes: renderTimeViewClock,
                    //   seconds: renderTimeViewClock,
                    // }}
                    format="YYYY-MM-DD HH:mm"
                    value={toDate}
                    onChange={handleToDate}
                    minDate={fromDate}
                    maxDate={calculatedDay}
                    disableHighlightToday
                  />
                </LocalizationProvider>
                {errorTodate && <Typography color="error" variant="body2" align="center">{errorTodate}</Typography>}
              </FormControl>
            </Grid>
            
            {agentBased === '0' &&  
             <Grid item xs={3}>
                <FormControl fullWidth>
                  <Autocomplete
                  fullWidth
                  // sx={{ minWidth: 300 }}
                  multiple
                  id="detected-types"
                  options={analytics}
                  getOptionLabel={(option) => option.Name}
                  value={detectedType}
                  onChange={handleDetectedType}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.Name}>
                      {option.Name}
                    </Box>
                  )}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Detected Types"
                      placeholder="Detected Types"
                    />
                  )}
                  /> 
                </FormControl> 
              </Grid>
            }

{(clientGroupList.length > 0 ) && (
            <Grid item xs={3}>
                <Autocomplete
                    multiple
                    id="tags-outlined-groups"
                    options={clientGroupList}
                    onChange={handleGroupChange}
                    value={[]}
                    disableCloseOnSelect
                    //getOptionLabel={(option) => (option.selectall && selectedCamesList.length === activeCameras.length) ? 'Select All' : `${option.deviceid} ${option.devicename}`}
                    renderInput={(params) => (
                     <TextField
                        {...params}
                        label="Select Groups"
                        placeholder="Search Group Name..."
                      />
                    )}
                    filterOptions={handleGroupFilterOptions}                                      
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                       {option.all ? (
                          <>
                           <Checkbox
                            icon={groupicon}
                            checkedIcon={groupcheckedIcon}
                            style={{ marginRight: 8 }}
                            checked={groupCheckValue}
                            //onChange={() => setCheckValue(!checkValue)}
                          />
                        {option.title}
                      </>
                       ) : (
                      <>
                      <Checkbox
                       icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={activeGroups.includes(option.GroupId)}
                        onChange={() => {
                          const isChecked = activeGroups.includes(option.GroupId);
                          const newActiveGroups = isChecked
                            ? activeGroups.filter((groupId) => groupId !== option.GroupId)
                            : [...activeGroups, option.GroupId];
                          setActiveGroups(newActiveGroups);
                      }}
                    />
                    {`${option.GroupName}`}
                  </>
                  )}
              </li>
            )}
                                                //getOptionKey={(option) => option.deviceid}
                    isOptionEqualToValue={(option, value) => option.GroupId === value.GroupId}
                    /> 
              {errorGroup && <Typography color="error" variant="body2" align="center">{errorGroup.message}</Typography>}
            </Grid>
          )}

            <Grid item xs={3}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  fullWidth
                  id="camera"
                  
                  options={clientGroupList.length > 0 ? camerasByGroupId :cameras}
                  
                  //value={(checkValue) ? [{ title: 'Select All', all: true }] : inputValue.filter((camera) => activeCameras.includes(camera.uid))}
                  value={[]}

                  getOptionLabel={(option) => {
                    if (option.selectall) {
                      return 'Select All';
                    } else if (option.deviceid && option.devicename) {
                      return `${option.deviceid} ${option.devicename}`;
                    } else {
                      return '';
                    }
                  }}
                  
                  onChange={handleCamerasChange}
                  disableCloseOnSelect={openAuto}
                  open={openAuto}
                  onOpen={() => setOpenAuto(true)}
                  onClose={() => setOpenAuto(false)}
                  
                  // filterOptions={(options, params) => {
                  //   const filter = createFilterOptions();
                  //   const filtered = filter(options, params);
                  //   return [{ title: 'Select All', selectall: true }, ...filtered];
                  // }}

                  filterOptions={handleFilterOptions}
                  
                  renderOption={(props, option, { selected }) => (
                    
                    <li {...props}>
                      {option.selectall ? (
                        <>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={checkValue}
                            //onChange={() => setCheckValue(!checkValue)}
                          />
                          {option.title}
                        </>
                      ) : (
                        <>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={activeCameras.includes(option.uid)}
                            onChange={() => {
                              debugger
                              const isChecked = activeCameras.includes(option.uid);
                              const newActiveCameras = isChecked
                                ? activeCameras.filter((uid) => uid !== option.uid)
                                : [...activeCameras, option.uid];
                              setActiveCameras(newActiveCameras);
                            }}
                          />
                          {`${option.devicename}`}
                        </>
                      )}
                    </li>

                  
                )}
                  renderInput=
                  {(params) => 
                    <TextField {...params} label="Camera" onFocus={() => setOpenAuto(true)}/>
                  }
                />    
              </FormControl>
            </Grid>

            <Grid item xs={0.8}>
            <Button disabled={!(fromDate || toDate || detectedType.length > 0 || device.length > 0)} 
              onClick={
                handleGet
              } 
                variant='contained' size="large">Get</Button>
            </Grid>
            {clientType === 'INDIA' && 
            <Grid item xs={1}>
            <Button disabled={!(fromDate || toDate || detectedType.length > 0 || device.length > 0)} 
              onClick={
                handleExport
              } 
                variant='contained' size="large"
                >Export <RedoIcon /></Button>
            </Grid>
             } 
          </Grid>  
            </Stack>

            <Box sx={{ backgroundColor: "#ebebeb", p: 1 }}>
              <Box sx={{ textAlign: "right" }}>
                  <SearchStyle
                    // value={filterAudit}
                    onChange={onFilterAudits}
                    sx={{ backgroundColor: "white", mb: 1 }}
                    placeholder="Search..."
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                      </InputAdornment>
                    }
                  />
              </Box>

            {(alldata.isLoad) && (
              <Box sx={{display: "flex",
                alignItems: "center"}}>
                <CircularProgress sx={{ marginLeft: '45%' }} />
              </Box>
            )}            
            
            


            {alldata.filterData.length === 0  && !alldata.isLoad ? (
              <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>
               No data
              </Typography>
              ) : (
                <AuditList audits={alldata.filterData} loadMoreAudits={loadMoreAudits} updateToClient={updateToClient} isEscalating={isEscalating} clientId={clientId} />
              )}

          </Box>

        </Card>
      </Container>
    </Page>
  );
}