
import React, { useState, useEffect , useRef } from 'react';
import {
  Checkbox,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell, 
  TableContainer,
  TableHead,
  TableRow,CircularProgress,
  Button,
  Card,
  CardContent,
  TextField,
  FormControl,
  InputLabel,
  Autocomplete,
  IconButton,
  Dialog,
  DialogContent,
  Menu,Stack,
  selectClasses,
  Select,
  MenuItem,
  DialogTitle,
  createFilterOptions,
} from '@mui/material';
// import { PlayCircleFilled } from '@mui/icons-material';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DateTimePicker from '@mui/lab/DateTimePicker';
import Scrollbar from 'src/components/Scrollbar';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
//import Autocomplete from '@mui/material/Autocomplete'; // Import Autocomplete component
import { PlayCircleFilled } from '@mui/icons-material'; // Import PlayCircleFilled icon//
import { ArrowBackIos, ArrowForwardIos, Share, CloudDownload } from '@mui/icons-material';
import  { getClientEscalatedVideos } from 'src/api/clientDetails'
import { getCamerasList, getClientGroupInfo } from 'src/api/liveCameras'
import { getGroupsByClient } from 'src/api/liveCameras';
import { getCamerasByGroupID } from 'src/api/liveCameras';
import { downloadVideo, getTimezoneList } from 'src/api/analytics';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import dayjs from 'dayjs';
import {InputAdornment } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { getvideoURLMail } from 'src/api/clientDetails';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Label from 'src/components/Label';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const VideoArchives = () => {

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedCameras, setSelectedCameras] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogImage, setOpenDialogImage] = useState(false);

  // const [clientCameras,setClientCameras] = useState([]);
  const [escalatedVideos,setEscalatedVideos] = useState([]);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState('');
  const [imageURL, setImageURL] = useState([]);

  const [selectedRowInfo, setSelectedRowInfo] = useState('');

  const [isValidFrom, setIsValidFrom] = useState(false);
  const [isValidTo, setIsValidTo] = useState(false);
  const [email, setEmail] = useState('');
  const [url, setUrl] = useState('');
  const [selectedSpeed, setSelectedSpeed] = useState(3); // Initial playback speed
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [currentDeviceVideos, setCurrentDeviceVideos] = useState([]);
  const [currentDeviceIndex, setCurrentDeviceIndex] = useState(0);
  const [clientGroupList, setClientGroupsList] = useState([]);

  const [selectedGroup, setSelectedGroup] = useState("");

  const [camerasByGroupId, setCamerasByGroup] = useState([]);
  const [camerawithoutGroups, setCameraListWithoutGroups] = useState([]);
  const [archivesCamerasList, setArchivesCamerasList] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorFromdate, setFromDateErrorMessage] = useState('');
  const [errorTodate, setToErrorMessage] = useState('');
  const [initialToTime,setInitialToTime]=useState(true)
  const [checkValue,setCheckValue]=useState(false)

  const [openAuto,setOpenAuto]=useState(false)

  const [activeCameras, setActiveCameras] = useState([])
  const [inputValue, setInputValue] = useState([]);
  const videoRef = React.createRef();
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isTabledata, setIsTabledata] = useState(false);
  const [errorEmail,setErrorEmail] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [localURL,setLocalURL] = useState(false);
  const [calculatedDay, setCalculatedDay] = useState(null); 
  const [groupinfo,setGroupinfo] = useState([]);
  const [errorGroup, setErrorGroup] = useState(null);
  const [groupCheckValue,setGroupCheckValue] = useState(false)
  const [activeGroups,setActiveGroups] = useState([])

  const [videos, setVideos] = useState([
    { sno: 1, cameraName: 'Camera 1', previewUrl: 'https://www.w3schools.com/html/mov_bbb.mp4' },
  ]);

  const [tokenName, setToken] = useState(localStorage.getItem('displayname'))

  const groupicon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const groupcheckedIcon = <CheckBoxIcon fontSize="small" />;



  useEffect(() => {
    //debugger
    const timezoneId = localStorage.getItem('timezone_id')
    const fetchData = async () => {
      const response = await new Promise((resolve, reject) => {
        getTimezoneList((response) => {
         
            if (response.status === 200) {
                resolve(response);
            } else {
                reject(new Error('Failed to fetch timezone list'));
            }
        });
      });

      let data = response.data;
      let timeOffset = data.filter(item=>item.Id ===parseInt(timezoneId,10))[0].Time;

      //timeOffset = '-22:00'

      const now = new Date();
      const [sign, hours, minutes] = timeOffset.match(/([+-])(\d{2}):(\d{2})/).slice(1);
      const offsetInMinutes = (parseInt(hours) * 60 + parseInt(minutes)) * (sign === '+' ? 1 : -1);
      //debugger
      const adjustedTime = new Date(now.getTime() + offsetInMinutes * 60 * 1000);
      const adjustedDay = adjustedTime.getDate()
      const adjustedMonth = (adjustedTime.getMonth())+1; 
      const adjustedYear = adjustedTime.getFullYear();
      //console.log(adjustedDay,adjustedMonth,adjustedYear)

      const defaultFromDate = dayjs(new Date(adjustedYear, adjustedMonth - 1, adjustedDay)); 
      //setFromDate(defaultFromDate);
      console.log(adjustedTime)
      setCalculatedDay(defaultFromDate);

    }

    fetchData()
},[]);


  
  useEffect(()=>{
    if(process.env.REACT_APP_VDO === 'LOCAL'){
      setLocalURL(true);
      // console.log("string.. ")
    }
  },[])
  
  useEffect(()=>{
    getCamerasList((response)=>{
      if(response.status === 200){
        // console.log("cameras List by Client",response.data);
        if(response.data !== null){
          setCameraListWithoutGroups(response.data)
        }
      }
    })
        
  },[]);

  useEffect(() => {
    //debugger
    //console.log(selectedGroup)

    if(selectedGroup){
      getCamerasByGroupID(selectedGroup, (response) => {
        if (response.status === 200) {
          // console.log("cameras List by Group ", selectedGroup, response.data);
          if(response.data !== null) {
            setCamerasByGroup(response.data);
          }
        }
      });
    }
  }, [selectedGroup]);



  useEffect(() => {
    let clientId = localStorage.getItem('client_id')
    let groupId = 0
    let body = {clientId : clientId , groupId : groupId}
    getClientGroupInfo(body,(response) => {
      if (response.status === 200) {
        // console.log("response Data group ", response.data);
        setGroupinfo(response.data)
        const uniqueGroups = Array.from(
          new Map(response.data.map(item => [item.GroupId, { GroupId: item.GroupId, GroupName: item.GroupName }])).values()
      );       
        setClientGroupsList(uniqueGroups);
      }
    });
  }, []);

  useEffect(()=>{
    const video = document.getElementById('videoPlayer');
    if (video) {
      video.playbackRate = selectedSpeed;
    }
  },[]);
  
  const handleFromDateChange = (date) => {
    setFromDate(date);
    setIsValidFrom(!!date);
  };

  // const handleToDateChange = (date) => {
  //   setToDate(date);
  //   setIsValidTo(!!date);
  // };



const handleToDateChange1 = (newDate) => {
    // Check if the selected time is not at the end of the day
    // console.log("to date",newDate,newDate.$m)

      const dateString = newDate.$d;
      const date = new Date(dateString);

      // Extracting time components
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();

      // Formatting time
      const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

      // console.log(formattedTime); 
    // if (!newDate.isSame(newDate.endOf('day'))) {
    //     // Set the selected time as is
    //     setToDate(newDate);
    // } else {
    //     // Adjust the time to 23:59:59
    //     const adjustedDate = newDate.endOf('day');
    //     setToDate(adjustedDate);
    // }
};

const handleToDateChange = (newDate) => {
  if(initialToTime){
  const adjustedDate = newDate.endOf('day');
  setToDate(adjustedDate);
  setInitialToTime(false)
  }
  else{
    setToDate(newDate);
  }
};
// by default its not getting End time its getting start time
    // by default the value is end time but if user selects specific time then that values should consider


// const handleGroupChange = (event) => {
//   setArchivesCamerasList([]);
//   setSelectedCameras([]);
//   setCamerasByGroup([]);
//   setInputValue([])
//   setActiveCameras([])
//   setOpenAuto(false)
//   setSelectedGroup(event.target.value);
// };

const handleGroupChange = (event,val) => {
debugger
  if(event.target.value && errorGroup){
    setErrorGroup(null)
  }

  setCheckValue(false)
  const containsAllProperty = val.some(obj => obj.hasOwnProperty('all'));

  if(clientGroupList.length === activeGroups.length && containsAllProperty){
      setGroupCheckValue(false)
      setActiveGroups([])
      setCheckValue(false)
      setActiveCameras([])
      setCamerasByGroup([])
  }

  else if(containsAllProperty){
    setGroupCheckValue(true)
    let groupId = clientGroupList.map(val => val.GroupId)
    setActiveGroups(groupId)
  }

  else{
    const grpId =  val[0].GroupId
    const avail =activeGroups.length > 0 ? activeGroups.filter(item => item === grpId) : []

    if(avail.length>0){ // remove the group id
      const id = activeGroups.filter(item => item !== grpId)
      setActiveGroups(id)
      setGroupCheckValue(false)

      if(id.length === 0){
        setCheckValue(false)
        setActiveCameras([])
        setCamerasByGroup([])
      }
    }
    else{ //  add group id
      //setActiveCameras()
      setActiveGroups((id) => [...id, grpId]);

      //[...activeGroups, grpId].length === clientGroupList ? setGroupCheckValue(true) : setGroupCheckValue(false)
    }
  }


  // setLiveCamerasList([]);
  // setSelectedCameras([]);
  // setCamerasByGroup([]);  
  // setSelectedGroup(event.target.value);
  // setInputValue([])
  // setActiveCameras([])

};


useEffect (()=>{
  debugger
  if(activeGroups.length === clientGroupList.length && activeGroups.length>0){
     setGroupCheckValue(true)
  }

  if(activeGroups.length > 0){
    let cameras = groupinfo.filter(val => activeGroups.includes(val.GroupId))
    setCamerasByGroup(cameras)
    const newId = cameras.map(val => val.deviceid)
    const filArr = activeCameras.filter(val => newId.includes(val))
    console.log(filArr)
    setActiveCameras(filArr)

    if(cameras.length === filArr.length && filArr.length>0){
      setCheckValue(true)
    }
  }

  // if(camerasByGroupId.length === activeCameras.length && activeCameras.length>0){
  //   setCheckValue(true)
  // }

},[activeGroups])

  const handleSubmit1 = () => {
    // if (!isValidFrom || !isValidTo || selectedCameras.length === 0) {
    //   // Validation failed
    //   alert('Please fill in all the mandatory fields.');
    //   return;
    // }
    setIsTabledata(false)
    //debugger
    
    // const deviceIds = selectedCameras.map((camera) => {
    //   if (clientGroupList.length !== 0) {
    //     return camera.DeviceId; // Change DeviceId to deviceid
    //   } else {
    //     return camera.deviceid;
    //   }
    // });

      // Assuming fromDate is a string representing a date in some format
      // console.log('Submit button clicked', fromDate, toDate, activeCameras.length);
      // const deviceIds = selectedCameras.map(camera => camera.deviceid);
      if (activeCameras.length === 0 || fromDate === null || toDate === null) {
        let count = 0;
      
        if (fromDate === null) {
          setFromDateErrorMessage('Please Select From Date');
          count = count + 1;
        } else {
          setFromDateErrorMessage('');
        }
      
        if (toDate === null) {
          setToErrorMessage('Please Select To date');
          count = count + 1;
        } else {
          setToErrorMessage('');
        }
      
        if (activeCameras.length === 0) {
          setErrorMessage('Please Select Atleast one Camera');
          count = count + 1;
        } else {
          setErrorMessage('');
        }
      
        if (count !== 0) {
          return;
        }
      }
      
      else{
        setFromDateErrorMessage('');
        setToErrorMessage('');
        setErrorMessage('');

      // Function to check if a value is a valid date
      const isValidDate = (value) => {
        const date = new Date(value);
        return !isNaN(date.getTime());
      };

      // Function to parse string date into Date object
      const parseDate = (dateString) => {
        if (!isValidDate(dateString)) {
          console.error("Invalid date:", dateString);
          return null; // Return null if the date string is invalid
        }
        return new Date(dateString);
      };

      // Function to format date in YYYY-MM-DD HH:MM:SS format
      const formatDateTime = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      };

      // Parse fromDate string into a Date object
      const fromDateObj = parseDate(fromDate);
      const toDateObj = parseDate(toDate);
      const formattedFromDate = ''
      if (fromDateObj && toDateObj) {
        // Format fromDate
        const formattedFromDate = formatDateTime(fromDateObj);
        const formattedToDate = formatDateTime(toDateObj);
        // console.log('Formatted fromDate:', formattedFromDate ,formattedToDate ,activeCameras);
        

            const data ={
              camera_id : activeCameras,
              start_time : formattedFromDate,
              end_time : formattedToDate
            }

            getClientEscalatedVideos(data,(response)=>{
                if(response.status === 200){
                  if(response.data !== null){
                    setEscalatedVideos(response.data)
                    setIsTabledata(true)

                  }
                }
            })


      } else {
        console.error("Failed to parse fromDate:", fromDate);
      }

      }
  };


  const handleSubmit = () => {
    // if (!isValidFrom || !isValidTo || selectedCameras.length === 0) {
    //   // Validation failed
    //   alert('Please fill in all the mandatory fields.');
    //   return;
    // }
    setIsTabledata(false)
   // debugger
    
    // const deviceIds = selectedCameras.map((camera) => {
    //   if (clientGroupList.length !== 0) {
    //     return camera.DeviceId; // Change DeviceId to deviceid
    //   } else {
    //     return camera.deviceid;
    //   }
    // });

      // Assuming fromDate is a string representing a date in some format
      // console.log('Submit button clicked', fromDate, toDate, activeCameras.length);
      // const deviceIds = selectedCameras.map(camera => camera.deviceid);
      if (fromDate === null || toDate === null) {
        let count = 0;
      
        if (fromDate === null) {
          setFromDateErrorMessage('Please Select From Date');
          count = count + 1;
        } else {
          setFromDateErrorMessage('');
        }
      
        if (toDate === null) {
          setToErrorMessage('Please Select To date');
          count = count + 1;
        } else {
          setToErrorMessage('');
        }
      
        // if (activeCameras.length === 0) {
        //   setErrorMessage('Please Select Atleast one Camera');
        //   count = count + 1;
        // } else {
        //   setErrorMessage('');
        // }
      
        if (count !== 0) {
          return;
        }
      }
      
      else{
        setFromDateErrorMessage('');
        setToErrorMessage('');
        setErrorMessage('');
        setLoadingSubmit(true);
      // Function to check if a value is a valid date
      const isValidDate = (value) => {
        const date = new Date(value);
        return !isNaN(date.getTime());
      };

      // Function to parse string date into Date object
      const parseDate = (dateString) => {
        if (!isValidDate(dateString)) {
          console.error("Invalid date:", dateString);
          return null; // Return null if the date string is invalid
        }
        return new Date(dateString);
      };

      // Function to format date in YYYY-MM-DD HH:MM:SS format
      const formatDateTime = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      };

      // Parse fromDate string into a Date object
      const fromDateObj = parseDate(fromDate);
      const toDateObj = parseDate(toDate);
      const formattedFromDate = ''
      if (fromDateObj && toDateObj) {
        // Format fromDate
        const formattedFromDate = formatDateTime(fromDateObj);
        const formattedToDate = formatDateTime(toDateObj);
        // console.log('Formatted fromDate:', formattedFromDate ,formattedToDate ,activeCameras);
        

            const data ={
              camera_id : activeCameras? activeCameras : '',
              start_time : formattedFromDate,
              end_time : formattedToDate
            }

            getClientEscalatedVideos(data,(response)=>{
                if(response.status === 200){
                  if(response.data !== null){
                    setEscalatedVideos(response.data)
                    setIsTabledata(true)
                    setLoadingSubmit(false);


                  }
                }
            })


      } else {
        console.error("Failed to parse fromDate:", fromDate);
      }

      }
  };
// Dailog pop up related

  // const handlePlayVideo = (url) => {
  //   setSelectedVideoUrl(url);
  //   setOpenDialog(true);
  // };

  const handlePlayImage = (item) => {
   // console.log("image details ",item);
    setImageURL(item);
    setOpenDialogImage(true);
  };



  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // const handlePlayVideo = (videos, index) => {
  //   setCurrentDeviceVideos(videos);
  //   setCurrentDeviceIndex(index);
  //   setOpenDialog(true);
  // };

  const handlePlayVideo = (deviceID) => {
    setEmail('')
    setErrorEmail('');

    const deviceVideos = escalatedVideos.filter(item => item.deviceid === deviceID);

    // console.log("start to play ",deviceVideos,currentDeviceIndex);
    setCurrentDeviceVideos(deviceVideos);
    setCurrentDeviceIndex(0); // Reset index when a new device is selected
    setOpenDialog(true);
  };


  
  const handleGroupFilterOptions = (options, params) => {
    //debugger
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    //return [{ title: 'Select All', all: true }, ...sortedOptions];
    return [{ title: 'Select All', all: true }, ...filtered];
  };


  useEffect(() => {
    //debugger
    const video = document.getElementById("videoPlayer");
    if (video) {
      video.playbackRate = selectedSpeed;
    }
  }, [selectedSpeed]);
  
  const handlePrevious = () => {
    setEmail('')
    setErrorEmail('');

    if (currentDeviceIndex > 0) {
      setCurrentDeviceIndex(currentDeviceIndex - 1);
    }
  };

  const handleNext = () => {
    setEmail('')
    setErrorEmail('');
    if (currentDeviceIndex < currentDeviceVideos.length - 1) {
      setCurrentDeviceIndex(currentDeviceIndex + 1);
    }
  };

  const handleVideoEnded = () => {
   // debugger
    if (currentDeviceIndex < currentDeviceVideos.length - 1) {
      setCurrentDeviceIndex(currentDeviceIndex + 1);
      setSelectedSpeed(selectedSpeed)
    }
  }

  const handleVideoLoaded = ()=>{
  //  debugger
    const video = document.getElementById("videoPlayer");
    if (video) {
      video.playbackRate = selectedSpeed;
    }
  }

  // Function to handle email input change
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setErrorEmail('');
  };

  // Function to handle URL input change
  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };






  const handleShare = () => {
    if (email) {
  
      //  console.log("email",email,currentDeviceVideos[currentDeviceIndex].play_url,tokenName)
        const body ={
            to : email,
            client_name :tokenName,
            video_url :currentDeviceVideos[currentDeviceIndex].play_url
        }
        getvideoURLMail(body,(response) => {
            if(response.status === 200){
              //  console.log("response email",response.data);
            }
        })

      } else {
        setErrorEmail('Please enter an email address.')
        // alert('Please enter an email address.');
      }


 
}

  const handleSpeedChange = (event) => {
    setSelectedSpeed(event.target.value);
    if (videoRef.current) {
      videoRef.current.playbackRate = event.target.value;
    }
  };
  const handleChangeSpeed = (event) => {
    // Handle speed change here
    // console.log(event.target.value); // You can access the selected speed here
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClose=(event)=>{
    setOpenDialog(false);
    setOpenDialogImage(false);
    setSelectedSpeed(3);
    setEmail('')
    setErrorEmail('');

  }
  const uniqueEscalatedVideos = escalatedVideos.filter((item, index, self) =>
  index === self.findIndex((t) => (
    t.deviceid === item.deviceid
  ))
);

  const handleDownload = (videoUrl) => {
    // console.log("video URL",videoUrl);
    setLoading(true);
    setTimeout(() => {
        setLoading(false);
      }, 3000);
    downloadVideo(videoUrl,(response)=>{
     // console.log(response)
      setLoading(false);

    })

  };

  const handleCameraChange = (event,newValue)=>{
   // debugger
    //console.log(clientGroupList)
    let lengthofCameras=clientGroupList.length?camerasByGroupId.length:camerawithoutGroups.length
    let cameraValues=clientGroupList.length?camerasByGroupId:camerawithoutGroups
              if (!newValue || newValue.length === 0) {
                setArchivesCamerasList([]); 
              }
            
              if (newValue.length > 1 && newValue.every((entry, index, arr) => JSON.stringify(entry) === JSON.stringify(arr[0]))) {
                newValue = [];
              }
              
              const containsAllProperty = newValue.some(obj => obj.hasOwnProperty('all'));

              if(containsAllProperty){
                if(newValue.length==1){
                  //camerasByGroupId
                  //let val=clientGroupList.length?camerasByGroupId:camerawithoutGroups
                  setSelectedCameras(cameraValues);              
                }
                else{
                  let val = newValue.filter(item => !item.all)
                  setSelectedCameras(val)
                }               
              }
            
            
              if (newValue.length === 0 || !containsAllProperty) {
                setCheckValue(false);
              }
            
              let actCameras = [];
            
              if (newValue.length > 0 && !containsAllProperty) {
                newValue.forEach((row) => {
                  clientGroupList.length?actCameras.push(row.deviceid):actCameras.push(row.deviceid)
                });

                //let lenVal=clientGroupList.length?camerasByGroupId.length:camerawithoutGroups.length
                if (lengthofCameras === actCameras.length) {
                  setCheckValue(true);
                  //setOpenAuto(false);
                }
            
                setActiveCameras([...actCameras]);
                setInputValue([...newValue]);
              }
            
              if (newValue.length > 0 && containsAllProperty) {
                
                let Cameras =cameraValues;
            
                actCameras = [];
                
                //let samplVal=clientGroupList.length?camerasByGroupId.length:camerawithoutGroups.length
                if (activeCameras && activeCameras.length === lengthofCameras) {
                  setCheckValue(false);
                  let deviceIds =[] 
                  
                  if(clientGroupList.length){
                  deviceIds=newValue.filter(obj => !obj.hasOwnProperty('all')).map(obj => obj.deviceid);
                  Cameras = Cameras.filter(item => !deviceIds.includes(item.deviceid));
                  Cameras.forEach((row) => {
                    actCameras.push(row.deviceid);
                  });
                  setInputValue(camerasByGroupId);
                  }
                  else{
                    deviceIds=newValue.filter(obj => !obj.hasOwnProperty('all')).map(obj => obj.deviceid);
                    Cameras = Cameras.filter(item => !deviceIds.includes(item.deviceid));
                    Cameras.forEach((row) => {
                      actCameras.push(row.deviceid);
                    });
                    setInputValue(camerawithoutGroups);
                  }

                 
                  setActiveCameras([...actCameras]);
                 
                  //setOpenAuto(false);
                  return;
                }
               
                if (!checkValue) {
                 
                  if(clientGroupList.length){
                    Cameras.forEach((row) => {
                      actCameras.push(row.deviceid);
                    });
                    setActiveCameras([...actCameras]);
                    setInputValue(camerasByGroupId);
                    setCheckValue(true);
                  }
                 else{
                  Cameras.forEach((row) => {
                    actCameras.push(row.deviceid);
                  });
                  setActiveCameras([...actCameras]);
                  setInputValue(camerawithoutGroups);
                  setCheckValue(true);
                 }
                } else {
                  setActiveCameras([]);
                  setInputValue([]);
                }
              } else if (newValue.length === 0) {
                setActiveCameras([]);
                setCheckValue(false);
                setInputValue([]);
              }
  }
 
  useEffect(() => {
    setSelectedSpeed(3); // Whenever currentDeviceIndex changes, set speed to 3x
  }, [currentDeviceIndex]);
 

 //console.log("selcetd active cams",uniqueEscalatedVideos)
  const filterOptions = (options, params) => {
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    
    // Separate selected and unselected options
    const selectedOptions = filtered.filter(option => activeCameras.includes(option.deviceid));
    const unselectedOptions = filtered.filter(option => !activeCameras.includes(option.deviceid));
    
    // Sort options: selected options first, then unselected
    const sortedOptions = [...selectedOptions, ...unselectedOptions];

    return [{ title: 'Select All', all: true }, ...filtered];
  };




  return (
    <>
  <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
    <Typography variant="h4" gutterBottom>Video Archives</Typography>
  </Stack>
    <Card variant="outlined">

      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={2}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="From Date"
                  id="from-date"
                  format="YYYY-MM-DD HH:mm"
                  value={fromDate}
                  required={true}
                  onChange={handleFromDateChange}
                  sx={{ border: 'none' }}
                  //maxDate={dayjs()}
                  maxDate={calculatedDay}
                  disableHighlightToday
                  //minDate={calculatedDay}
                  ampm={true}
                />
              </LocalizationProvider>
              {errorFromdate && <Typography color="error" variant="body2" align="center">{errorFromdate}</Typography>}

            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="To Date"
                  id="to-date"
                  required
                  format="YYYY-MM-DD HH:mm"
                  value={toDate}
                  onChange={handleToDateChange}
                  sx={{ border: 'none' }}
                  //minDate={fromDate}
                  maxDate={calculatedDay}
                  disableHighlightToday
                  //maxDate={dayjs().subtract(1, 'day')}
                />
              </LocalizationProvider>
              {errorTodate && <Typography color="error" variant="body2" align="center">{errorTodate}</Typography>}

            </FormControl>

          </Grid>
          
          
          {/* {clientGroupList.length > 0 && (
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="group-label">Select Groups</InputLabel>
                <Select
                  labelId="group-label"
                  value={selectedGroup}
                  onChange={handleGroupChange}
                  label="Group"
                >
                  {clientGroupList.map((group) => (
                    <MenuItem key={group.Id} value={group.Id}>
                      {group.GroupName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )} */}

          {(clientGroupList.length > 0 ) && (
            <Grid item xs={3}>
                <Autocomplete
                    multiple
                    id="tags-outlined-groups"
                    options={clientGroupList}
                    onChange={handleGroupChange}
                    value={[]}
                    disableCloseOnSelect
                    //getOptionLabel={(option) => (option.selectall && selectedCamesList.length === activeCameras.length) ? 'Select All' : `${option.deviceid} ${option.devicename}`}
                    renderInput={(params) => (
                     <TextField
                        {...params}
                        label="Select Groups"
                        placeholder="Search Group Name..."
                      />
                    )}
                    filterOptions={handleGroupFilterOptions}                                      
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                       {option.all ? (
                          <>
                           <Checkbox
                            icon={groupicon}
                            checkedIcon={groupcheckedIcon}
                            style={{ marginRight: 8 }}
                            checked={groupCheckValue}
                            //onChange={() => setCheckValue(!checkValue)}
                          />
                        {option.title}
                      </>
                       ) : (
                      <>
                      <Checkbox
                       icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={activeGroups.includes(option.GroupId)}
                        onChange={() => {
                          const isChecked = activeGroups.includes(option.GroupId);
                          const newActiveGroups = isChecked
                            ? activeGroups.filter((groupId) => groupId !== option.GroupId)
                            : [...activeGroups, option.GroupId];
                          setActiveGroups(newActiveGroups);
                      }}
                    />
                    {`${option.GroupName}`}
                  </>
                  )}
              </li>
            )}
                                                //getOptionKey={(option) => option.deviceid}
                    isOptionEqualToValue={(option, value) => option.GroupId === value.GroupId}
                    /> 
              {errorGroup && <Typography color="error" variant="body2" align="center">{errorGroup.message}</Typography>}
            </Grid>
          )}



        <Grid item xs={clientGroupList.length > 0 ? 3 : 3}>
          <Autocomplete
            multiple
            id="tags-outlined-cameras"
            options={
              clientGroupList.length > 0
                ? camerasByGroupId
                : camerawithoutGroups
            } 
            //value={selectedCameras}  
            value={(checkValue) ? [{ title: 'Select All', all: true }] : inputValue.filter((camera) =>clientGroupList.length?activeCameras.includes(camera.deviceid):activeCameras.includes(camera.deviceid) )}
            onChange={handleCameraChange}

            //disableCloseOnSelect={openAuto}
            disableCloseOnSelect
            open={openAuto}
            onOpen={() => setOpenAuto(true)}
            onClose={() => setOpenAuto(false)}
            filterOptions={filterOptions}
            getOptionLabel={(option) => {
              const options =
                clientGroupList.length > 0
                  ? camerasByGroupId
                  : camerawithoutGroups;
              return options === camerawithoutGroups
                ? option.all ? 'Select All' : `${option.deviceid} ${option.devicename}`
                : option.all ? 'Select All' : `${option.deviceid} ${option.devicename}`;
            }}
            
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                {option.all ? (
                  <>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={checkValue}
                      onChange={() => setCheckValue(!checkValue)}
                    />
                    {option.title}
                  </>
                ) : option.deviceid ? ( // no group cameras
                  <>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={activeCameras.includes(option.deviceid)}
                      onChange={() => {
                        const isChecked = activeCameras.includes(option.deviceid);
                        const newActiveCameras = isChecked
                          ? activeCameras.filter((deviceId) => deviceId !== option.deviceid)
                          : [...activeCameras, option.deviceid];
                        setActiveCameras(newActiveCameras);
                      }}
                    />
                    {`${option.devicename}`}
                  </>
                ) : ( // group cameras
                  <>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={activeCameras.includes(option.deviceid)}
                    onChange={() => {
                      const isChecked = activeCameras.includes(option.deviceid);
                      const newActiveCameras = isChecked
                        ? activeCameras.filter((deviceId) => deviceId !== option.deviceid)
                        : [...activeCameras, option.deviceid];
                      setActiveCameras(newActiveCameras);
                    }}
                  />
                  {`${option.devicename}`}
                </>
                )
                
                }
              </li>
            )}
            // onChange={(event, newValue) => {
            //   setSelectedCameras(newValue);
            //   if (!newValue || newValue.length === 0) {
            //     setArchivesCamerasList([]); // Clear Archives cameras list when no cameras selected
            //   }
            // }}
            onInputChange={(event, newInputValue) => {
              if (!newInputValue) {
                setArchivesCamerasList([]); // Clear Archives cameras list when input value is empty
              }
            }}
            // filterOptions={(options, params) => {
            //   const filter = createFilterOptions();
            //   const filtered = filter(options, params);
            //   return [{ title: 'Select All', all: true }, ...filtered];
            // }}
            
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Camera"
                placeholder="Search Camera ID Or Name..."
              />
            )}
            renderTags={() => null} 
            clearOnBlur={true} // Clear the value when Autocomplete loses focus
          />
          {errorMessage && <Typography color="error" variant="body2" align="center">{errorMessage}</Typography>}

        </Grid>

          {/* <Grid item xs={3}>
          <Autocomplete
              multiple
              options={clientCameras}
              getOptionLabel={(option) => `${option.deviceid} ${option.devicename}`}
              onChange={(event, value) => setSelectedCameras(value)}
              value={selectedCameras}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="select-cameras"
                  label="Select Cameras"
                  variant="outlined"
                />
              )}
            />
          </Grid> */}

          <Grid item xs={2}>
          {/* <Button  color="error" variant="contained" onClick={handleSubmit}>Clear</Button> */}

            {/* <Button variant="contained" onClick={handleSubmit}>Submit</Button> */}

            <Button
                        disabled={loadingSubmit}
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        startIcon={loadingSubmit && <CircularProgress size={22} />}
                      >
                        {loadingSubmit ? "Loading..." : "Submit"}
                      </Button>

          </Grid>
        </Grid>

        {/* Table with video previews */}
        <Grid container spacing={2} alignItems="center" sx={{ mt: 2 }}>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
                  <TableCell>S.No</TableCell>
                  <TableCell>Preview</TableCell>
                  <TableCell>Camera Name</TableCell>
                  <TableCell>Play</TableCell>
                </TableRow>
              </TableHead>
              {/* {uniqueEscalatedVideos.length > 0  (
              <TableBody>
                {uniqueEscalatedVideos.map((item,index) => (
                  <TableRow key={index}>
                    <TableCell>{index+1}</TableCell>
                    <TableCell>
                    <img
                      src={item.snapshot_url}
                      alt={`Image NotFound `}
                      style={{ maxWidth: '50px', maxHeight: '100px', cursor: 'pointer' }}
                      onClick={() => handlePlayImage(item)}
                    />

                    </TableCell>
                    <TableCell>{item.devicename}</TableCell>
                    <TableCell>
                    <IconButton color="white" aria-label="play" onClick={() => handlePlayVideo(item.deviceid)}>
                      <PlayCircleFilled sx={{ fontSize: 36 }} />
                      <InputLabel htmlFor="Play">Play</InputLabel>
                    </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              ):(
                    {escalatedVideos.length != 0 (
                    <TableBody>
                    <TableRow>
                        <TableCell colSpan={5}>No Data Availabe for selected Camera</TableCell>
                    </TableRow>
                    </TableBody>
                      ):(
                        <TableRow>
                            <TableCell colSpan={5}> </TableCell>
                        </TableRow>
          
                      )}
              )} */}

<TableBody>
  {uniqueEscalatedVideos.length > 0 ? (
    uniqueEscalatedVideos.map((item, index) => (
      <TableRow key={index}>
        <TableCell>{index + 1}</TableCell>
        <TableCell>
          <img
            src={localURL ? item.local_snapshot_url : item.snapshot_url}
            alt={`Image NotFound`}
            style={{ maxWidth: '50px', maxHeight: '100px', cursor: 'pointer' }}
            onClick={() => handlePlayImage(item)}
          />
        </TableCell>
        <TableCell>{item.devicename}</TableCell>
        <TableCell>
          <IconButton color="white" aria-label="play" onClick={() => handlePlayVideo(item.deviceid)}>
            <PlayCircleFilled sx={{ fontSize: 36 }} />
            {/* <InputLabel htmlFor="Play">Play</InputLabel> */}
          </IconButton>
        </TableCell>
      </TableRow>
    ))
  ) :
  uniqueEscalatedVideos.length === 0  && isTabledata ?  (
    <TableRow>
        <TableCell colSpan={5} align="center">
          No Data 
        </TableCell>
    </TableRow>
  ) : 
  (
    <TableRow>
      <TableCell colSpan={5} align="center">  </TableCell>
    </TableRow>
  )}
</TableBody>

            </Table>
          </TableContainer>
        </Grid>



      <Dialog
          open={openDialog}
          onClose={() => {
            setSelectedSpeed(1)
            setOpenDialog(false)
          }}
          maxWidth={false}   
          PaperProps={{
            style: {
              width: "35%", // Adjust the width as needed
              maxWidth: "none" // Remove the maximum width limit
            }
          }}
          >
          <DialogContent  >
          {currentDeviceVideos.length > 0 &&
                        currentDeviceVideos[currentDeviceIndex] && (
            <div style={{ display: "flex", flexDirection: "column", width : "100%" }}>
              {/* Video Player */}
              <div style={{ position: "relative", paddingBottom: "56.25%", paddingTop: 25, height: 0 }}>
                <video
                  controls
                  id="videoPlayer"
                  key={currentDeviceIndex}
                  ref={videoRef}
                  style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                  onEnded={handleVideoEnded}
                  onLoadedMetadata={handleVideoLoaded} 
                  autoPlay
                >
                    <source
                                src={ localURL ? currentDeviceVideos[currentDeviceIndex].local_play_url:currentDeviceVideos[currentDeviceIndex].play_url}
                                type="video/mp4"
                              />        </video>
              </div>

              {/* Speed Selector */}
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "1rem" }}>
                <FormControl style={{ minWidth: "10%" }}>
                  <InputLabel id="speed-label">Speed</InputLabel>
                  <Select
                    label="Speed"
                    labelId="speed-label"
                    value={selectedSpeed}
                    onChange={handleSpeedChange}
                    variant="outlined"
                    size="large"
                    style={{ minWidth: 80 }}
                  >
                    <MenuItem value={1}>1x</MenuItem>
                    <MenuItem value={2}>2x</MenuItem>
                    <MenuItem value={3}>3x</MenuItem>
                    <MenuItem value={5}>5x</MenuItem>
                    <MenuItem value={10}>10x</MenuItem>
                  </Select>
                </FormControl>

                {/* Previous and Next Buttons */}
                <div style={{ display: "flex" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePrevious}
                    style={{ marginRight: "0.5rem" }}
                    startIcon={<ArrowBackIos />}
                    disabled={currentDeviceIndex === 0}
                  >Previous</Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    startIcon={<ArrowForwardIos />}
                    disabled={currentDeviceIndex === currentDeviceVideos.length - 1}
                  >Next</Button>
                </div>
              </div>

              {/* URL TextField */}
              {/* <TextField
                label="URL"
                variant="outlined"
                margin="normal"
                value={currentDeviceVideos[currentDeviceIndex].play_url}
                onChange={handleUrlChange}
                fullWidth
              /> */}


<TextField
                id="videoLink"
                style={{ width: "100%", height: 50, mt: 4 }}
                value={localURL ? currentDeviceVideos[currentDeviceIndex].local_play_url:currentDeviceVideos[currentDeviceIndex].play_url}
                InputProps={{
                  style: {
                    fontSize: "0.9rem",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {/* <IconButton onClick={handleCopy} edge="end"> */}
                      <CopyToClipboard text={localURL ? currentDeviceVideos[currentDeviceIndex].local_play_url:currentDeviceVideos[currentDeviceIndex].play_url}
                       onCopy={() => {
                        setCopied(true);
                        setTimeout(() => setCopied(false), 2000);
                      }}
                      >
                        <IconButton edge="end">
                          <ContentCopyIcon />
                        </IconButton>
                      </CopyToClipboard>
                    </InputAdornment>
                  ),
                }}
          />
          <Stack direction='row' sx={{ pt: 2 }}>
                    {copied && <Typography variant='body2' color='success.main' sx={{ pl: 1 }}>Copied!</Typography>}
              
          </Stack>



              {/* Email TextField and Buttons */}
              {/* <div style={{ display: "flex", alignItems: "center" }}>
                <TextField
                  label="Email"
                  variant="outlined"
                  margin="normal"
                  value={email}
                  onChange={handleEmailChange}
                  fullWidth


                />
              {errorEmail && <Typography color="error" variant="body2" align="center">{errorEmail}</Typography>}

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleShare}
                  startIcon={<Share />}
                  style={{ marginLeft: "0.5rem" }}
                ></Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleDownload(currentDeviceVideos[currentDeviceIndex].play_url)}
                  style={{ marginLeft: "0.5rem" }}
                  startIcon={!loading ? <CloudDownload /> : <CircularProgress size={22} color="inherit" /> }
                ></Button>
              </div> */}

              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
  <TextField
    label="Email"
    variant="outlined"
    margin="normal"
    value={email}
    onChange={handleEmailChange}
    fullWidth
  />
  {errorEmail && (
    <Typography color="error" variant="body2" align="center" style={{ marginTop: "0.5rem" }}>
      {errorEmail}
    </Typography>
  )}

  {/* <div style={{ display: "flex", marginTop: "1rem" }}>
    <Button
      variant="contained"
      color="primary"
      onClick={handleShare}
      startIcon={<Share />}
      style={{ marginLeft: "0.5rem" }}
    >
      Share
    </Button>
    <Button
      variant="contained"
      color="primary"
      onClick={() => handleDownload(currentDeviceVideos[currentDeviceIndex].play_url)}
      style={{ marginLeft: "0.5rem" }}
      startIcon={!loading ? <CloudDownload /> : <CircularProgress size={22} color="inherit" />}
    >
      Download
    </Button>
  </div> */}
</div>

              
                    {/* Close Button */}
              <div style={{ textAlign: "right", marginTop: "1rem" }}>
              <Button
                  variant="contained"
                  color="primary"
                  onClick={handleShare}
                  startIcon={<Share />}
                  style={{ marginLeft: "0.5rem" }}
                >
                  Share
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleDownload(currentDeviceVideos[currentDeviceIndex].play_url)}
                  style={{ marginLeft: "0.5rem" }}
                  startIcon={!loading ? <CloudDownload /> : <CircularProgress size={22} color="inherit" />}
                >
                  Download
                </Button>

                <Button color="error" variant="contained"  style={{ marginLeft: "0.5rem" }} onClick={handleClose}>Close</Button>
              </div>
            </div>
            )}
          </DialogContent>
          
        </Dialog>


        <Dialog open={openDialogImage} onClose={() => setOpenDialogImage(false)} maxWidth="lg">
        <DialogContent>
          <img src={imageURL.snapshot_url} alt="Preview" style={{ width: '100%', height: 'auto' }} />

          <Grid container spacing={2} alignItems="center" justifyContent="space-between" style={{ marginTop: "0.5rem" }}>
            <Grid item>
              <Typography><b>Camera Name :</b> {imageURL.devicename}</Typography>
            </Grid>
            <Grid item>
              <Button color="error" variant="contained" onClick={handleClose}>Close</Button>
            </Grid>
          </Grid>

        </DialogContent>
      </Dialog>

      
      </CardContent>
    </Card>
    </>

  );
};

export default VideoArchives;