import { useRef, useState } from 'react';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

export default function ServerMoreMenu({ row, setDetailBoxStatus, handleAlertOpen, updateServerDetails }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const manageOptionsSelection = (details, actionType) => {
    setIsOpen(false)
    if (actionType === 'edit') {
      updateServerDetails(details)
    } else if (actionType === 'details') {
      setDetailBoxStatus({isOpen: true, data: row})
    } else if (['status', 'delete'].includes(actionType)) {
      handleAlertOpen(row, actionType)
    }
  }

  return (
    <>
      <IconButton 
      ref={ref} 
      onClick={() => setIsOpen(true)}
      aria-controls={isOpen ? 'menu-options' : undefined}
      aria-haspopup="true"
      aria-expanded={isOpen ? 'true' : 'false'}
      >
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        id="menu-options"
        role="menu"
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => {setIsOpen(false); ref.current?.focus();}}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >

        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => manageOptionsSelection(row, 'details')} >
          <ListItemIcon>
            <Iconify icon="eva:menu-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="More Details" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem to="#" sx={{ color: 'text.secondary' }} onClick={() => manageOptionsSelection(row, 'edit')}>
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem sx={{ color: 'text.primary' }} onClick={() => manageOptionsSelection(row, 'status')} >
          <ListItemIcon>
            <Iconify icon="eva:flip-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Change Status" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem sx={{ color: 'text.error' }} onClick={() => manageOptionsSelection(row, 'delete')} >
          <ListItemIcon>
            <Iconify sx={{color: "red"}} icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText sx={{color: "red"}} primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

      </Menu>
    </>
  );
}
