import React, { useState, useEffect, useRef } from 'react';

import {
  Grid,
  Autocomplete,
  Button,
  TextField,
  Typography,
  FormControl,
  DialogActions,
  Stack,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableHead,
  TableContainer, TablePagination, Toolbar, OutlinedInput, Container,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Tooltip,
  Box,
  DialogContent,
  DialogTitle,
  Dialog
} from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { getGroupsByClient, getEscStatus, InsertUpdateEscStatus, getClientGroupInfo } from 'src/api/liveCameras';
import { getDeviceStats } from 'src/api/DashboardDev';
import BarGraph from 'src/sections/reports/BarGraph';
import dayjs from 'dayjs';
import { CheckBox } from '@mui/icons-material';
import client from 'src/api/clients';
import { getTimezoneList } from 'src/api/analytics';
import InfoIcon from '@mui/icons-material/Info';
import { LoadingButton } from '@mui/lab';

export default function DashBoardDepApp({ clientId }) {

  //console.log(clientId)

  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedMonth, setSelectedmonth] = useState('');
  const [setYear, setYearSelected] = useState('');
  const [fromDate, setFromDate] = useState(dayjs().startOf('month').hour(0).minute(0))
  const [toDate, setToDate] = useState(dayjs().endOf('day'))
  const [deviceStats, setDeviceStats] = useState([]);
  const [fromDateError, setFromDateErrorMsg] = useState('');
  const [toDateError, setToDateErrorMsg] = useState('');
  const [initialToTime, setInitialToTime] = useState(true)
  const [escStatus, setEscStatus] = useState(true)
  const [calculatedDay, setCalculatedDay] = useState(null);
  const [id, setId] = useState('')
  const [confirmation, setConfirmation] = useState(false)


  const months = [
    { name: 'January', value: '1' },
    { name: 'February', value: '2' },
    { name: 'March', value: '3' },
    { name: 'April', value: '4' },
    { name: 'May', value: '5' },
    { name: 'June', value: '6' },
    { name: 'July', value: '7' },
    { name: 'August', value: '8' },
    { name: 'September', value: '9' },
    { name: 'October', value: '10' },
    { name: 'November', value: '11' },
    { name: 'December', value: '12' }
  ];
  const currentYear = new Date().getFullYear();
  const years = //[currentYear - 1,currentYear,currentYear + 1];
    [
      { name: (currentYear - 1).toString(), value: currentYear - 1 },
      { name: (currentYear).toString(), value: currentYear },
      { name: (currentYear + 1).toString(), value: currentYear + 1 }
    ]



  // Use Effect - API Call

  useEffect(() => {
    ////debugger
    const timezoneId = localStorage.getItem('timezone_id')
    const fetchData = async () => {
      const response = await new Promise((resolve, reject) => {
        getTimezoneList((response) => {

          if (response.status === 200) {
            resolve(response);
          } else {
            reject(new Error('Failed to fetch timezone list'));
          }
        });
      });

      let data = response.data;
      let timeOffset = data.filter(item => item.Id === parseInt(timezoneId, 10))[0].Time;
      // let timeOffset = '-22:30'

      const now = new Date();
      const [sign, hours, minutes] = timeOffset.match(/([+-])(\d{2}):(\d{2})/).slice(1);
      const offsetInMinutes = (parseInt(hours) * 60 + parseInt(minutes)) * (sign === '+' ? 1 : -1);
      ////debugger
      const adjustedTime = new Date(now.getTime() + offsetInMinutes * 60 * 1000);
      const adjustedDay = adjustedTime.getDate()
      const adjustedMonth = (adjustedTime.getMonth()) + 1;
      const adjustedYear = adjustedTime.getFullYear();
      //console.log(adjustedDay,adjustedMonth,adjustedYear)

      const defaultFromDate = dayjs(new Date(adjustedYear, adjustedMonth - 1, adjustedDay));
      //setFromDate(defaultFromDate);
      console.log(adjustedTime)
      setCalculatedDay(defaultFromDate);

    }

    fetchData()
  }, []);


  useEffect(() => {
    debugger
    const id = localStorage.getItem('ID')
    setId(id);
    setSelectedmonth(null);
    setYearSelected(null);
    
    // getGroupsByClient('', (response) => {
    //   if (response.status) {
    //     if (response.data !== null) {
    //       setGroups(response.data)
    //     }
    //   }
    // })

    let body = {groupId:0,clientId:id}
    getClientGroupInfo(body,(response)=>{    
      if(response.status === 200){       
        if(response.data.length>0){
          //setIsGroupExist(true)
          const uniqueResult = Array.from(
            new Map(response.data.map(item => [`${item.GroupId}-${item.GroupName}`, item])).values()
            ).map(item => ({
                GroupId: item.GroupId,
                GroupName: item.GroupName
            }));       
            setGroups(uniqueResult)
        }
        else{

        }
      }
    })

    if (id) {
      getEscStatus(id, (response) => {
        //debugger
        if (response.status === 200) {
          // let escStatus = response.data[0].EscalationStatus

          if (response.data.length > 0) {
            let escStatus = response.data[0] ? response.data[0].EscalationStatus : ''
            if (escStatus === 0) {
              setEscStatus(false)
            }
            else if (escStatus === 1) {
              setEscStatus(true)
            }
          }
          else {
            let body = { ClientId: parseInt(id, 10), EscalationStatus: 1 }

            InsertUpdateEscStatus(body, (response) => {
              console.log(response);
            })
          }
        }
      })
    }


    // else{
    //   //debugger
    //   const id = localStorage.getItem('ID')

    //   let body = {ClientId:parseInt(id,10),EscalationStatus:1}

    //   InsertUpdateEscStatus(body,(response)=>{
    //     console.log(response);
    //   })
    // }


  }, [])


  useEffect(() => {
    const timezoneId = localStorage.getItem('timezone_id')

    let fromDatefor = fromDate;
    let toDatefor = toDate;

    const fetchData = async () => {

      try {
        const response = await new Promise((resolve, reject) => {
          getTimezoneList((response) => {

            if (response.status === 200) {
              resolve(response);
            } else {
              reject(new Error('Failed to fetch timezone list'));
            }
          });
        });

        let data = response.data;
        let timeOffset = data.filter(item => item.Id === parseInt(timezoneId, 10))[0].Time;

        //timeOffset='-16:30';

        const now = new Date();
        //console.log(now)

        const [sign, hours, minutes] = timeOffset.match(/([+-])(\d{1,2}):(\d{2})/).slice(1);
        const offsetInMinutes = (parseInt(hours) * 60 + parseInt(minutes)) * (sign === '+' ? 1 : -1);

        const adjustedTime = new Date(now.getTime() + offsetInMinutes * 60 * 1000);

        console.log(now, adjustedTime)
        console.log(adjustedTime.getTime())
        console.log(now.getTime())

        if (now.getDate() !== adjustedTime.getDate()) {
          const adjustedDay = adjustedTime.getDate();
          const adjustedMonth = adjustedTime.getMonth();
          const adjustedYear = adjustedTime.getFullYear();

          setFromDate(dayjs(new Date(adjustedYear, adjustedMonth, adjustedDay)).startOf('month').hour(0).minute(0));
          setToDate(dayjs(new Date(adjustedYear, adjustedMonth, adjustedDay)).endOf('day'));

          fromDatefor = dayjs(new Date(adjustedYear, adjustedMonth, adjustedDay)).startOf('month').hour(0).minute(0);
          toDatefor = dayjs(new Date(adjustedYear, adjustedMonth, adjustedDay)).endOf('day');
        }

        const nowHours = now.getHours();
        const nowMinutes = now.getMinutes();
        const adjustedHours = adjustedTime.getHours();
        const adjustedMinutes = adjustedTime.getMinutes();

        if (nowHours !== adjustedHours || nowMinutes !== adjustedMinutes) {
          const adjTimeValue = dayjs(adjustedTime)
          setToDate(adjTimeValue);
          toDatefor = adjTimeValue;
        }

        const isValidDate = (value) => {
          const date = new Date(value);
          return !isNaN(date.getTime());
        };

        const parseDate = (dateString) => {
          if (!isValidDate(dateString)) {
            console.error("Invalid date:", dateString);
            return null; // Return null if the date string is invalid
          }
          return new Date(dateString);
        };

        const formatDateTime = (date) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          const seconds = String(date.getSeconds()).padStart(2, '0');

          return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        const fromDateObj = parseDate(fromDatefor);
        const toDateObj = parseDate(toDatefor);
        let formattedFromDate = ''
        let formattedToDate = ''

        if (fromDateObj && toDateObj) {
          formattedFromDate = formatDateTime(fromDateObj);
          formattedToDate = formatDateTime(toDateObj);
          const body = {
            month: 0,
            year: 0,
            fromdate: formattedFromDate,
            todate: formattedToDate,
            group_id: 0
            // client_id : 0
          }

          getDeviceStats(body, (response) => {
            if (response.status === 200) {
              // console.log("messages ",response.data)
              if (response.data !== null) {
                setDeviceStats(response.data)
              }
            }
          })
        }
      }
      catch (error) {
        console.error("Error fetching data:", error);
      }

    }

    fetchData();

    // getTimezoneList((response)=>{
    //   if(response.status===200){
    //     let data = response.data;
    //     console.log(data)
    //     ////debugger
    //     let timeOffset = data.filter(item=>item.Id ===parseInt(timezoneId,10))[0].Time;

    //     //timeOffset='-16:30';

    //     const now = new Date();
    //     //console.log(now)

    //     const [sign, hours, minutes] = timeOffset.match(/([+-])(\d{2}):(\d{2})/).slice(1);
    //     const offsetInMinutes = (parseInt(hours) * 60 + parseInt(minutes)) * (sign === '+' ? 1 : -1);

    //     const adjustedTime = new Date(now.getTime() + offsetInMinutes * 60 * 1000);

    //     //debugger
    //     console.log(now,adjustedTime)
    //     console.log(adjustedTime.getTime())
    //     console.log(now.getTime())

    //     if(now.getDate() !== adjustedTime.getDate()){
    //       const adjustedDay = adjustedTime.getDate();
    //       const adjustedMonth = adjustedTime.getMonth(); 
    //       const adjustedYear = adjustedTime.getFullYear();

    //       setFromDate(dayjs(new Date(adjustedYear, adjustedMonth, adjustedDay)).startOf('month').hour(0).minute(0));
    //       setToDate(dayjs(new Date(adjustedYear, adjustedMonth, adjustedDay)).endOf('day'));

    //       fromDatefor = dayjs(new Date(adjustedYear, adjustedMonth, adjustedDay)).startOf('month').hour(0).minute(0);
    //       toDatefor = dayjs(new Date(adjustedYear, adjustedMonth, adjustedDay)).endOf('day');
    //     }   

    //     const nowHours = now.getHours();
    //     const nowMinutes = now.getMinutes();
    //     const adjustedHours = adjustedTime.getHours();
    //     const adjustedMinutes = adjustedTime.getMinutes();

    //     if (nowHours !== adjustedHours || nowMinutes !== adjustedMinutes) {
    //         const adjTimeValue = dayjs(adjustedTime)
    //         setToDate(adjTimeValue); 
    //         toDatefor = adjTimeValue;
    //     }

    //   }
    // })  

  }, [])





  const handleMonthchange = (event, value) => {
    setSelectedmonth();
    if (value) {
      // console.log("Selected Month:", value);
      setSelectedmonth(value);
    } else {
      setSelectedmonth();

    }
  };

  const handleYearChange = (event, value) => {
    setYearSelected();
    if (value) {
      // console.log("Selected Year:", value.name);
      setYearSelected(value);
    }
    else {
      setYearSelected();
    }
  };


  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  //   const handleToDateChange = (date) => {


  //     setToDate(date);

  // }

  //   const handleToDateChange = (newDate) => {
  //     // Adjust the time to 23:59:59
  //     const adjustedDate = newDate.endOf('day');

  //     // Update the state with the adjusted date
  //     setToDate(adjustedDate);
  // };

  const handleToDateChange = (newDate) => {
    if (initialToTime) {
      const adjustedDate = newDate.endOf('day');
      setToDate(adjustedDate);
      setInitialToTime(false)
    }
    else {
      setToDate(newDate);
    }
  };



  const handleGroup = (event, value) => {
    //debugger
    if (value) {
      // console.log("Selected Group:", value.GroupName,value.Id);
      setSelectedGroup(value.GroupId)

    } else {
      setSelectedGroup()
      // console.log("No group selected");
    }
  }




  const handleButtonClick = () => {
    // (selectedMonth || setYear) && (fromDate || toDate)
    //debugger
    setToDateErrorMsg('')
    setFromDateErrorMsg('')
    if (fromDate === null || toDate === null) {

      if (fromDate === null && toDate === null) {
        setFromDateErrorMsg("Please Select From Date");
        setToDateErrorMsg("Please Select to Date");
        return;
      }
      else if (fromDate === null) {
        setFromDateErrorMsg("Please Select From Date");
        return;
      }
      else if (toDate === null) {
        setToDateErrorMsg("Please Select to Date");
        return;
      }
    }

    // else if(selectedMonth && setYear){
    //   const yearValue = setYear.name !== null ? setYear.name : '';
    //   const monthValue = selectedMonth.value !== null ? selectedMonth.value : '';
    //   const groupValue = selectedGroup ? selectedGroup : 0;

    //   const body = { 
    //     month :monthValue,
    //     year : yearValue,
    //     fromdate:'',
    //     todate:'',
    //     group_id : groupValue
    //     // client_id : 0
    //   }
    //   getDeviceStats(body,(response) => {
    //     if (response.status === 200) {
    //       console.log("messages ",response.data)
    //       setDeviceStats(response.data)
    //     }
    //   })

    // }
    else {
      setToDateErrorMsg('')
      setFromDateErrorMsg('')
      const fromDatefor = fromDate;
      const toDatefor = toDate;


      const isValidDate = (value) => {
        const date = new Date(value);
        return !isNaN(date.getTime());
      };

      const parseDate = (dateString) => {
        if (!isValidDate(dateString)) {
          console.error("Invalid date:", dateString);
          return null; // Return null if the date string is invalid
        }
        return new Date(dateString);
      };

      const formatDateTime = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      };

      const fromDateObj = parseDate(fromDatefor);
      const toDateObj = parseDate(toDatefor);
      const formattedFromDate = ''
      const formattedToDate = ''

      debugger

      if (fromDateObj && toDateObj) {
        const formattedFromDate = formatDateTime(fromDateObj);
        const formattedToDate = formatDateTime(toDateObj);
        // console.log('Formatted fromDate:', formattedFromDate ,formattedToDate );

        const groupValue = selectedGroup ? selectedGroup : 0;

        const body = {
          month: 0,
          year: 0,
          fromdate: formattedFromDate,
          todate: formattedToDate,
          group_id: groupValue
          // client_id : 0
        }
        getDeviceStats(body, (response) => {
          if (response.status === 200) {
            // console.log("messages ",response.data)
            setDeviceStats(response.data)
          }
        })

      }

      if (!fromDateObj) {
        setFromDateErrorMsg("Please Select From Date");
      }

      if (!toDateObj) {
        setToDateErrorMsg("Please Select to Date");
      }

    }

  };


  const handleClearButtonClick = () => {
    setSelectedmonth(null);
    setYearSelected(null);
    setFromDate(null);
    setToDate(null);
    setSelectedGroup('');
    setDeviceStats([]);
    setToDateErrorMsg('')
    setFromDateErrorMsg('')
  }

  const changeStatus = () => {
    ////debugger
    //console.log(escStatus)
    const isChecked = escStatus === true ? 1 : 0;

    let body = { ClientId: parseInt(id, 10), EscalationStatus: isChecked }

    InsertUpdateEscStatus(body, (response) => {
      console.log(response);
      setConfirmation(false)
    })
  }

  const handleCheckboxChange = (event) => {
    ////debugger
    setConfirmation(true)

    setEscStatus(event.target.checked);
    // const isChecked = event.target.checked === true ? 1 : 0 ;

    // let body = {ClientId:parseInt(id,10),EscalationStatus:isChecked}

    // InsertUpdateEscStatus(body,(response)=>{
    //   console.log(response);
    // })
  }

  const closePopUp = () => {

    console.log(escStatus)
    if (!escStatus) {
      setEscStatus(true)
    }
    else {
      setEscStatus(false)
    }
    setConfirmation(false)
  }

  return (

    <>

      <div>
        <Typography variant="h4" gutterBottom>
          Dashboad
        </Typography>
        <Grid container justifyContent="flex-end">
          <Grid item>
            {/* <FormGroup> */}
            <FormControlLabel
              control={<Checkbox checked={escStatus} onChange={handleCheckboxChange} />}
              // sx={{color:escStatus?'black':'red'}}
              label="Escalation Status"
            />
          </Grid>
          <Grid item sx={{ marginTop: '10px' }}>
            {/* <Tooltip
                      title={
                            <Typography variant='body2'
                            sx={{
                              color: escStatus ? 'green' : 'red' // Green if checked, red if unchecked
                            }}
                            >
                                    {escStatus 
                                ? "Check : you will get the escalations" 
                                : "UnCheck : Escalation will Pause until check"}
                            </Typography>
                          }>
                     <InfoIcon sx={{color:'grey'}}/>
                    </Tooltip> */}




  <Tooltip
    title={
      <div>
        <Typography variant="body2" sx={{ fontSize: "0.9rem", whiteSpace: "nowrap" }}>
          <strong>Check:</strong> <span style={{ color: 'green' }}>You will get the escalations</span>
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "0.9rem", whiteSpace: "nowrap" }}>
          <strong>Uncheck:</strong> <span style={{ color: 'red' }}>Escalation will pause until checked</span>
        </Typography>
      </div>
    }
    componentsProps={{
      tooltip: {
        sx: {
          backgroundColor: 'white', // Set a light background color
          color: 'black', // Default text color
          border: '1px solid grey', // Add a border
          boxShadow: 1, // Subtle shadow for better visibility
          padding: '8px', // Inner spacing for text
          whiteSpace: 'normal', // Allow wrapping within the tooltip
          maxWidth: '350px', // Limit width to avoid excessive size
          wordBreak: 'break-word', // Break long words to fit inside
        },
      },
    }}
  >
    <InfoIcon sx={{ color: 'grey' }} />
  </Tooltip>



          </Grid>
          {/* <Tooltip 
                title={
                  <Typography variant='body2'>
                    {escStatus ? "Click to Pause Escalation." : "Click to Resume Escalation"}
                  </Typography>
                }
                >
                  <FormControlLabel
                    control={<Checkbox checked={escStatus} onChange={handleCheckboxChange} />}
                    sx={{color:escStatus?'black':'red'}}
                    label="Enable Escalation"
                  />
                  
                </Tooltip>  */}

          {/* </FormGroup> */}

        </Grid>
        <Grid sx={{ marginLeft: '1rem' }}>
          <Grid container spacing={2} alignItems="center" sx={{ marginTop: '0.2rem' }}>


            <Grid item xs={3}>

              <Autocomplete

                id="tags-outlined-client"
                options={groups}
                getOptionLabel={(option) => option.GroupName}
                onChange={handleGroup}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Group"
                    placeholder="Search Group Name..."
                  //   error={error && isClientSelected}

                  />

                )}
              />
              {/* {error && isClientSelected && <Typography variant="body2" color="error">Select Client</Typography>}  */}
            </Grid>
            {/* <Grid item xs={3}>

                  <Autocomplete
                      value={selectedMonth}
                      options={months}
                      getOptionLabel={(option) => option.name}
                      onChange={handleMonthchange}
                      renderInput={(params) => (
                          <TextField
                          {...params}
                          label="Month"
                          placeholder="Select Month"
                          variant="outlined"
                          />
                      )}
                      />
                  </Grid>
                <Grid item xs={3}>

                                <Autocomplete
                                value={setYear}
                                onChange={handleYearChange}
                                options={years}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    label='Year'
                                    placeholder="Select Year"
                                    variant="outlined"
                                    />
                                )}
                                />
                </Grid> */}




            <Grid item xs={3}>

              <FormControl fullWidth>
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label="From Date"
                        id="from-date"
                        ampm={false}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        format="YYYY-MM-DD HH:mm"
                        value={fromDate}
                        onChange={handleFromDateChange}
                        sx={{ border: 'none' }}
                      />
                    </LocalizationProvider> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="From Date"
                    id="from-date"
                    format="YYYY-MM-DD HH:mm"
                    value={fromDate}
                    onChange={handleFromDateChange}
                    ampm={false} // This disables the AM/PM format
                    sx={{ border: 'none' }}
                    // maxDate={dayjs()}
                    maxDate={calculatedDay}
                    disableHighlightToday


                  />
                </LocalizationProvider>
                {fromDateError && <Typography color="error" variant="body2" align="center">{fromDateError}</Typography>}

              </FormControl>
            </Grid>
            <Grid item xs={3}>

              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="To Date"
                    id="to-date"
                    ampm={false}
                    // viewRenderers={{
                    //     hours: renderTimeViewClock,
                    //     minutes: renderTimeViewClock,
                    //     seconds: renderTimeViewClock,
                    // }}
                    format="YYYY-MM-DD HH:mm"
                    value={toDate}
                    onChange={handleToDateChange}
                    sx={{ border: 'none' }}
                    minDate={fromDate}
                    maxDate={calculatedDay}
                    disableHighlightToday
                  />
                </LocalizationProvider>
                {toDateError && <Typography color="error" variant="body2" align="center">{toDateError}</Typography>}

              </FormControl>
            </Grid>



            <Grid item xs={3}>
              <Button onClick={handleButtonClick} variant='contained' color="primary">Get Reports</Button>
              <Button color='error' variant='contained' onClick={handleClearButtonClick} sx={{marginLeft:'4px'}}>Cancel</Button>
            </Grid>
          </Grid>
        </Grid>


        <div style={{ marginTop: '30px' }}></div>

        <Container>
          <BarGraph data={deviceStats}></BarGraph>
        </Container>
        <div style={{ marginTop: '30px' }}></div>
        {/* Table content */}
        <div >

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              paddingLeft: '15px',
              paddingRight: '15px',
            }}
          >
            <TableContainer sx={{ width: '90%' }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: 'rgba(102, 178, 255 , 1)' }}>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell align="center">Camera Name</TableCell>
                    <TableCell align="center">True Events</TableCell>
                    <TableCell align="center">False Events</TableCell>
                    <TableCell align="center">Escalated Events</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  <TableRow >
                    <TableCell></TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>Total</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }} >
                      {deviceStats.reduce((total, row) => total + row.true_event_count, 0)}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {deviceStats.reduce((total, row) => total + row.false_event_count, 0)}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {deviceStats.reduce((total, row) => total + row.escalated_count, 0)}
                    </TableCell>
                  </TableRow>

                  {deviceStats.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        <Box
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography
                            style={{
                              display: 'inline-block',
                              textAlign: 'left',
                              width: '40%', // adjust width to control the start point
                              paddingLeft: '10px', // adjust padding as needed
                            }}
                          >
                            {row.devicename}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="center">{row.true_event_count}</TableCell>
                      <TableCell align="center">{row.false_event_count}</TableCell>
                      <TableCell align="center">{row.escalated_count}</TableCell>
                    </TableRow>
                  ))}



                </TableBody>
              </Table>
            </TableContainer>

          </Box>
        </div>

        <Dialog open={confirmation} sx={{ maxWidth: '90vw', width: '90vw' }}>
          <DialogTitle sx={{ textAlign: 'center' }}> Escalation Update </DialogTitle>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            Are you sure you want to change Escalation Status?
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={changeStatus}>
              Yes
            </Button>
            <Button variant="contained" color="error" onClick={closePopUp}>
              No
            </Button>
          </DialogActions>
        </Dialog>

      </div>

    </>
  )
}